import axios from "axios";
import keys from '../../common/browserStorageKeys'
import storage from '../../utility/browserStorage'
import {HandleApiError} from "../../utility/handleApiError"

export const printAndFinalSubmit = (formData) => {
  let authToken = "";
  if (storage.getItemValue(keys.AUTHORIZATION_TOKEN)) {
    authToken = storage.getItemValue(keys.AUTHORIZATION_TOKEN);
  }

  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: authToken,
    },
  });
  return (dispatch) => {
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    instance
      .post("/printAndFinalSubmit", formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
          dispatch({ type: "PRINT_AND_FINAL_SUBMIT", payload: response.data });
          }
        }
      )
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
        console.log(err);
        HandleApiError(err)
      });
  };
};
