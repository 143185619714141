import axios from "axios";
import browserStorageKeys from "../../common/browserStorageKeys";
import browserStorage from "../../utility/browserStorage";

export const getPaymentStatus = (transactionId, loader = false) => {
  let authToken = "";
  if (browserStorage.getItemValue(browserStorageKeys.AUTHORIZATION_TOKEN)) {
    authToken = browserStorage.getItemValue(
      browserStorageKeys.AUTHORIZATION_TOKEN
    );
  }
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: authToken,
    },
  });
  let applicationId = "";
  let formData = {};
  if (browserStorage.getItemValue(browserStorageKeys.USER_SERVICE_ID)) {
    applicationId = browserStorage.getItemValue(
      browserStorageKeys.USER_SERVICE_ID
    );
    formData = {
      applicationId: applicationId,
    };
  }

  return (dispatch) => {
    if (loader) {
      dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    }
    instance
      .post(`payments/status/${transactionId}`, formData)
      .then((response) => {
        if (response.status === 200) {
          // dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
          dispatch({ type: "GET_PAYMENT_STATUS", payload: response.data });
          dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
        }
      })
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
        console.log(err);
      });
  };
};
