var language = {
  "TM_Txf6": "আপনার পছন্দের ভাষা নির্বাচন করুন",
  "TM_But15": "এগিয়ে যান",
  "TM_Pag19": "ভাষা নির্বাচন কর",
  "TM_Pag17": "অবস্থান পরিবর্তন করুন",
  "TM_Pag13": "কাজের সুবিধা",
  "TM_But1": "চল শুরু করি",
  "TM_Pag20": "স্থান নির্বাচন করুন",
  "TM_Txf9": "আপনার অবস্থান প্রদান করা আমাদের আপনাকে আপনার এলাকায় আপনি যোগ্য সুবিধাগুলি দেখাতে সাহায্য করবে৷",
  "TM_Txf3": "অনুসন্ধান রাষ্ট্র",
  "TM_Txf4": "অনুসন্ধান জেলা",
  "TM_But16": "পরবর্তী",
  "TM_Alt1": "3000+ কল্যাণ প্রকল্পের জন্য যোগ্যতা পরীক্ষা করুন",
  "TM_Alt3": "আপনার কাছাকাছি চাকরির সুযোগ খুঁজুন",
  "TM_Alt2": "আপনি আবেদন করতে চান পরিষেবা চেক করুন",
  "TM_Lin1": "অ্যাপটি অ্যাক্সেস করার মাধ্যমে, আপনি EasyGov-এর সাথে সম্মত হন",
  "TM_Alt5": "আপনি যোগ্য হতে পারে এমন কল্যাণ সুবিধাগুলি খুঁজে পেতে আমাদের সাহায্য করার জন্য প্রোফাইল তৈরি করুন৷",
  "TM_But2": "আপনার প্রোফাইল তৈরি করুন",
  "TX_Sub1": "স্কিম বিভাগ",
  "TX_Sub2": "ফ্ল্যাগশিপ স্কিম",
  "TX_Sub3": "নতুন যোগ করা স্কিম",
  "TX_Sub4": "জনপ্রিয় স্কিম",
  "TM_Pag1": "স্কিম বিভাগ",
  "TM_Alt6": "স্কিম",
  "TM_Alt7": "সেবা",
  "TM_Alt8": "চাকরি",
  "TM_Alt9": "অনুসন্ধান",
  "TM_Alt10": "প্রোফাইল",
  "TX_Sub7": "সেবা বিভাগ",
  "TX_Sub8": "শীর্ষ রেট সেবা",
  "TX_Sub9": "নতুন যোগ করা সেবা",
  "TX_Sub10": "জনপ্রিয় পরিষেবা",
  "TM_Pag18": "সেবা বিভাগ",
  "TM_But37":"আবেদন দেখুন",
  "TM_Tag8":"স্ট্যাটাস",
  "TM_Pag35": "আবেদনপত্রের অবস্থা",
  "TM_Alt16": "আপনার জন্য সবচেয়ে উপযুক্ত চাকরি খুঁজে পেতে আমাদের সাহায্য করার জন্য প্রোফাইল তৈরি করুন",
  "TM_Alt17": "চাকরির বিস্তারিত",
  "TM_Alt18": "কাজের বিবরণী",
  "TM_Alt19": "ইংরেজি",
  "TM_Alt20": "হিন্দি",
  "TM_Alt21": "তামিল",
  "TM_Alt22": "কন্নড়",
  "TM_Alt23": "পাঞ্জাবি",
  "TM_Alt24": "মালায়লাম",
  "TM_Alt25": "বাংলা",
  "TM_Alt26": "গুজরাটি",
  "TM_Alt27": "তেলেগু",
  "TM_Alt28": "মারাঠি",
  "TM_But3": "সব দেখ",
  "TM_But5": "যোগ্যতা পরীক্ষা করুন",
  "TM_But10": "আরও চাকরি অন্বেষণ করুন",
  "TX_Sub13": "আমার পরিষেবাসমূহ",
  "TM_Pag6": "আমার পরিষেবাসমূহ",
  "TX_Sub15": "শীর্ষ রেটেড চাকরি",
  "TX_Sub16": "নতুন যোগ করা চাকরি",
  "TX_Sub17": "জনপ্রিয় চাকরি",
  "TX_Sub18": "প্রস্তাবিত চাকরি",
  "TX_Sub20": "আমার কাজ",
  "TM_alt11": "সুবিধা",
  "TM_Alt13": "নথিপত্র",
  "TM_Pag4": "স্কিম বিশদ",
  "TM_Pag21": "স্কিম",
  "TM_Pag22": "সেবা",
  "TM_Pag23": "চাকরি",
  "TM_Alt30": "স্কিম, পরিষেবা এবং চাকরির জন্য অনুসন্ধান করুন",
  "TM_Lab6": "সম্প্রতি অনুসন্ধান করা হয়েছে",
  "TM_Err4": "কোন ফলাফল পাওয়া যায়নি",
  "TM_Err12": "এই পরিষেবা আপনার অবস্থানে উপলব্ধ নয়.",
  "TM_Mod25":"ভাষা পরিবর্তন করুন",
  "TM_Rec33":"এই পরিষেবাটি শুধুমাত্র ইংরেজি ভাষায় উপলব্ধ। অ্যাপের ভাষা পরিবর্তন করতে এগিয়ে যান ক্লিক করুন",
  "TM_Pag12": "কাজের বিবরণ",
  "TM_Alt12": "যোগ্যতা",
  "TM_Tag1": "এইমাত্র চালু করা হয়েছে",
  "TX_Sub25": "প্রায়শই জিজ্ঞাসিত প্রশ্নসমুহ",
  "TM_Sup1": "আমাদের অনুসরণ করুন",
  "TM_Hep17": "আপনার যদি কোনও প্রশ্ন থাকে তবে আমাদের জানান এবং আমরা 48 ব্যবসায়িক ঘন্টার মধ্যে আপনার কাছে ফিরে আসব।",
  "TM_Lab4": "ইমেল",
  "TM_Lin2": "বিধি ও শর্তাবলী",
  "TM_Lin3": "গোপনীয়তা নীতি",
  "TM_But4": "ব্যবহারকারী পরিবর্তন",
  "TM_Pag15": "ব্যবহারকারী পরিবর্তন",
  "TM_Hep36": "আপনি যোগ্যতা দেখতে বা আপনার সংরক্ষিত ডেটা আরও প্রক্রিয়া করতে আপনার পছন্দসই প্রোফাইলটি নির্বাচন বা যুক্ত করতে পারেন।",
  "TM_But18": "ব্যবহারকারী যোগ করুন",
  "TM_Mod5": "আপনি কি স্থায়ীভাবে {{Name}} অ্যাকাউন্ট মুছে ফেলার বিষয়ে নিশ্চিত?",
  "TM_Rec7": "আপনি যখন আপনার EasyGov অ্যাকাউন্ট মুছে ফেলবেন, আপনি আপনার প্রোফাইল এবং আপনার অ্যাকাউন্টের সাথে সম্পর্কিত অন্যান্য প্রোফাইলে সঞ্চিত কোনো ডেটা বা তথ্য পুনরুদ্ধার করতে সক্ষম হবেন না। আপনার সমস্ত প্রস্তাবিত এবং সংরক্ষিত স্কিমগুলিও মুছে ফেলা হবে৷",
  "TM_Err2": "আপনি {{Name}} প্রোফাইল মুছে ফেলতে পারবেন না কারণ এটিতে একটি চলমান অ্যাপ্লিকেশন(গুলি) রয়েছে৷",
  "TM_But11": "মুছে ফেলা",
  "TM_But12": "বাতিল করুন",
  "TM_But30": "সমস্ত চাকরি দেখুন",
  "TM_But31": "সমস্ত স্কিম দেখুন",
  "TM_Con11": "লোড হচ্ছে",
  "TM_The1": "সেভ করা",
  "TM_The2": "ড্রাফ্ট",
  "TM_The3": "আবেদন করা হয়েছে",
  "TM_The4": "নথিভুক্ত",
  "TM_The5":"প্রক্রিয়া করা হয়েছে",
  "TM_Hep7": "কোন সংরক্ষিত স্কিম",
  "TM_Hep21": "কোন খসড়া স্কিম",
  "TM_Hep22": "কোনো ফলিত স্কিম নেই",
  "TM_Hep23": "কোন নথিভুক্ত স্কিম",
  "TM_Hep6":"কোন সংরক্ষিত সেবা",
  "TM_Hep54":"আজ",
  "TM_Hep55":"গতকাল",
  "TM_Hep13":"কোন খসড়া পরিষেবা নেই",
  "TM_Hep14":"কোন ফলিত সেবা.",
  "TM_Hep15":"বিজ্ঞপ্তি নেই",
  "TM_Hep44":"কোনো প্রক্রিয়াজাত পরিষেবা নেই৷",
  "TM_But7": "আরো স্কিম এক্সপ্লোর করুন",
  "TM_Rec1": "আপনি কি আপনার 'আমার স্কিম' থেকে এই স্কিমটি মুছে দেওয়ার বিষয়ে নিশ্চিত?",
  "TM_Rec2": "আপনি কি আপনার 'আমার পরিষেবা' থেকে এই পরিষেবাটি মুছে দেওয়ার বিষয়ে নিশ্চিত?",
  "TM_Pag2": "আমার স্কিম",
  "TM_Hep10": "আপনি প্রোফাইল থেকে আপনার পছন্দের ভাষা এবং অবস্থান পরিবর্তন করতে পারেন",
  "TM_But21": "বুঝেছি!",
  "TM_Alt29": "ভাষা এবং অবস্থান",
  "TM_Hep38": "{{category}} বিভাগে উপযুক্ত স্কিম সনাক্ত করতে আমাদের সাহায্য করার জন্য, অনুগ্রহ করে {{category}} সম্পর্কিত কিছু অতিরিক্ত প্রশ্নের উত্তর দিন",
  "TM_Hep11": "আপনার প্রোফাইলের উপর ভিত্তি করে, আমরা আপনাকে কোনো স্কিমের জন্য যোগ্য খুঁজে পাইনি। আমরা ক্রমাগত আরও স্কিম যোগ করছি, অনুগ্রহ করে পরে চেক করুন।",
  "TM_Hep39": "{{category}} বিভাগে উপযুক্ত চাকরি শনাক্ত করতে আমাদের সাহায্য করতে, অনুগ্রহ করে {{category}} সম্পর্কিত কিছু অতিরিক্ত প্রশ্নের উত্তর দিন",
  "TM_Rec6": "এই মুহূর্তে আপনার রাজ্যে কোনও চাকরি কনফিগার করা নেই, আমরা ক্রমাগত আপডেট করছি, অনুগ্রহ করে আমাদের সাথে আবার চেক করুন",
  "TM_But27": "ঠিক আছে",
  "TX_Sub5": "প্রস্তাবিত স্কিম",
  "TM_Pag9": "চাকরির বিভাগ",
  "TM_Hep42": "আপনার প্রোফাইলের উপর ভিত্তি করে, আমরা আপনাকে কোনো চাকরির জন্য যোগ্য খুঁজে পাইনি। আমরা ক্রমাগত আরও চাকরি যোগ করছি, অনুগ্রহ করে পরে চেক করুন।",
  "TM_Hep40": "আবেদন করতে",
  "TM_Hep41": "ঘন্টার",
  "TM_Hep2": "শ্রেণী",
  "TM_Alt31": "সেবা",
  "TM_Alt32": "স্কিমে",
  "TM_Alt33": "চাকরিতে",
  "TM_Pag14": "প্রোফাইল তৈরি করুন",
  "TM_Hep9": "একটি প্রোফাইল তৈরি করা আপনাকে আপনার আগ্রহের সমস্ত সুযোগ সংরক্ষণ করতে এবং আপনার সংরক্ষিত ডেটা আরও প্রক্রিয়া করার জন্য যে কোনও সময় পরে ফিরে আসতে দেয়৷",
  "TM_Txf1": "পুরো নাম",
  "TM_Txf2": "সম্পর্ক",
  "TM_But29": "সংরক্ষণ",
  "TM_Err1": "একই নাম এবং সম্পর্কের প্রোফাইল ইতিমধ্যেই বিদ্যমান!",
  "TM_Err6": "শুধুমাত্র অক্ষর অনুমোদিত",
  "TM_Pag16": "ভাষা পরিবর্তন করুন",
  "TM_Pag33": "অবস্থান হালনাগাদ করুন",
  "TM_But22": " আধুনিকীকরণ ",
  "TM_But14": "প্রোফাইল যোগ করুন",
  "TM_Txf15": "নাম যুক্ত করুন",
  "TM_But20":"সম্পাদনা করুন",
  "TM_Pag29":"প্রোফাইল তৈরি",
  "TM_Con9":"দুঃখিত! আমরা আপনার জন্য কোনো মিলে যাওয়া স্কিম খুঁজে পাইনি",
  "TM_Con10":"দুঃখিত! আমরা আপনার জন্য কোনো মিলে যাওয়া চাকরি খুঁজে পাইনি",
  "TM_But26": "বাড়ি",
  "TM_Rec35": "সম্পদ উপলব্ধ নয়",
  "TM_Rec36":"সার্ভার টাইম আউট!",
  "TM_Rec37":"প্রবেশাধিকার অস্বীকৃত!",
  "TM_Mod28":"কিছু ভুল হয়েছে.",
  "TM_Rec10": "আমরা ক্রমাগত উন্নতি করছি, অনুগ্রহ করে পরে আবার চেষ্টা করুন।",
  "TM_But24":"পুনরায় চেষ্টা করা",
  "TM_Hep16": "অ্যাপটি ব্যবহার করার সময় আপনার অভিজ্ঞতা বাড়ানোর জন্য আপনার সঠিক অবস্থান বেছে নিন এবং আপনার জন্য সঠিক স্কিম/পরিষেবা/চাকরি প্রদর্শন করুন।",
  "TM_Pag26": "অবস্থান নিশ্চিত করুন",
  "TM_But23": "নিশ্চিত করুন",
  "TX_Sub21":"বিজ্ঞপ্তি",
  "TX_Sub22":"প্রোফাইল পরিচালনা করুন",
  "TX_Sub23":"অবস্থান",
  "TX_Sub24":"ভাষা",
  "TX_Sub26":"আমাদের সম্পর্কে",
  "TX_Sub27":"হিসাব মুছে ফেলা",
  "TX_Sub28":"ব্যক্তিগত বিবরণ",
  "TX_Sub29":"অর্থনৈতিক বিবরণ",
  "TX_Sub30":"হাউজিং বিবরণ",
  "TX_Sub31":"শিক্ষা প্রোফাইল",
  "TX_Sub32":"পেশা প্রোফাইল",
  "TX_Sub33":"স্বাস্থ্য প্রোফাইল",
  "TM_Txf13":"রাজ্য নির্বাচন কর",
  "TM_Txf14":"জেলা নির্বাচন করুন",
  "TM_Hep43":"আগের প্রশ্নের জন্য ডানদিকে সোয়াইপ করুন",
  "TM_Hep28":"আপনার প্রোফাইল অনুযায়ী প্রাসঙ্গিক স্কিম খুঁজতে প্রশ্নের উত্তর দিন",
  "TM_Hep30":"আপনার প্রোফাইল অনুযায়ী প্রাসঙ্গিক চাকরি খুঁজতে প্রশ্নের উত্তর দিন",
  "TM_Hep32": "আপনি জন্য যোগ্য হতে পারে",
  "TM_Hep35":"কয়েকটি প্রশ্নের উত্তর দাও",
  "TM_Rel1":"কন্যা",
  "TM_Rel2":"পিতা",
  "TM_Rel3":"মাতা",
  "TM_Rel4":"পুত্র",
  "TM_Rel5":"ভাই",
  "TM_Rel6":"বোন",
  "TM_Rel7":"স্ত্রী/স্বামী",
  "TM_Rel8":"অন্য কেউ",
  "TM_Rel9": "স্ব",
  "TM_Mod4": "মনোযোগ",
  "TM_Mod2": "পরিষেবাগুলি মুছুন",
  "TM_Rec12": "আপনি যদি ডেটা পরিবর্তন করেন তবে আপনার প্রোফাইল ডেটাতেও একই আপডেট করা হবে। আপনি কি অবিরত করতে চান?",
  "TM_Hep12": "আপনার পূর্বে সংরক্ষিত ডেটা পর্যালোচনা করুন এবং নিশ্চিত করুন।",
  "TM_But28": "চালিয়ে যান",
  "TM_Pag24": "আপনার বিবরণ পর্যালোচনা",
  "TM_Pag25": "আপনার বিবরণ আপডেট করুন",
  "TM_Hep33":"আপনি ভাল করছেন! চোলতে থাকা...",
  "TM_But9" : "আরও পরিষেবাগুলি অন্বেষণ করুন৷",
  "TM_Con15":"আমরা বিস্তারিত আনার সময় অনুগ্রহ করে অপেক্ষা করুন",
  "TM_Pag5":"স্কিম বিবরণ",
  "TM_Pag8":"পরিষেবার বিবরণ",
  "TM_Hep3": "আমার স্নাতকের",
  "TM_But6": "লিংক কপি করুন",
  "TM_Con1": "লিঙ্ক কপি করা হয়েছে",
  "TM_Con5": "ডাউনলোড করা হয়েছে",
  "TM_Alt14": "কিভাবে আবেদন করতে হবে",
  "TM_Alt15": "তথ্য",
  "TM_Con3": `এই স্কিমটি আপনার "আমার স্কিম"-এ সংরক্ষিত হয়েছে`,
  "TM_Con2": `এই পরিষেবাটি আপনার "আমার পরিষেবাগুলিতে" সংরক্ষিত হয়েছে`,
  "TM_Alt34":"আবেদনের পদ্ধতি",
  "TM_Alt35":"আবেদনের লিঙ্ক",
  "TM_Alt36":"ফর্ম ডাউনলোড করুন",
  "TM_Alt38":"নির্বাচন পদ্ধতি",
  "TM_Alt41":"স্কিম সুবিধা",
  "TM_Alt56":"পোস্ট",
  "TM_Alt57":"বেতন",
  "TM_Alt58":"চাকরির বিস্তারিত",
  "TM_Alt59":"আবেদন করার শেষ তারিখ",
  "TM_Txf12": "কারণ",
  "TM_Hep51": "যোগ্যতা সংক্রান্ত মান",
  "TM_Hep52": "আপনার দ্বারা প্রদান করা মান",
  "TM_Con7" : "দুঃখিত, আপনি এই স্কিমের জন্য যোগ্য নন",
  "TM_Con8" : "দুঃখিত, আপনি এই কাজের জন্য যোগ্য নন",
  "TM_Err5" : "কোন অপশন বাকি",
  "TM_Con13" : "আপনার ডেটা সফলভাবে সংরক্ষণ করা হয়েছে.",
  "TM_Hep8" : "কোন সংরক্ষিত কাজ",
  "TM_Pag10": "আমার কাজ",
  "TM_Mod3" : "চাকরি মুছুন",
  "TM_Rec3" : "আপনি কি আপনার 'আমার চাকরি' থেকে এই চাকরিটি মুছে দেওয়ার বিষয়ে নিশ্চিত?",
  "TM_Hep24": "কোনো খসড়া চাকরি নেই",
  "TM_Hep25": "কোন প্রয়োগকৃত চাকরি নেই",
  "TM_Hep26": "কোন নথিভুক্ত চাকরি",
  "TM_Hep27": 'মেয়াদ শেষ হওয়ার তারিখ থেকে 30 দিনের পর আপনার "আমার চাকরি" থেকে সব মেয়াদ শেষ হয়ে যাওয়া চাকরিগুলো সরিয়ে দেওয়া হবে।',
  "TM_Tag3" : "মেয়াদোত্তীর্ণ",
  "TM_Tag4" : "এর মধ্যে আবেদন করুন",
  "TM_Con4":  'এই চাকরিটি আপনার "আমার কাজ" এ সংরক্ষিত হয়েছে',
  "TM_Rec5": "আপনার সমস্ত তথ্য মুছে ফেলা হয়েছে",
  "TM_Rec4": "আপনার অ্যাকাউন্ট মুছে ফেলার জন্য আপনাকে প্রথমে আপনার অ্যাকাউন্টের সাথে যুক্ত সমস্ত প্রোফাইল মুছে ফেলতে হবে",
  "TM_Pag41": "আবেদনের বিবরণ",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "আপনি কি নিশ্চিত আপনি এই নথিটি সরাতে চান?",
  "TM_Mod18": "গ্যালারি",
  "TM_Mod17": "ক্যামেরা",
  "91412465ea9169dfd901dd5e7c96dd99": "আপলোড",
  "af6ebe0c0e89201cb2294f32955f7b15": "একবারে শুধুমাত্র 20টি নথি অনুমোদিত।",
  "d5de543c0e83073f05a846d62cc83c81": "একই ক্ষেত্রের জন্য ইতিমধ্যেই নথি আপলোড করা হয়েছে।",
  "fca8da28cbcbe094dc646872e7cdbc7b": "বৈধ লিখুন",
  "3f68e67dc6c397aa9d1c24c356f754f": "যাচাই করা হয়েছে",
  "1e410e1b9903e7a99b93857f536b7e1d": "যাচাই করা হয়নি",
  "f8d855b26faffa0f8779fb44632d6bc1": "অবৈধ ক্যাপচা কোড",
  "d53b4d564b9054db9b4b4a50e713197f": "দয়া করে এখানে ক্যাপচা লিখুন",
  "TM_Con18": "আপনার আবেদনটি আপনার 'ড্রাফ্ট স্কিম'-এ সফলভাবে সংরক্ষিত হয়েছে",
  "TM_Con24": "আপনার আবেদনটি আপনার 'ড্রাফ্ট জব'-এ সফলভাবে সংরক্ষিত হয়েছে",
  "TM_Con23": `আপনার আবেদনটি আপনার "ড্রাফ্ট সার্ভিসে" সফলভাবে সংরক্ষিত হয়েছে`,
  "6cf24d7a07132fe88b610e2b3d3def28": "আত্ম ঘোষণা",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "এই ক্ষেত্রটি প্রয়োজন।",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "অনুগ্রহ করে শুধুমাত্র বর্ণমালা, সংখ্যা, কমা, -, _, / লিখুন",
  "562d3d08919ae105c3ebc42d9607d266": "অনুগ্রহ করে শুধুমাত্র বর্ণমালা বা সংখ্যা লিখুন",
  "58f7a43f2c3bd7854d99fbbc3fa4ae9": "শুধুমাত্র বর্ণমালা অনুমোদিত।",
  "4906d981bb592b32f6982fb4187c7b60": "একটি বৈধ আইএফএসসি কোড লিখুন।",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "শুধুমাত্র 11টি অক্ষর অনুমোদিত।",
  "7c58cde3b827bc7d57cbf77865046169": "অনুগ্রহ করে শুধুমাত্র সংখ্যা লিখুন",
  "544b0264d55a1e4cf476b279240d9be4": "কেবল দশটি সংখ্যা অনুমোদিত। অনুগ্রহ করে 0 বা +91 প্রিফিক্স করবেন না",
  "73f0d980e48b8141e0776e02d96a872f": "শুধুমাত্র বারোটি সংখ্যা অনুমোদিত।",
  "0ce98bd973419ff33921a2275e7903b9": "অনুগ্রহ করে একটি বৈধ প্যান নম্বর লিখুন।",
  "e55e425dbaf6b6449940eac8ea749092": "অনুগ্রহ করে শুধুমাত্র বর্ণমালা লিখুন।",
  "64ff9e3809686d4ea3253a631cae4761": "অনুগ্রহ করে 2 দশমিক স্থানে সঠিক মান লিখুন।",
  "7ed0ef23d527c2876015161e422f49ce": "অনুগ্রহ করে কন্নড় ভাষায় আপনার নাম লিখুন।",
  "230486b5dd7afed96556ba9bd4175423": "পিন কোড ভুল",
  "9f44bd93c8988c682d5ef5369fd11f47": "অনুগ্রহ করে বৈধ ইমেল লিখুন",
  "ac598199ea0dd028ade4677a435caf4b": "একটি বৈধ আধার নম্বর লিখুন",
  "10803b83a68db8f7e7a33e3b41e184d0": "জন্ম তারিখ",
  "d0de720ccdffea8490dfd2893c50ebc5": "এটি {{displayName}} এর থেকে বড় হওয়া উচিত",
  "ced6e171e13ebd5c37415c822706b9d4": "এটি {{displayName}} থেকে কম হওয়া উচিত",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "এটি {{displayName}} এর সমান হওয়া উচিত",
  "9483135517d156229d0439dff74b1ca0": "এটি {{displayName}} এর থেকে বড় বা সমান হওয়া উচিত",
  "01e9cd748e852b1ae389b980bff195b0": "এটি {{displayName}} এর থেকে কম বা সমান হওয়া উচিত",
  "TM_Pag37": "পর্যালোচনা বিবরণ",
  "TM_Rec18": "আপনার পূর্ণ বিবরণ পরিবর্তন করতে “সম্পাদনা” এ ক্লিক করুন।",
  "TM_Alt51": "সংযুক্তি(গুলি):",
  "TM_Alt42": "ধন্যবাদ",
  "TM_But9":"আরো পরিষেবা অন্বেষণ করুন",
  "TM_But19":"ডাউনলোড করুন",
  "TM_Con17":"আপনার অ্যাপ্লিকেশন ডাউনলোড করা হয়েছে।",
  "TM_Sub48": "সাহায্য এবং সহযোগিতা",
  "TM_Alt66": "<b>jioeasygov@easygov.co.in</b>-এ আমাদের একটি ইমেল পাঠান এবং আমাদের দল শীঘ্রই আপনার কাছে ফিরে আসবে",
  "TM_Alt65": "সাহায্য এবং সহযোগিতা কোন প্রশ্নের সাথে সহায়তা প্রয়োজন?",
  "TM_Txf16": "এখানে অনুসন্ধান করুন",
  "TM_Pag54": "সেবা সুবিধা",
  "TM_Alt37": "সরকারী ফি",
  "TM_But105" : "আরো অন্বেষণ",
  "TM_Alt39": "জমা দেওয়ার ঠিকানা",
  "TM_But17": "জমা দিন",
  "TM_But58": "আবেদন করার জন্য অগ্রসর হন",
  "TM_But100" : "ফিরে যাও",
  "TM_But95": "এখন পরিশোধ করুন",
  "TM_Alt88": "আপনার আবেদন জমা দেওয়ার জন্য অনুগ্রহ করে নিম্নোক্ত পরিমাণ অর্থ প্রদান করুন",
  "TM_Con32" : "আপনার আবেদন সফলভাবে জমা দেওয়া হয়েছে",
  "TM_But110": "আবেদন",
  "TM_Hep69": "'স্কিম সমর্থন পান'-এ ক্লিক করুন এবং একটি সংক্ষিপ্ত সমীক্ষা করুন, যা আপনাকে সঠিক সহায়তা প্রদান করতে আমাদের সাহায্য করবে।",
  "TM_But107": "স্কিম সমর্থন পান কি?",
  "TM_But106": "স্কিম সমর্থন পান",
  "TM_Pag32": "স্কিম সচেতনতা সমীক্ষা",
  "TM_Con14": "বিস্তারিত শেয়ার করার জন্য আপনাকে ধন্যবাদ.",
  "TM_Tag5": "খালি অবস্থান",
  "TM_Alt107": "দিন",
  "TM_Rec11":"আপনি কি নিশ্চিত যে আপনি নির্বাচিত বিজ্ঞপ্তিগুলি মুছে ফেলতে চান?",
  "TM_Mod7":"বিজ্ঞপ্তি মুছুন",
  "TM_But25":"সব নির্বাচন করুন",
  "TM_Mod1": "স্কিম মুছুন",
  "3f68e67dc6c397aaa9d1c24c356f754f":"যাচাইকৃত",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9":"শুধুমাত্র বর্ণমালা অনুমোদিত",
  "TM_Rec50": "আপনি কি নিশ্চিত যে আপনি আপনার আবেদন প্রত্যাহার করতে চান?",
  "TM_But23": "নিশ্চিত করুন",
  "TM_But12": "বাতিল করুন",
  "TM_Con6" : "আপনার নিবন্ধিত মোবাইল নম্বরে একটি OTP পাঠানো হয়েছে (xxxxxx{{phoneNumber}})",
  "TM_But17": "জমা দিন",
  "TM_But61": "OTP আবার পাঠান",
  "TM_Con20": "আপনার আবেদন সফলভাবে প্রত্যাহার করা হয়েছে",
  "TM_But38": "সম্পূর্ণ কর্ম",
  "TM_But62": "মতামত দিন",
  "TM_Lab11": "আবেদন প্রত্যাহার",
  "TM_Err3" : "অবৈধ OTP",
  "TM_Err13": "OTP মেয়াদ শেষ",
  "TM_Err14": "আপনি OTP জেনারেট করার সর্বোচ্চ সীমা অতিক্রম করেছেন।  অনুগ্রহ করে ৩ ঘন্টা পর আবার চেষ্টা করুন।",
  "TM_But40": "কর্ম",
  "TM_Tag9": "ব্যবস্থা নেওয়ার প্রয়োজন আছে",
  "TM_Tag10": "কোনও ব্যবস্থা নেওয়ার প্রয়োজন নেই",
  "TM_Tag8" : "স্ট্যাটাস",
  "TM_But76" : "দেখুন",
  "TM_Alt87" : "অনলাইনে পাওয়া যায় না",
  "TM_Pag34": "পেমেন্ট বিবরণ",
  "TM_Hea1": "মোট পরিমাণ",
  "TM_Hea2": "সারসংক্ষেপ",
  "TM_Sub56": "মোট",
  "TM_But42": "বেতন",
  "TM_Lab18": "রসিদ দেখুন",
  "TM_Err34": "পেমেন্ট ব্যর্থতার কারণে আপনার আবেদন জমা দেওয়া যায়নি।",
  "TM_Rec55": "আপনার পেমেন্টের স্থিতি নিশ্চিত করতে সময় নিচ্ছে৷ আমরা ক্রমাগত আপনার ব্যাঙ্কের সাথে চেক করছি৷ অনুগ্রহ করে পরবর্তী 48 ঘন্টার জন্য কোনো অতিরিক্ত পেমেন্ট করা এড়িয়ে চলুন।",
  "TM_But13": "ফিরে যান",
  "TM_Alt124":"অনুগ্রহ করে অপেক্ষা করুন",
  "TM_Rec58": "অপেক্ষা করুন আমরা আপনার পেমেন্ট স্ট্যাটাস যাচাই করছি।",
  "TM_Rec59": "এই পরিষেবার জন্য আপনার শেষ অর্থপ্রদান মুলতুবি অবস্থায় রয়েছে৷ এটি সম্পূর্ণ হলে আমরা আপনাকে অবহিত করব৷ ইতিমধ্যে, আমাদের অন্যান্য পরিষেবাগুলি অন্বেষণ করতে নির্দ্বিধায়৷ আপনার ধৈর্যের জন্য আপনাকে ধন্যবাদ.",
  "TM_But133": "লিঙ্কে ক্লিক করুন"
};



export default language;
