import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import RoutesMobile from "./components/Routes";
import history from "./history";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSettings } from "./store/actions/getSettingsAction";
import { getPaymentStatus } from "./store/actions/getPaymentStatusAction";
import { printAndFinalSubmit } from "./store/actions/printAndFinalSubmitAction";
import { appOpenCount } from "./store/actions/appOpenCountAction";
import browserStorage from "./utility/browserStorage";
import browserStorageKeys from "./common/browserStorageKeys";
import callNative from "./utility/JioMiniApp/callNative";
import $ from "jquery";
import Loader from "./components/elements/loader";
import { getProduct } from "./store/actions/getProductAction";
import NoNetworkPage from "./components/pages/noNetworkScreen";
import { saveJwt } from "./store/actions/saveJwtAction";
import url_utility from "./utility/url"

function App() {
  const [hitAppOpenCount, setHitAppOpenCount] = useState(false);
  const [isUrlVisit, setIsUrlVisit] = useState(true);
  const [loader,setLoader]= useState(true);
  const [noInternetScreen, setNotInternetScreen] = useState(false)
  const settings = useSelector((state) => state.settings.data);
  const payment = useSelector((state)=> state.getPaymentStatus.data);
  const printAndFinalSubmitData = useSelector((state) => state.printAndFinalSubmit.data)
  const showLoader = useSelector((state) => state.showLoader.data.showLoader);
  const closeModalUrl = ["/survey", "categorySurvey", "/specificSurvey"]
  const dispatch = useDispatch();
  //This use Effect will hit getSettings Api
  useEffect(() => {
    setLoader(true);
    callNative({ type: "appLanguag" });
    window.sendAppLanguage = (lang) => {
      browserStorage.setItemValue(
        browserStorageKeys.NATIVE_LANG,
        JSON.stringify(lang)
      );
    };
    if (
      !browserStorage.getItemValue(browserStorageKeys.SELECTED_GEOGRAPHY) &&
      !browserStorage.getItemValue(browserStorageKeys.NATIVE_LOCATION)
    ) {
      callNative({ type: "adparams" });
      window.sendAdParams = (data) => {
        if(data.latitude && data.longitude !== 0){
          browserStorage.setItemValue(browserStorageKeys.NATIVE_LOCATION,JSON.stringify(data));
        }
      };
    }

    if (
      window.location.search !== "" &&
      browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) &&
      browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID) &&
      !isUrlVisit
    ) {
      setIsUrlVisit(false);
      window.location = window.location.search;
    }
    if (window.location.pathname === "/") {
      dispatch(getSettings());
    }
    window.addEventListener('online', ()=>handleInternetConnection())
    window.addEventListener("offline", ()=>handleNoInternet())
    let params = new URLSearchParams(window.location.search);

    if (!localStorage.getItem("jwtData")) {
      if (window.location.search.includes("jwt")) {
        if (!localStorage.getItem("jwtData") && params.has("jwt")) {
          localStorage.setItem("jwtData", params.get("jwt"));
          dispatch(appOpenCount(params.get("jwt")));
          setLoader(false)
        }
      }
    }

    if (browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)) {
      var langSet = JSON.parse(
        browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
      );
      langSet = langSet.code;
      if (
        browserStorage.getItemValue(browserStorageKeys.LANG_TYPE) == langSet
      ) {
        $("body").addClass(langSet);
      }
    }
  }, []);

// Below useEffect is for Deep links
useEffect(()=>{
  let params_link = url_utility.params.get_param();
  
  if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("selectedId") && params_link.hasOwnProperty("category")){
    params_link["selectedId"] = Number(params_link.selectedId);
    browserStorage.setItemValue(browserStorageKeys.SURVEY_TYPE,JSON.stringify(params_link))
  }
  if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("ver")){
    browserStorage.setItemValue(browserStorageKeys.IS_NEW_VERSION,true)
  } else {
    browserStorage.removeItemValue(browserStorageKeys.IS_NEW_VERSION)
  }

  if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("category") && params_link.category === "advertisement"){
    browserStorage.setItemValue(browserStorageKeys.SURVEY_TYPE_ADVERTISEMENT,JSON.stringify(params_link));
  }
  /* Setting current product based on the object created using param_links */ 
  if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("productType") ){
    browserStorage.setItemValue(browserStorageKeys.CURRENT_PRODUCT, params_link.productType);
  }
/* Setting deepLinkUrl so that a first time user can also avail service */ 
  if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("name") ){
    browserStorage.setItemValue(browserStorageKeys.DEEP_LINK_URL, window.location.search);
  }
  if(window.location.search !== "" && browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) && browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID) && !isUrlVisit){
      setIsUrlVisit(false)
      window.location = window.location.search;
  }


  if(window.newVersion){
    browserStorage.setItemValue(browserStorageKeys.IS_NEW_VERSION,true)
  }
  // dispatch(getSettings());
  let params = new URLSearchParams(window.location.search);
  if(!localStorage.getItem("jwtData")){
      if(window.location.search.includes("jwt")){
          if(!localStorage.getItem("jwtData") && params.has('jwt')){
              localStorage.setItem("jwtData", params.get("jwt"))
              dispatch(appOpenCount(params.get("jwt")))
              setLoader(false)
          } 
      } 
  } else {
    if (localStorage.getItem("jwtData") && browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID) && !browserStorage.getItemValue(browserStorageKeys.AUTHORIZATION_TOKEN)) {
      dispatch(saveJwt(Number(browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID))));
    }
    let jwtData = browserStorage.getItemValue(browserStorageKeys.JWT_DATA)
    dispatch(appOpenCount(jwtData));
    setLoader(false)
  }
  return () => {
    /* Setting deepLinkUrl so that a first time user can also avail service */ 
  if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("name") ){
    browserStorage.setItemValue(browserStorageKeys.DEEP_LINK_URL, window.location.search);
  }
  }
},[])


  const handleInternetConnection = () =>{
    if(closeModalUrl.includes(window.location.pathname)){
      setNotInternetScreen(false)
    }
    else{
      history.go(0)
    }
    }
    const handleNoInternet = () => {
      dispatch({type: "HANDLE_LOADER", payload:{showLoader: false}})
      setNotInternetScreen(true)
    }

  useEffect(() => {
    window.callWebview = (msgObj = "") => {
      try {
        msgObj = JSON.parse(msgObj);
      } catch (e) {}
    };

    window.sendJWT = (data) => {
      //callback
      if (typeof data === "object") {
        data = JSON.stringify(data);
        localStorage.setItem("jwtData", data);
        window.sessionStorage.setItem("call_back_data_jwt", data);
      }
    };

    window.sendJwt = (data) => {
      if (typeof data === "object") {
        data = JSON.stringify(data);
        localStorage.setItem("jwtData", data);
        window.sessionStorage.setItem("call_back_data_jwt", data);
      }
    };

    if (!localStorage.getItem("jwtData")) {
      callNative({ type: "jwt" });
    }
    let params = new URLSearchParams(window.location.search);

    if (params && !params.has("closebutton")) {
      callNative({ type: "loadingCompleted" });
    }
  }, []);

  //This useEffect hook is for saving token, checking platform and also saving JWT from getSettings
  useEffect(() => {
    if (settings) {
      if (settings.userToken) {
        if (
          navigator.platform.includes("Win") ||
          navigator.platform.includes("MacIntel") ||
          navigator.platform.includes("Linux x86_64") ||
          navigator.platform.includes("Linux aarch64")
        ) {
          if (
            !browserStorage.getItemValue(browserStorageKeys.JWT_DATA) &&
            !hitAppOpenCount
          ) {
            setHitAppOpenCount(true);
            browserStorage.setItemValue(
              browserStorageKeys.JWT_DATA,
              settings.userToken.toString()
            );
            dispatch(appOpenCount(settings.userToken));
          }
        } else {
          if (
            !browserStorage.getItemValue(browserStorageKeys.JWT_DATA) &&
            !hitAppOpenCount
          ) {
            setHitAppOpenCount(true);
            browserStorage.setItemValue(
              browserStorageKeys.JWT_DATA,
              settings.userToken.toString()
            );
            dispatch(appOpenCount(settings.userToken));
          }
        }
        setLoader(false)
      }

      if (
        !browserStorage.getItemValue(browserStorageKeys.GET_SETTINGS_VERSION)
      ) {
        browserStorage.setItemValue(
          browserStorageKeys.GET_SETTINGS_VERSION,
          settings.current_version
        );
        browserStorage.setItemValue(
          browserStorageKeys.SETTINGS_DATA,
          JSON.stringify(settings)
        );
      } else {
        if (
          browserStorage.getItemValue(
            browserStorageKeys.GET_SETTINGS_VERSION
          ) !== settings.current_version
        ) {
          let jwt = browserStorage.getItemValue(browserStorageKeys.JWT_DATA);
          let geographyId = browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID);
          let langObj = browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
          let currentProduct = browserStorage.getItemValue(browserStorageKeys.CURRENT_PRODUCT)
          let districtList = browserStorage.getItemValue(browserStorageKeys.DISTRICT_LIST)
          let langType = browserStorage.getItemValue(browserStorageKeys.LANG_TYPE)
          let langApiData = browserStorage.getItemValue(browserStorageKeys.LANGUAGE_API_DATA)
          let selectedGeography = browserStorage.getItemValue(browserStorageKeys.SELECTED_GEOGRAPHY)
          let activeProfile = browserStorage.getItemValue(browserStorageKeys.ACTIVE_PROFILE)
          let nativeLang = browserStorage.getItemValue(browserStorageKeys.NATIVE_LANG)
          let nativeLocation = browserStorage.getItemValue(browserStorageKeys.NATIVE_LOCATION)
          let nativeApiData = browserStorage.getItemValue(browserStorageKeys.NATIVE_API_DATA)
          localStorage.clear();
          browserStorage.setItemValue(browserStorageKeys.JWT_DATA, jwt);
          browserStorage.setItemValue(browserStorageKeys.GEOGRAPHY_ID, geographyId);
          browserStorage.setItemValue(browserStorageKeys.LANGUAGE_OBJ, langObj);
          browserStorage.setItemValue(browserStorageKeys.CURRENT_PRODUCT, currentProduct);
          browserStorage.setItemValue(browserStorageKeys.LANG_TYPE, langType);
          browserStorage.setItemValue(browserStorageKeys.DISTRICT_LIST, districtList);
          browserStorage.setItemValue(browserStorageKeys.LANGUAGE_API_DATA, langApiData);
          browserStorage.setItemValue(browserStorageKeys.SELECTED_GEOGRAPHY, selectedGeography);
          browserStorage.setItemValue(browserStorageKeys.ACTIVE_PROFILE, activeProfile);
          browserStorage.setItemValue(browserStorageKeys.IS_INTRODUCTION_BUTTON_CLICKED, false)
          if(nativeLang) browserStorage.setItemValue(browserStorageKeys.NATIVE_LANG, nativeLang);
          if(nativeLocation) browserStorage.setItemValue(browserStorageKeys.NATIVE_LOCATION, nativeLocation);
          if(nativeApiData) browserStorage.setItemValue(browserStorageKeys.NATIVE_API_DATA, nativeApiData);
          browserStorage.setItemValue(
            browserStorageKeys.GET_SETTINGS_VERSION,
            settings.current_version
          );
          browserStorage.setItemValue(
            browserStorageKeys.SETTINGS_DATA,
            JSON.stringify(settings)
          );
          let getProductHit = false;
          if (!getProductHit) {
            getProductHit = true;
            if (browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID)) {
              dispatch(saveJwt(browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID)));
              dispatch(
                getProduct(
                  browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID)
                )
              );
              dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
              history.replace("/redirect");
            } else {
              history.replace("/");
            }
          }
        }
      }
      setLoader(false);
    }

    if (
      !browserStorage.getItemValue(browserStorageKeys.CURRENT_PRODUCT) ||
      window.location.pathname === "/home"
    ) {
      browserStorage.setItemValue(browserStorageKeys.CURRENT_PRODUCT, "scheme");
    }
  }, [settings]);

  useEffect(() => {
    if (payment) {
      setLoader(false);
    }
  }, [payment]);
  useEffect(() => {
    if (payment) {
      setLoader(false);
    }
  }, [printAndFinalSubmitData]);
  return (
    <div className="App" data-testid="app">
      {showLoader || loader === true? <Loader /> : null}
      {noInternetScreen === true ? <NoNetworkPage/>:null}
      <BrowserRouter history={history}>
        <Suspense fallback={<Loader />}>
          <RoutesMobile />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
