import axios from "axios";
import { getUserFamilyProfile } from "./getUserFamilyProfileAction";
import browserStorage from "../../utility/browserStorage";
import browserStorageKeys from "../../common/browserStorageKeys";
import {HandleApiError} from "../../utility/handleApiError"
import { responseTime } from "../../utility/responseTime";

export const saveJwt = (geographyId) => {
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "",
    },
  });

  let formData = {};
  if (browserStorage.getItemValue(browserStorageKeys.JWT_DATA)) {
    formData["jwt"] = browserStorage.getItemValue(browserStorageKeys.JWT_DATA);
    formData["geographyId"] = geographyId;
    formData["languageCode"] =
      browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ) &&
      JSON.parse(browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)).code;
  }
  return (dispatch) => {
    const startTime = performance.now();
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    instance
      .post("saveJwt/", formData)
      .then((response) => {
        responseTime(performance.now(),startTime)
        if (response.status === 200) {
            dispatch({ type: "SAVE_JWT", payload: response.data });
            dispatch(getUserFamilyProfile(true));
          }
        }
      )
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
        console.log(err);
        HandleApiError(err)
      });
  };
};
