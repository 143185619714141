var language = {
	"TM_Txf6": "உங்களுக்கு விருப்பமான மொழியைத் தேர்ந்தெடுக்கவும்",
	"TM_Pag17": "இருப்பிடத்தை மாற்று",
  "TM_Pag13": "வேலை நன்மைகள்",
	"TM_But15": "தொடர",
	"TM_Pag19": "மொழியை தேர்ந்தெடுங்கள்",
	"TM_But1": "ஆரம்பிக்கலாம்",
	"TM_Pag20": "இடம் தேர்ந்தெடுக்கவும்",
	"TM_Txf9": "உங்கள் இருப்பிடத்தை வழங்குவது, உங்கள் பகுதியில் உங்களுக்குத் தகுதியான பலன்களைக் காட்ட உதவும்.",
	"TM_Txf3": "தேடல் நிலை",
	"TM_Txf4": "தேடல் மாவட்டம்",
  "TM_But37":"விண்ணப்பத்தைப் பார்க்கவும்",
  "TM_Tag8":"நிலை",
  "TM_Pag35": "விண்ணப்ப நிலை",
	"TM_Alt1": "3000+ நலத் திட்டங்களுக்கான தகுதியைச் சரிபார்க்கவும்",
	"TM_Alt3": "உங்களுக்கு அருகிலுள்ள வேலை வாய்ப்புகளைக் கண்டறியவும்",
	"TM_Alt2": "நீங்கள் விண்ணப்பிக்க விரும்பும் சேவைகளைச் சரிபார்க்கவும்",
	"TM_Lin1": "பயன்பாட்டை அணுகுவதன் மூலம், EasyGov’s ஐ ஒப்புக்கொள்கிறீர்கள்",
	"TM_Alt5": "நீங்கள் தகுதிபெறக்கூடிய நலன்புரி பலன்களைக் கண்டறிய எங்களுக்கு உதவ சுயவிவரத்தை உருவாக்கவும்",
	"TM_But2": "உங்கள் சுயவிவரத்தை உருவாக்கவும்",
	"TX_Sub1": "திட்ட வகைகள்",
	"TX_Sub2": "முதன்மைத் திட்டங்கள்	",
	"TX_Sub3": "புதிதாக சேர்க்கப்பட்ட திட்டங்கள்",
	"TX_Sub4": "பிரபலமான திட்டங்கள்",
  "TX_Sub13": "எனது சேவைகள்",
  "TM_Pag6": "எனது சேவைகள்",
	"TM_Pag1": "திட்ட வகைகள்",
	"TM_Alt6": "திட்டங்கள்",
	"TM_Alt7": "சேவைகள்",
	"TM_Alt8": "வேலைகள்",
	"TM_Alt9": "தேடு",
	"TM_Alt10": "சுயவிவரம்",
	"TX_Sub7": "சேவை வகைகள்",
	"TX_Sub8": "சிறந்த தரமதிப்பீடு பெற்ற சேவைகள்",
	"TX_Sub9": "புதிதாக சேர்க்கப்பட்ட சேவைகள்",
	"TX_Sub10": "பிரபலமான சேவைகள்",
	"TM_Pag18": "சேவை வகைகள்",
	"TM_Alt16": "உங்களுக்கான மிகவும் பொருத்தமான வேலைகளைக் கண்டறிய எங்களுக்கு உதவ சுயவிவரத்தை உருவாக்கவும்",
	"TM_Alt17": "வேலை விவரங்கள்",
  "TM_Alt18" : "வேலை விவரம்",
  "TM_Alt19":"ஆங்கிலம்", 
  "TM_Alt20":"ஹிந்தி",
  "TM_Alt21":"ஹிந்தி",
  "TM_Alt22":"கன்னடம்",
  "TM_Alt23":"பஞ்சாபி",
  "TM_Alt24":"மலையாளம்",
  "TM_Alt25":"பெங்காலி",
  "TM_Alt26":"குஜராத்தி",
  "TM_Alt27":"தெலுங்கு",
  "TM_Alt28":"மராத்தி",
  "TM_But3":"அனைத்தையும் காட்டு",
"TM_But5":"தகுதியை சரிபார்க்கவும்",
"TM_But10":"மேலும் வேலைகளை ஆராயுங்கள்",
"TX_Sub15":"சிறந்த தரமதிப்பீடு பெற்ற வேலைகள்",
"TX_Sub16":"புதிதாக சேர்க்கப்பட்ட வேலைகள்",
"TX_Sub17":"பிரபலமான வேலைகள்",
"TX_Sub18":"பரிந்துரைக்கப்பட்ட வேலைகள்",
"TX_Sub20":"எனது வேலைகள்",
"TM_alt11":"பலன்கள்",
"TM_Pag4":"திட்ட விவரங்கள்",
"TM_Pag21": "திட்டங்கள்",
"TM_Pag22": "சேவைகள்",
"TM_Pag23": "வேலைகள்",
"TM_Alt30" : "திட்டங்கள், சேவைகள் மற்றும் வேலைகளைத் தேடுங்கள்",
"TM_Lab6": "சமீபத்தில் தேடியது",
"TM_Err4": "முடிவுகள் எதுவும் இல்லை",
"TM_Err12": "இந்தச் சேவை உங்கள் இருப்பிடத்தில் இல்லை.",
"TM_Mod25":"மொழி மாறவும்",
"TM_Rec33":"இந்த சேவை ஆங்கிலத்தில் மட்டுமே கிடைக்கும். பயன்பாட்டின் மொழியை மாற்ற தொடர என்பதைக் கிளிக் செய்யவும்",
"TM_Pag12":"வேலை விவரங்கள்",
"TM_Alt12":"தகைமைகள்",
"TM_Tag1":"இப்போதுதான் தொடங்கப்பட்டது",
"TX_Sub25" : "அடிக்கடிக் கேட்கப்படும் கேள்விகள்",
"TM_Sup1": "எங்களைப் பின்தொடரவும்",
"TM_Hep17":"உங்களிடம் ஏதேனும் கேள்விகள் இருந்தால் எங்களுக்குத் தெரியப்படுத்துங்கள், நாங்கள் 48 வணிக மணி நேரத்திற்குள் உங்களிடம் திரும்பி வருவோம்.",
"TM_Lab4":"மின்னஞ்சல்",
"TM_Lin2":"விதிமுறைகள் மற்றும் நிபந்தனைகள்",
"TM_Lin3":"தனியுரிமைக் கொள்கை",
"TM_But4":"பயனரை மாற்றவும்",
"TM_Pag15": "பயனரை மாற்றவும்",
"TM_Hep36":"தகுதியைப் பார்க்க அல்லது உங்கள் சேமித்த தரவை மேலும் செயலாக்க நீங்கள் விரும்பிய சுயவிவரத்தைத் தேர்ந்தெடுக்கலாம் அல்லது சேர்க்கலாம்.",
"TM_But18":"பயனரைச் சேர்க்கவும்",
"TM_Mod5": "{{Name}} கணக்கை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?",
"TM_Rec7":"உங்கள் EasyGov கணக்கை நீக்கினால், உங்கள் சுயவிவரத்திலும் உங்கள் கணக்குடன் தொடர்புடைய பிற சுயவிவரங்களிலும் சேமிக்கப்பட்டுள்ள எந்தத் தரவையும் அல்லது தகவலையும் உங்களால் மீட்டெடுக்க முடியாது. உங்கள் பரிந்துரைக்கப்பட்ட மற்றும் சேமித்த திட்டங்கள் அனைத்தும் நீக்கப்படும்.",
"TM_Err2":"செயலில் உள்ள பயன்பாடு(கள்) இருப்பதால் {{Name}} சுயவிவரத்தை நீக்க முடியாது.",
"TM_But11":"அழி",
"TM_But12": "ரத்து செய்",
"TM_But30" : "அனைத்து வேலைகளையும் பார்க்கவும்",
"TM_But31" : "அனைத்து திட்டங்களையும் பார்க்கவும்",
"TM_Con11": "ஏற்றுகிறது",
"TM_The1":"சேமிக்கப்பட்டது",
"TM_The2":"வரைவு",
"TM_The3":"விண்ணப்பிக்கப்பட்டது",
"TM_The4":"பதிவுசெய்யப்பட்டது",
"TM_The5":"செயல்படுத்தப்பட்டது",
"TM_Hep7":"சேமிக்கப்பட்ட திட்டங்கள் இல்லை", 	
"TM_Hep21":"வரைவு திட்டங்கள் இல்லை",	
"TM_Hep22":"பயன்படுத்தப்பட்ட திட்டங்கள் இல்லை",
"TM_Hep23":"பதிவுசெய்யப்பட்ட திட்டங்கள் இல்லை",	
"TM_But7":"மேலும் திட்டங்களை ஆராயுங்கள்",
"TM_Rec1": "உங்கள் ‘எனது திட்டங்களில்’ இருந்து இந்தத் திட்டத்தை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
"TM_Rec2":"உங்கள் ‘எனது சேவைகளில்’ இருந்து இந்தச் சேவையை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
"TM_Pag2":"எனது திட்டங்கள்",
"TM_Hep10": "சுயவிவரத்திலிருந்து நீங்கள் விரும்பும் மொழியையும் இருப்பிடத்தையும் மாற்றலாம்",
"TM_But21": "அறிந்துகொண்டேன்!",
"TM_Alt29":"மொழி & இடம்",
"TM_Hep38":"{{category}} பிரிவில் பொருத்தமான திட்டங்களைக் கண்டறிய எங்களுக்கு உதவ, {{category}} தொடர்பான சில கூடுதல் கேள்விகளுக்குப் பதிலளிக்கவும்",
"TM_Hep11":"உங்கள் சுயவிவரத்தின் அடிப்படையில், நீங்கள் எந்தத் திட்டத்திற்கும் தகுதியுடையவராக இருக்கவில்லை. நாங்கள் தொடர்ந்து கூடுதல் திட்டங்களைச் சேர்த்து வருகிறோம், பிறகு சரிபார்க்கவும்.",
"TM_Hep39":"{{category}} பிரிவில் பொருத்தமான வேலைகளைக் கண்டறிய எங்களுக்கு உதவ, {{category}} தொடர்பான சில கூடுதல் கேள்விகளுக்குப் பதிலளிக்கவும்",
"TM_Rec6": "தற்போது உங்கள் மாநிலத்தில் உள்ளமைக்கப்பட்ட வேலைகள் எதுவும் இல்லை, நாங்கள் தொடர்ந்து புதுப்பித்து வருகிறோம், தயவுசெய்து எங்களை மீண்டும் பார்க்கவும்",
"TM_But27": "சரி",
"TX_Sub5": "பரிந்துரைக்கப்பட்ட திட்டங்கள்",
"TM_Pag9": "வேலை வகைகள்",
"TM_Hep42": "உங்கள் சுயவிவரத்தின் அடிப்படையில், நீங்கள் எந்த வேலைக்கும் தகுதியானவர் என்று நாங்கள் கண்டறியவில்லை. நாங்கள் தொடர்ந்து அதிக வேலைகளைச் சேர்த்து வருகிறோம், பிறகு சரிபார்க்கவும்.",
"TM_Hep40":"விண்ணப்பிக்க" ,
"TM_Hep41":"மணி",
"TM_Hep2": "வகை",
"TM_Alt31": "சேவையில்",
  "TM_Alt32" : "திட்டத்தில்",
  "TM_Alt33" : "வேலையில்",
  "TM_Pag14": "சுயவிவரத்தை உருவாக்கவும்",
  "TM_Hep6":"சேமித்த சேவைகள் இல்லை",
  "TM_Hep13":"வரைவு சேவைகள் இல்லை.",
  "TM_Hep14":"பயன்பாட்டு சேவைகள் இல்லை.",
  "TM_Hep15":"அறிவிப்புகள் இல்லை",
  "TM_Hep54":"இன்று",
  "TM_Hep55":"நேற்று",
  "TM_Hep44":"செயலாக்கப்பட்ட சேவைகள் இல்லை",
  "TM_Hep9": "சுயவிவரத்தை உருவாக்குவது, நீங்கள் ஆர்வமுள்ள அனைத்து வாய்ப்புகளையும் சேமிக்கவும், உங்கள் சேமித்த தரவை மேலும் செயலாக்க எந்த நேரத்திலும் மீண்டும் வரவும் உங்களை அனுமதிக்கிறது.",
  "TM_Txf1": "முழு பெயர்",
  "TM_Txf2": "உறவு",
  "TM_But29": "சேமிக்கவும்",
  "TM_Err1": "அதே பெயர் மற்றும் தொடர்புடைய சுயவிவரம் ஏற்கனவே உள்ளது!",
  "TM_Err6": "எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன",
  "TM_Pag16": "மொழியை மாற்றவும்",
  "TM_Pag33": " இருப்பிடத்தைப் புதுப்பி",
  "TM_But22": " புதுப்பி ",
  "TM_But14": "சுயவிவரத்தைச் சேர்க்கவும்",
  "TM_Txf15": "பெயர் சேர் ",
  "TM_But20":"தொகு",
  "TM_Hep16": "பயன்பாட்டைப் பயன்படுத்தும் போது உங்கள் அனுபவத்தை மேம்படுத்தவும், உங்களுக்கான துல்லியமான திட்டங்கள்/சேவைகள்/வேலைகளைக் காண்பிக்கவும் உங்கள் சரியான இருப்பிடத்தைத் தேர்வுசெய்யவும்.",
  "TM_Pag26": "இருப்பிடத்தை உறுதிப்படுத்தவும்",
  "TM_But23": "உறுதிப்படுத்தவும்",
  "TX_Sub21":"அறிவிப்புகள்",
  "TX_Sub22":"சுயவிவரத்தை நிர்வகிக்கவும்",
  "TX_Sub23":"இடம்",
  "TX_Sub24":"மொழி",
  "TX_Sub25":"அடிக்கடி கேட்கப்படும் கேள்விகள்",
  "TX_Sub26":"எங்களை பற்றி",
  "TX_Sub27":"கணக்கை நீக்குக",
  "TX_Sub28":"தனிப்பட்ட விவரங்கள்",
  "TX_Sub29":"பொருளாதார விவரங்கள்",
  "TX_Sub30":"வீட்டு விவரங்கள்",
  "TX_Sub31":"கல்வி விவரக்குறிப்பு",
  "TX_Sub32":"தொழில் சுயவிவரம்",
  "TX_Sub33":"சுகாதார சுயவிவரம்",
  "TM_Txf13":"மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
  "TM_Txf14":"மாவட்டத்தைத் தேர்ந்தெடுக்கவும்",
  "TM_Pag29":"சுயவிவர உருவாக்கம்",
  "TM_Con9":"மன்னிக்கவும்! உங்களுக்காக பொருந்தக்கூடிய திட்டங்கள் எதையும் எங்களால் கண்டுபிடிக்க முடியவில்லை",
  "TM_Con10":"மன்னிக்கவும்! உங்களுக்கான பொருத்தமான வேலைகள் எதையும் எங்களால் கண்டுபிடிக்க முடியவில்லை",
  "TM_But26": "வீடு",
  "TM_Rec35": "ஆதாரம் கிடைக்கவில்லை",
  "TM_Rec36":"சர்வர் நேரம் முடிந்தது!",
  "TM_Rec37":"அனுமதி மறுக்கப்பட்டது!",
  "TM_Mod28":"ஏதோ தவறு நடைபெற்றிருக்கிறது.",
  "TM_Rec10": "நாங்கள் தொடர்ந்து மேம்படுத்தி வருகிறோம், பிறகு முயற்சிக்கவும்.",
  "TM_But24":"மீண்டும் முயற்சிக்கவும்",
  "TM_Hep43":"முந்தைய கேள்விக்கு வலதுபுறமாக ஸ்வைப் செய்யவும்",
  "TM_Hep28":"உங்கள் சுயவிவரத்தின்படி தொடர்புடைய திட்டங்களைக் கண்டறிய கேள்விகளுக்கு பதிலளிக்கவும்",
  "TM_Hep30":"உங்கள் சுயவிவரத்தின்படி தொடர்புடைய வேலைகளைக் கண்டறிய கேள்விகளுக்குப் பதிலளிக்கவும்",
  "TM_Hep32": "நீங்கள் தகுதியுடையவராக இருக்கலாம்",
  "TM_Hep35":"சில கேள்விகளுக்கு பதிலளிக்கவும்",
  "TM_But16":"அடுத்தது",
  "TM_Rel1":"மகள்",
  "TM_Rel2":"தந்தை",
  "TM_Rel3":"தாய்",
  "TM_Rel4":"தாய்",
  "TM_Rel5":"தமையன்",
  "TM_Rel6":"சகோதரி",
  "TM_Rel7":"வாழ்க்கைத் துணை",
  "TM_Rel8":"வேறொருவர்",
  "TM_Rel9": "சுய",
  "TM_Mod4": "கவனம்",
  "TM_Mod2": "சேவைகளை நீக்கு",
  "TM_Rec12": "நீங்கள் தரவை மாற்றினால், அது உங்கள் சுயவிவரத் தரவிலும் புதுப்பிக்கப்படும். தொடர வேண்டுமா?",
  "TM_Hep12": "நீங்கள் முன்பு சேமித்த தரவை மதிப்பாய்வு செய்து உறுதிப்படுத்தவும்.",
  "TM_But28": "தொடரவும்",
  "TM_Pag24": "உங்கள் விவரங்களை மதிப்பாய்வு செய்யவும்",
  "TM_Pag25": "உங்கள் விவரங்களைப் புதுப்பிக்கவும்",
  "TM_Hep33":"நீங்கள் நன்றாக செய்கிறீர்கள்! தொடருங்கள்...",
  "TM_Con15":"நாங்கள் விவரங்களைப் பெறும் வரை காத்திருக்கவும்",
  "TM_But9" : "மேலும் சேவைகளை ஆராயுங்கள்",
  "TM_Pag5":"திட்ட விவரங்கள்",
  "TM_Pag8":"சேவை விவரங்கள்",
  "TM_Hep3": "சேவை நிலை",
  "TM_But6": "இணைப்பை நகலெடுக்கவும்",
  "TM_Con1": "இணைப்பு நகலெடுக்கப்பட்டது",
  "TM_Con5": "பதிவிறக்கம் செய்யப்பட்டது",
  "TM_Alt14": "எப்படி விண்ணப்பிப்பது",
  "TM_Alt13": "ஆவணங்கள்",
  "TM_Alt15": "தகவல்",
  "TM_Con3": `இந்தத் திட்டம் உங்கள் “எனது திட்டத்தில்” சேமிக்கப்பட்டது`,
  "TM_Con2": `இந்த சேவை உங்கள் “எனது சேவை”யில் சேமிக்கப்பட்டுள்ளது`,
  "TM_Alt34":"விண்ணப்பிப்பதற்கான வழிமுறை",
  "TM_Alt35":"விண்ணப்ப இணைப்பு",
  "TM_Alt36":"பதிவிறக்கப் படிவம்",
  "TM_Alt38":"தேர்வு நடைமுறை",
  "TM_Alt41":"திட்டத்தின் நன்மைகள்",
  "TM_Alt56":"அஞ்சல்",
  "TM_Alt57":"சம்பளம்",
  "TM_Alt58":"வேலை விவரங்கள்",
  "TM_Alt59":"விண்ணப்பிக்க கடைசி தேதி",
  "TM_Txf12": "காரணம்",
  "TM_Hep51": "தகுதியான மதிப்புகள்",
  "TM_Hep52": "நீங்கள் வழங்கிய மதிப்பு",
  "TM_Con7" : "மன்னிக்கவும், இந்த திட்டத்திற்கு நீங்கள் தகுதி பெறவில்லை",
  "TM_Con8" : "மன்னிக்கவும், இந்த வேலைக்கு நீங்கள் தகுதி பெறவில்லை",
  "TM_Err5" : "எந்த விருப்பங்களும் இல்லை",
  "TM_Con13" : "உங்கள் தரவு வெற்றிகரமாக சேமிக்கப்பட்டது.",

  "TM_Hep8" : "சேமிக்கப்பட்ட வேலைகள் இல்லை",
  "TM_Pag10": "எனது வேலைகள்",
  "TM_Mod3" : "வேலையை நீக்கு",
  "TM_Rec3" : "உங்களின் ‘எனது வேலைகள்’ என்பதிலிருந்து இந்த வேலையை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  "TM_Hep24": "வரைவு வேலைகள் இல்லை",
  "TM_Hep25": "பயன்பாட்டு வேலைகள் இல்லை",
  "TM_Hep26": "பதிவுசெய்யப்பட்ட வேலைகள் இல்லை",
  "TM_Hep27": 'காலாவதியான தேதியிலிருந்து 30 நாட்களுக்குப் பிறகு உங்கள் "எனது வேலைகள்" இலிருந்து காலாவதியான அனைத்து வேலைகளும் அகற்றப்படும்.',
  "TM_Tag3" : "காலாவதியான",
  "TM_Tag4" : "மூலம் விண்ணப்பிக்கவும்",
  "TM_Con4":  'இந்த வேலை உங்கள் "எனது வேலையில்" சேமிக்கப்பட்டது',
  "TM_Rec5":"உங்கள் தரவு அனைத்தும் நீக்கப்பட்டது",
  "TM_Rec4": "உங்கள் கணக்கை நீக்க முதலில் உங்கள் கணக்குடன் தொடர்புடைய அனைத்து சுயவிவரங்களையும் நீக்க வேண்டும்",
  "TM_Pag41": "விண்ணப்ப விவரங்கள்",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "இந்த ஆவணத்தை நிச்சயமாக அகற்ற விரும்புகிறீர்களா?",
  "TM_Mod18": "கேலரி",
  "TM_Mod17": "கேமரா",
  "91412465ea9169dfd901dd5e7c96dd99": "பதிவேற்றம் ",
  "af6ebe0c0e89201cb2294f32955f7b15": "ஒரு நேரத்தில் 20 ஆவணங்கள் மட்டுமே அனுமதிக்கப்படும் .",
  "d5de543c0e83073f05a846d62cc83c81": "ஆவணம் ஏற்கனவே அதே துறையில் பதிவேற்றப்பட்டது.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "செல்லத்தக்கது உள்ளிடவும்",
  "3f68e67dc6c397aaa9d1c24c356f754f": "சரிபார்க்கப்பட்டது",
  "1e410e1b9903e7a99b93857f536b7e1d": "சரிபார்க்கப்படவில்லை",
  "f8d855b26faffa0f8779fb44632d6bc1": "தவறான கேப்ட்சா குறியீடு",
  "d53b4d564b9054db9b4b4a50e713197f": "தயவுசெய்து கேப்ட்சாவை இங்கே உள்ளிடவும்",
  "TM_Con18": "உங்கள் 'வரைவு திட்டத்தில்' உங்கள் விண்ணப்பம் வெற்றிகரமாகச் சேமிக்கப்பட்டது",
  "TM_Con24": "உங்கள் விண்ணப்பம் வெற்றிகரமாக உங்கள் 'டிராஃப்ட் ஜாப்' இல் சேமிக்கப்பட்டது",
  "TM_Con23": `உங்கள் விண்ணப்பம் உங்கள் "வரைவு சேவைகளில்" வெற்றிகரமாகச் சேமிக்கப்பட்டது`,
  "6cf24d7a07132fe88b610e2b3d3def28": "சுய பிரகடனம்",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "இந்தப் புலம் தேவைப்படுகிறது.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "தயவுசெய்து எழுத்துக்கள், எண்கள், கமா, -, _, / மட்டும் உள்ளிடவும்",
  "562d3d08919ae105c3ebc42d9607d266": "தயவுசெய்து எழுத்துக்கள் அல்லது எண்களை மட்டும் உள்ளிடவும்",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படும்.",
  "4906d981bb592b32f6982fb4187c7b60": "சரியான ஐஎஃப்எஸ்சி குறியீட்டை உள்ளிடவும்.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "11 எழுத்துகள் மட்டுமே அனுமதிக்கப்படும்.",
  "7c58cde3b827bc7d57cbf77865046169": "தயவுசெய்து எண்களை மட்டும் உள்ளிடவும்",
  "544b0264d55a1e4cf476b279240d9be4": "பத்து இலக்கங்கள் மட்டுமே அனுமதிக்கப்படும். தயவுசெய்து 0 அல்லது +91 ஐ முன்னொட்டாக இணைக்க வேண்டாம்",
  "73f0d980e48b8141e0776e02d96a872f": "பன்னிரண்டு இலக்கங்கள் மட்டுமே அனுமதிக்கப்படும்.",
  "0ce98bd973419ff33921a2275e7903b9": "தயவுசெய்து சரியான பான் எண்ணை உள்ளிடவும்.",
  "e55e425dbaf6b6449940eac8ea749092": "தயவுசெய்து எழுத்துக்களை மட்டும் உள்ளிடவும்.",
  "64ff9e3809686d4ea3253a631cae4761": "தயவுசெய்து 2 தசம இடங்களுக்கு சரியான மதிப்பை உள்ளிடவும்.",
  "7ed0ef23d527c2876015161e422f49ce": "தயவுசெய்து உங்கள் பெயரை கன்னட மொழியில் உள்ளிடவும்.",
  "230486b5dd7afed96556ba9bd4175423": "பின் குறியீடு தவறாக உள்ளது",
  "9f44bd93c8988c682d5ef5369fd11f47": "தயவுசெய்து செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
  "ac598199ea0dd028ade4677a435caf4b": "செல்லுபடியாகும் ஆதார் எண்ணை உள்ளிடவும்",
  "10803b83a68db8f7e7a33e3b41e184d0": "பிறந்த தேதி",
  "d0de720ccdffea8490dfd2893c50ebc5": "இது {{displayName}} ஐ விட அதிகமாக இருக்க வேண்டும்",
  "ced6e171e13ebd5c37415c822706b9d4": "இது {{displayName}} ஐ விட குறைவாக இருக்க வேண்டும்",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "இது {{displayName}}க்கு சமமாக இருக்க வேண்டும்",
  "9483135517d156229d0439dff74b1ca0": "இது {{displayName}} ஐ விட அதிகமாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்",
  "01e9cd748e852b1ae389b980bff195b0": "இது {{displayName}} ஐ விட குறைவாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்",
  "TM_Pag37": "மதிப்பாய்வு விவரங்கள்",
  "TM_Rec18": "உங்கள் நிரப்பப்பட்ட விவரங்களை மாற்ற 'திருத்து' என்பதைக் கிளிக் செய்யவும்.",
  "TM_Alt51": "இணைப்பு(கள்):",
  "TM_Alt42": "நன்றி",
  "TM_But9":"மேலும் சேவைகளை ஆராயுங்கள்",
  "TM_But19":"பதிவிறக்கு",
  "TM_Con17":"உங்கள் விண்ணப்பம் பதிவிறக்கப்பட்டது.",
  "TM_Sub48": "உதவி மற்றும் ஆதரவு",
  "TM_Alt66": "<b>jioeasygov@easygov.co.in</b> இல் எங்களுக்கு மின்னஞ்சல் அனுப்புங்கள், எங்கள் குழு விரைவில் உங்களிடம் திரும்பும்",
  "TM_Alt65": "ஏதேனும் கேள்விக்கு உதவி தேவையா?",
  "TM_Pag54": "சேவை நன்மைகள்",
  "TM_Alt37": "அரசு கட்டணம்",
  "TM_But105" : "மேலும் ஆராயுங்கள்",
  "TM_But17": "சமர்ப்பிக்கவும்",
  "TM_Alt39": "சமர்ப்பிப்பு முகவரி",
  "TM_But58": "விண்ணப்பிக்க தொடரவும்",
  "TM_But100" : "திரும்பி செல்",
  "TM_But95": "இப்போது செலுத்த",
  "TM_Alt88": "உங்கள் விண்ணப்பத்தைச் சமர்ப்பிக்க பின்வரும் தொகையைச் செலுத்தவும்",
  "TM_Con32" : "உங்கள் விண்ணப்பம் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது",
  "TM_But110": "விண்ணப்பம்",
  "TM_Txf16": "இங்கே தேடவும்",
  "TM_Hep69": "‘திட்ட ஆதரவைப் பெறு’ என்பதைக் கிளிக் செய்து, ஒரு குறுகிய கருத்துக்கணிப்பை மேற்கொள்ளுங்கள், இது உங்களுக்கு சரியான ஆதரவை வழங்க எங்களுக்கு உதவும்.",
  "TM_But107": "திட்ட ஆதரவைப் பெறுவது என்றால் என்ன?",
  "TM_But106": "திட்ட ஆதரவைப் பெறுங்கள்",
  "TM_Pag32": "திட்ட விழிப்புணர்வு கணக்கெடுப்பு",
  "TM_Con14": "விவரங்களைப் பகிர்ந்தமைக்கு நன்றி.",
  "TM_Tag5": "காலியாக இடத்தை",
  "TM_Alt107": "நாட்களில்",
  "TM_Rec11":"தேர்ந்தெடுத்த அறிவிப்புகளை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  "TM_Mod7":"அறிவிப்புகளை நீக்கவும்",
  "TM_But25":"அனைத்தையும் தேர்ந்தெடுக்கவும்",
  "TM_Mod1": "திட்டத்தை நீக்கவும்",
  "TM_Rec50": "உங்கள் விண்ணப்பத்தைத் திரும்பப் பெற விரும்புகிறீர்களா?",
  "TM_But23": "உறுதிப்படுத்தவும்",
  "TM_But12": "ரத்து செய்",
  "TM_Con6" : "உங்கள் பதிவு செய்யப்பட்ட மொபைல் எண்ணுக்கு OTP அனுப்பப்பட்டுள்ளது (xxxxxx{{phoneNumber}})",
  "TM_But17": "சமர்ப்பிக்கவும்",
  "TM_But61": "OTP ஐ மீண்டும் அனுப்பவும்",
  "TM_Con20": "உங்கள் விண்ணப்பம் வெற்றிகரமாக திரும்பப் பெறப்பட்டது",
  "TM_But38": "முழுமையான செயல்கள்",
  "TM_But62": "கருத்து தெரிவிக்கவும்",
  "TM_Lab11": "விண்ணப்பத்தை திரும்பப் பெறவும்",
  "TM_Err3" : "தவறான OTP",
  "TM_Err13": "OTP காலாவதியானது",
  "TM_Err14": "OTP ஐ உருவாக்குவதற்கான அதிகபட்ச வரம்பை மீறிவிட்டீர்கள்.  3 மணி நேரம் கழித்து மீண்டும் முயற்சிக்கவும்.",
  "TM_But40": "செயல்கள்",
  "TM_Tag9":  "தேவையான நடவடிக்கைகள்",
  "TM_Tag10": "எந்த நடவடிக்கையும் தேவையில்லை",
  "TM_Tag8" : "நிலை",
  "TM_But76" : "பார்க்கவும்",
  "TM_Alt87" : "ஆன்லைனில் கிடைக்கவில்லை",
  "TM_Pag34": "கட்டண விவரங்கள்",
  "TM_Hea1": "மொத்த தொகை",
  "TM_Hea2": "சுருக்கம்",
  "TM_Sub56": "மொத்தம்",
  "TM_But42": "பணம் செலுத்து",
  "TM_Lab18": "ரசீதை பார்க்கவும்",
  "TM_Err34": "பணம் செலுத்துவதில் தோல்வி ஏற்பட்டதால் உங்கள் விண்ணப்பத்தை சமர்ப்பிக்க முடியவில்லை.",
  "TM_Rec55": "உங்கள் கட்டணத்தின் நிலையை உறுதிசெய்ய நேரம் எடுக்கிறது. நாங்கள் தொடர்ந்து உங்கள் வங்கியில் சோதனை செய்து வருகிறோம். அடுத்த 48 மணிநேரத்திற்கு கூடுதல் பணம் செலுத்துவதைத் தவிர்க்கவும்.",
  "TM_But13": "திரும்பி செல்",
  "TM_Alt124":"தயவுசெய்து காத்திருக்கவும்",
  "TM_Rec58": "காத்திருங்கள்.உங்கள் கட்டண நிலையை நாங்கள் சரிபார்க்கிறோம்",
  "TM_Rec59": "இந்தச் சேவைக்கான உங்களின் கடைசிப் பணம் நிலுவையில் உள்ளது. அது முடிந்ததும் உங்களுக்குத் தெரிவிப்போம். இதற்கிடையில், எங்கள் பிற சேவைகளைப் பற்றி ஆராய தயங்க வேண்டாம். பொறுமை காத்தமைக்கு நன்றி.",
  "TM_But133": "இணைப்பை கிளிக் செய்யவும்"

}

export default language
