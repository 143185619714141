var language = {
	"TM_Txf6": "કૃપા કરીને તમારી પસંદગીની ભાષા પસંદ કરો",
	"TM_But15": "આગળ વધો",
	"TM_Pag19": "ભાષા પસંદ કરો",
	"TM_Pag17": "સ્થાન બદલો",
  "TM_Pag13": "જોબ લાભ",
	"TM_But1": "ચાલો, શરુ કરીએ",
	"TM_Pag20": "સ્થાન પસંદ કરો",
	"TM_Txf9": "તમારું સ્થાન પ્રદાન કરવાથી તમે તમારા વિસ્તારમાં તમે કયા લાભો માટે પાત્ર છો તે બતાવવામાં અમને મદદ મળશે.",
	"TM_Txf3": "શોધ રાજ્ય",
	"TM_Txf4": "શોધ જિલ્લો",
  "TM_But37":"એપ્લિકેશન જુઓ",
  "TM_Tag8":"સ્થિતિ",
  "TM_Pag35": "એપ્લિકેશન સ્થિતિ",
	"TM_Alt1": "3000+ કલ્યાણ યોજનાઓ માટે પાત્રતા તપાસો",
	"TM_Alt3": "તમારી નજીકમાં નોકરીની જગ્યાઓ શોધો",
	"TM_Alt2": "તમે અરજી કરવા માંગો છો તે સેવાઓ તપાસો",
	"TM_Lin1": "એપ્લિકેશનને ઍક્સેસ કરીને, તમે EasyGov’s સાથે સંમત થાઓ છો",
	"TM_Alt5": "તમે લાયક હોઈ શકો તેવા કલ્યાણ લાભો શોધવામાં અમારી મદદ કરવા માટે પ્રોફાઇલ બનાવો",
	"TM_But2": "તમારી પ્રોફાઇલ બનાવો",
	"TX_Sub1": "યોજના શ્રેણીઓ",
	"TX_Sub2": "ફ્લેગશિપ યોજનાઓ",
	"TX_Sub3": "નવી ઉમેરવામાં આવેલ યોજનાઓ",
	"TX_Sub4": "લોકપ્રિય યોજનાઓ",
	"TM_Pag1": "યોજના શ્રેણીઓ",
	"TM_Alt6": "યોજનાઓ",
	"TM_Alt7": "સેવાઓ",
	"TM_Alt8": "નોકરીઓ",
	"TM_Alt9": "શોધો",
	"TM_Alt10": "પ્રોફાઇલ",
	"TX_Sub7": "સેવા શ્રેણીઓ",
	"TX_Sub8": "ટોચની રેટેડ સેવાઓ",
	"TX_Sub9": "નવી ઉમેરવામાં આવેલ સેવાઓ",
	"TX_Sub10": "લોકપ્રિય સેવાઓ",
  "TX_Sub13":"મારી સેવાઓ",
  "TM_Pag6": "મારી સેવાઓ",
  "TM_Hep6":"કોઈ સાચવેલી સેવાઓ નથી",
  "TM_Hep13":"કોઈ ડ્રાફ્ટ સેવાઓ નથી",
  "TM_Hep14":"કોઈ લાગુ સેવાઓ નથી.",
  "TM_Hep54":"આજે",
  "TM_Hep55":"ગઈ કાલે",
  "TM_Hep15":"કોઈ સૂચનાઓ નથી",
  "TM_Hep44":"કોઈ પ્રોસેસ્ડ સેવાઓ નથી",
	"TM_Pag18": "સેવા શ્રેણીઓ",
	"TM_Alt16": "તમારા માટે સૌથી યોગ્ય નોકરીઓ શોધવામાં અમારી મદદ કરવા માટે પ્રોફાઇલ બનાવો",
	"TM_Alt17": "જોબ વિગતો",
  "TM_Alt18" : "કામનું વર્ણન",
  "TM_Alt19":"અંગ્રેજી", 
  "TM_Alt20":"હિન્દી",
  "TM_Alt21":"તમિલ",
  "TM_Alt22":"કન્નડ",
  "TM_Alt23":"પંજાબી",
  "TM_Alt24":"મલયાલમ",
  "TM_Alt25":"બંગાળી",
  "TM_Alt26":"ગુજરાતી",
  "TM_Alt27":"તેલુગુ",
  "TM_Alt28":"મરાઠી",
  "TM_But3":"બધુજ જુઓ",
"TM_But5":"પાત્રતા તપાસો",
"TM_But10":"વધુ નોકરીઓનું અન્વેષણ કરો",
"TX_Sub15":"ટોચની રેટેડ નોકરીઓ",
"TX_Sub16":"નવી ઉમેરેલી નોકરીઓ",
"TX_Sub17":"લોકપ્રિય નોકરીઓ",
"TX_Sub18":"ભલામણ કરેલ નોકરીઓ",
"TX_Sub20":"મારી નોકરી",
"TM_alt11":"લાભો",
"TM_Pag4":"યોજનાની વિગતો",
"TM_Pag21": "યોજનાઓ",
"TM_Pag22": "સેવાઓ",
"TM_Pag23": "નોકરીઓ",
"TM_Alt30" : "યોજનાઓ, સેવાઓ અને નોકરીઓ શોધો",
"TM_Lab6": "તાજેતરમાં શોધાયેલ",
"TM_Err4": "કોઈ પરિણામો મળ્યા નથી",
"TM_Err12": "આ સેવા તમારા સ્થાન પર ઉપલબ્ધ નથી.",
"TM_Mod25":"ભાષા સ્વિચ કરો",
"TM_Rec33":"આ સેવા માત્ર અંગ્રેજી ભાષામાં જ ઉપલબ્ધ છે. એપ્લિકેશનની ભાષા બદલવા માટે આગળ વધો પર ક્લિક કરો",
"TM_Pag12":"જોબ વિગતો",
"TM_Alt12":"યોગ્યતા",
"TM_Tag1":"હમણાં જ શરૂ થયેલ છે",
"TX_Sub25" : "વારંવાર પૂછાતા પ્રશ્ન",
"TM_Sup1": "અમને અનુસરો",
"TM_Hep17":"જો તમારી પાસે કોઈ ક્વેરી હોય તો અમને જણાવો અને અમે ૪૮ વ્યવસાયિક કલાકોની અંદર તમારી પાસે પાછા આવીશું.",
"TM_Lab4":"ઇમેઇલ",
"TM_Lin2":"નિયમો અને શરતો",
"TM_Lin3":"ગોપનીયતા નીતિ",
"TM_But4":"વપરાશકર્તા બદલો",
"TM_Pag15": "વપરાશકર્તા બદલો",
"TM_Hep36":"તમે યોગ્યતા જોવા અથવા તમારા સાચવેલા ડેટાને આગળ પ્રક્રિયા કરવા માટે તમારી ઇચ્છિત પ્રોફાઇલ પસંદ કરી શકો છો અથવા ઉમેરી શકો છો.",
"TM_But18":"વપરાશકર્તા ઉમેરો",
"TM_Mod5": "શું તમે ખરેખર {{Name}} એકાઉન્ટ કાયમ માટે કાઢી નાખવા માંગો છો?",
"TM_Rec7":"જ્યારે તમે તમારું EasyGov એકાઉન્ટ કાઢી નાખો છો, ત્યારે તમે તમારી પ્રોફાઇલ અને તમારા એકાઉન્ટ સાથે સંકળાયેલ અન્ય પ્રોફાઇલ્સમાં સંગ્રહિત કોઈપણ ડેટા અથવા માહિતી પુનઃપ્રાપ્ત કરી શકશો નહીં. તમારી બધી ભલામણ કરેલ અને સાચવેલી યોજનાઓ પણ કાઢી નાખવામાં આવશે.",
"TM_Err2":"તમે {{Name}} પ્રોફાઇલ કાઢી શકતા નથી કારણ કે તેની પાસે ચાલુ એપ્લિકેશન(ઓ) છે.",
"TM_But11":"કાઢી નાખો",
"TM_But12": "રદ કરો",
"TM_But30" : "બધી નોકરીઓ જુઓ",
"TM_But31" : "બધી યોજનાઓ જુઓ",
"TM_Con11": "લોડ કરી રહ્યું છે",
"TM_The1":"સેવ",
"TM_The2":"ડ્રાફ્ટ",
"TM_The3":"અરજી કરેલ",
"TM_The4":"નોંધાયેલ",
"TM_The5":"પ્રક્રિયા થઈ",
"TM_Hep7":"કોઈ સાચવેલી યોજનાઓ નથી", 	
"TM_Hep21":"કોઈ ડ્રાફ્ટ યોજનાઓ નથી",	
"TM_Hep22":"કોઈ લાગુ યોજનાઓ નથી",
"TM_Hep23":"કોઈ નોંધાયેલ યોજનાઓ નથી",	
"TM_But7":"વધુ યોજનાઓનું અન્વેષણ કરો",
"TM_Rec1": "શું તમે ખરેખર આ યોજનાને તમારી ‘મારી યોજનાઓ’માંથી કાઢી નાખવા માંગો છો?",
"TM_Rec2": "શું તમે ખરેખર આ સેવાને તમારી 'મારી સેવાઓ'માંથી કાઢી નાખવા માંગો છો?",
"TM_Pag2":"મારી યોજનાઓ",
"TM_Hep10": "તમે પ્રોફાઇલમાંથી તમારી પસંદગીની ભાષા અને સ્થાન બદલી શકો છો",
"TM_But21": "જાણ્યું!",
"TM_Alt29":"ભાષા અને સ્થાન",
"TM_Hep38":"{{category}} શ્રેણીમાં યોગ્ય યોજનાઓ ઓળખવામાં અમારી મદદ કરવા માટે, કૃપા કરીને {{category}} થી સંબંધિત કેટલાક વધારાના પ્રશ્નોના જવાબ આપો",
"TM_Hep11":"તમારી પ્રોફાઇલના આધારે, અમે તમને કોઈપણ યોજના માટે લાયક જણાયા નથી. અમે સતત વધુ યોજનાઓ ઉમેરી રહ્યા છીએ, કૃપા કરીને પછીથી તપાસો.",
"TM_Hep39":"{{category}} શ્રેણીમાં યોગ્ય નોકરીઓ ઓળખવામાં અમારી મદદ કરવા માટે, કૃપા કરીને {{category}} સંબંધિત કેટલાક વધારાના પ્રશ્નોના જવાબ આપો",
"TM_Rec6": "આ ક્ષણે તમારા રાજ્યમાં કોઈ નોકરી ગોઠવેલી નથી, અમે સતત અપડેટ કરીએ છીએ, કૃપા કરીને અમારી સાથે ફરી તપાસો",
"TM_But27": "બરાબર",
"TX_Sub5": "ભલામણ કરેલ યોજનાઓ",
"TM_Pag9": "જોબ કેટેગરીઝ",
"TM_Hep42": "તમારી પ્રોફાઇલના આધારે, અમે તમને કોઈપણ નોકરી માટે લાયક જણાયા નથી. અમે સતત વધુ નોકરીઓ ઉમેરી રહ્યા છીએ, કૃપા કરીને પછીથી તપાસો.",
"TM_Hep40":"અરજી કરવી" ,
"TM_Hep41":"કલાક",
"TM_Hep2": "શ્રેણી",
"TM_Alt31": "સેવા માં",
  "TM_Alt32" : "યોજનામાં",
  "TM_Alt33" : "જોબમાં",
  "TM_Pag14": "પ્રોફાઇલ બનાવો",
  "TM_Hep9": "પ્રોફાઇલ બનાવવાથી તમે બધી તકો સાચવી શકો છો જેમાં તમને રુચિ છે અને તમારા સાચવેલા ડેટા પર વધુ પ્રક્રિયા કરવા માટે કોઈપણ સમયે પાછળથી પાછા આવી શકો છો.",
  "TM_Txf1": "પૂરું નામ",
  "TM_Txf2": "સંબંધ",
  "TM_But29": "સાચવો",
  "TM_Err1": "સમાન નામ અને સંબંધની પ્રોફાઇલ પહેલેથી અસ્તિત્વમાં છે!",
  "TM_Err6": "માત્ર અક્ષરોને જ મંજૂરી છે",
  "TM_Pag16": "ભાષા બદલો",
  "TM_Pag33": "સ્થાન સુધારો",
  "TM_But22": " સુધારો ",
  "TM_But14": "પ્રોફાઇલ ઉમેરો",
  "TM_Pag29":"પ્રોફાઇલ બનાવટ",
  "TM_But26": "ઘર",
  "TM_Rec35": "સંસાધન ઉપલબ્ધ નથી",
  "TM_Rec36":"સર્વર સમય સમાપ્ત!",
  "TM_Rec37":"પરવાનગી અસ્વીકાર!",
  "TM_Mod28":"કંઈક ખોટું થયું.",
  "TM_Rec10": "અમે સતત સુધારી રહ્યા છીએ, કૃપા કરીને પછીથી ફરી પ્રયાસ કરો.",
  "TM_Txf15": "નામ ઉમેરો",
  "TM_But20":"સંપાદિત કરો",
  "TM_Hep16": "એપ્લિકેશનનો ઉપયોગ કરતી વખતે તમારા અનુભવને વધારવા અને તમારા માટે ચોક્કસ યોજનાઓ/સેવાઓ/નોકરીઓ દર્શાવવા માટે તમારું સાચું સ્થાન પસંદ કરો.",
  "TM_Pag26": "સ્થાનની પુષ્ટિ કરો",
  "TM_But23": "પુષ્ટિ કરો",
  "TX_Sub21":"સૂચનાઓ",
  "TX_Sub22":"પ્રોફાઇલ મેનેજ કરો",
  "TX_Sub23":"સ્થાન",
  "TX_Sub24":"ભાષા",
  "TX_Sub25":"વારંવાર પૂછાતા પ્રશ્નો",
  "TX_Sub26":"અમારા વિશે",
  "TX_Sub27":"એકાઉન્ટ કાઢી નાખો",
  "TX_Sub28":"અંગત વિગતો",
  "TX_Sub29":"આર્થિક વિગતો",
  "TX_Sub30":"હાઉસિંગ વિગતો",
  "TX_Sub31":"શિક્ષણ પ્રોફાઇલ",
  "TX_Sub32":"વ્યવસાય પ્રોફાઇલ",
  "TX_Sub33":"આરોગ્ય પ્રોફાઇલ",
  "TM_Txf13":"રાજ્ય પસંદ કરો",
  "TM_Txf14":"જિલ્લો પસંદ કરો",
  "TM_Con9":"Sorry! We couldn't find any matching schemes for you",
  "TM_Con10":"માફ કરશો! અમે તમારા માટે કોઈ મેળ ખાતી નોકરી શોધી શક્યા નથી",
  "TM_Hep43":"અગાઉના પ્રશ્ન માટે જમણે સ્વાઇપ કરો",
  "TM_Hep28":"તમારી પ્રોફાઇલ મુજબ સંબંધિત યોજનાઓ શોધવા માટે પ્રશ્નોના જવાબ આપો",
  "TM_Hep30":"તમારી પ્રોફાઇલ મુજબ સંબંધિત નોકરીઓ શોધવા માટે પ્રશ્નોના જવાબ આપો",
  "TM_Hep32": "તમે માટે પાત્ર હોઈ શકે છે",
  "TM_Hep35":"થોડા પ્રશ્નોના જવાબ આપો",
  "TM_But16":"આગળ",
  "TM_Rel1":"પુત્રી",
  "TM_Rel2":"પિતા",
  "TM_Rel3":"માતા",
  "TM_Rel4":"પુત્ર",
  "TM_Rel5":"ભાઈ",
  "TM_Rel6":"બહેન",
  "TM_Rel7":"જીવનસાથી",
  "TM_Rel8":"અન્ય કોઈ",
  "TM_Rel9": "સ્વ",
  "TM_Mod4": "ધ્યાન",
  "TM_Mod2": "સેવાઓ કાઢી નાખો",
  "TM_Rec12": "જો તમે ડેટા બદલો છો તો તે જ તમારા પ્રોફાઇલ ડેટામાં પણ અપડેટ થશે. શું તમે ચાલુ રાખવા માંગો છો?",
  "TM_Hep12": "તમારા અગાઉ સાચવેલા ડેટાની સમીક્ષા કરો અને પુષ્ટિ કરો.",
  "TM_But28": "ચાલુ રાખો",
  "TM_Pag24": "તમારી વિગતોની સમીક્ષા કરો",
  "TM_Pag25": "તમારી વિગતો અપડેટ કરો",
  "TM_Hep33":"તમે સારું કરી રહ્યા છો! ચાલુ રાખો...",
  "TM_Con15":"અમે વિગતો મેળવીએ ત્યાં સુધી કૃપા કરીને રાહ જુઓ",
  "TM_But9" : "વધુ સેવાઓનું અન્વેષણ કરો",
  "TM_Pag5":"યોજના વિગતો",
  "TM_Pag8":"સેવાની વિગતો",
  "TM_Hep3": "સેવા સ્તર",
  "TM_But6": "લિંક કૉપિ કરો",
  "TM_But19": "ડાઉનલોડ કરો",
  "TM_Con1": "લિંક કૉપિ કરી",
  "TM_Con5": "ડાઉનલોડ કરેલ",
  "TM_Alt14": "કેવી રીતે અરજી કરવી",
  "TM_Alt13": "દસ્તાવેજો",
  "TM_Alt15": "માહિતી",
  "TM_Con3": `આ યોજના તમારી "મારી યોજના" માં સાચવવામાં આવી છે.`,
  "TM_Con2": `આ સેવા તમારી "મારી સેવા" માં સાચવવામાં આવી છે`,
  "TM_Alt34":"અરજી કરવાની પ્રક્રિયા",
  "TM_Alt35":"એપ્લિકેશન લિંક",
  "TM_Alt36":"ફોર્મ ડાઉનલોડ કરો",
  "TM_Alt38":"પસંદગી પ્રક્રિયા",
  "TM_Alt41":"યોજનાના લાભો",
  "TM_Alt56":"પદ",
  "TM_Alt57":"પગાર",
  "TM_Alt58":"નોકરીની વિગતો",
  "TM_Alt59":"અરજી કરવાની છેલ્લી તારીખ",
  "TM_Txf12": "કારણ",
  "TM_Hep51": "યોગ્યતાના મૂલ્યો",
  "TM_Hep52": "આપના દ્વારા પુરા પાડવામાં આવેલા મૂલ્યો",
  "TM_Con7" : "માફ કરશો, તમે આ યોજના માટે પાત્ર નથી",
  "TM_Con8" : "માફ કરશો, તમે આ નોકરી માટે પાત્ર નથી",
  "TM_Err5" : "કોઈ વિકલ્પો બાકી નથી",
  "TM_Con13" : "તમારો ડેટા સફળતાપૂર્વક સાચવવામાં આવ્યો છે.",
  "TM_Hep8" : "કોઈ સાચવેલી નોકરીઓ નથી",
  "TM_Pag10": "મારી નોકરી",
  "TM_Mod3" : "જોબ કાઢી નાખો",
  "TM_Rec3" : "શું તમે ખરેખર આ નોકરીને તમારી 'મારી નોકરી'માંથી કાઢી નાખવા માંગો છો?",
  "TM_Hep24": "કોઈ ડ્રાફ્ટ જોબ્સ નથી",
  "TM_Hep25": "કોઈ લાગુ નોકરીઓ નથી",
  "TM_Hep26": "નોંધાયેલ નોકરીઓ નથી",
  "TM_Hep27": 'સમાપ્તિની તારીખથી 30 દિવસની અવધિ પછી તમારી "મારી નોકરીઓ" માંથી બધી સમાપ્ત થયેલ નોકરીઓ દૂર કરવામાં આવશે.',
  "TM_Tag3" : "સમાપ્ત",
  "TM_Tag4" : "દ્વારા અરજી કરો",
  "TM_Con4":  'આ નોકરી તમારી "મારી નોકરી" માં સાચવવામાં આવી છે',
  "TM_Rec5": "તમારો બધો ડેટા કાઢી નાખવામાં આવ્યો છે",
  "TM_Rec4": "તમારું એકાઉન્ટ ડિલીટ કરવા માટે તમારે પહેલા તમારા એકાઉન્ટ સાથે સંકળાયેલ તમામ પ્રોફાઇલ્સ ડિલીટ કરવી પડશે",
  "TM_Pag41": "અરજીની વિગતો",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "શું તમે ખરેખર આ દસ્તાવેજ દૂર કરવા માંગો છો?",
  "TM_Mod18": "ગેલેરી",
  "TM_Mod17": "કેમેરો",
  "91412465ea9169dfd901dd5e7c96dd99": "અપલોડ કરો",
  "af6ebe0c0e89201cb2294f32955f7b15": "એક સમયે માત્ર 20 દસ્તાવેજોને જ મંજૂરી છે.",
  "d5de543c0e83073f05a846d62cc83c81": "તે જ ક્ષેત્ર માટે દસ્તાવેજ પહેલેથી જ અપલોડ કરેલ છે.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "માન્ય દાખલ કરો",
  "3f68e67dc6c397aa9d1c24c356f754f": "ચકાસાયેલ",
  "1e410e1b9903e7a99b93857f536b7e1d": "ચકાસાયેલ નથી",
  "f8d855b26faffa0f8779fb44632d6bc1": "અમાન્ય કેપ્ચા કોડ",
  "d53b4d564b9054db9b4b4a50e713197f": "કૃપા કરીને અહીં કેપ્ચા દાખલ કરો",
  "TM_Con18": "તમારી અરજી તમારી 'ડ્રાફ્ટ સ્કીમ'માં સફળતાપૂર્વક સાચવવામાં આવી છે",
  "TM_Con24": "તમારી અરજી તમારા 'ડ્રાફ્ટ જોબ'માં સફળતાપૂર્વક સાચવવામાં આવી છે",
  "TM_Con23": `તમારી એપ્લિકેશન તમારી "ડ્રાફ્ટ સેવાઓ"માં સફળતાપૂર્વક સાચવવામાં આવી છે`,
  "6cf24d7a07132fe88b610e2b3d3def28": "સ્વયં ઘોષણા",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "આ ફીલ્ડ જરૂરી છે.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "કૃપા કરીને માત્ર મૂળાક્ષરો, સંખ્યાઓ, અલ્પવિરામ, -, _, / દાખલ કરો",
  "562d3d08919ae105c3ebc42d9607d266": "કૃપા કરીને માત્ર મૂળાક્ષરો અથવા સંખ્યાઓ દાખલ કરો",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "માત્ર મૂળાક્ષરોની મંજૂરી છે.",
  "4906d981bb592b32f6982fb4187c7b60": "માન્ય આઈએફએસસી કોડ દાખલ કરો.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "માત્ર 11 અક્ષરોની મંજૂરી છે.",
  "7c58cde3b827bc7d57cbf77865046169": "કૃપા કરીને માત્ર નંબરો જ દાખલ કરો",
  "544b0264d55a1e4cf476b279240d9be4": "માત્ર દસ અંકોની મંજૂરી છે. કૃપા કરીને 0 અથવા +91 ઉપસર્ગ ન લગાવો",
  "73f0d980e48b8141e0776e02d96a872f": "માત્ર બાર અંકોની મંજૂરી છે.",
  "0ce98bd973419ff33921a2275e7903b9": "કૃપા કરીને માન્ય પાન નંબર દાખલ કરો.",
  "e55e425dbaf6b6449940eac8ea749092": "કૃપા કરીને માત્ર મૂળાક્ષરો જ દાખલ કરો.",
  "64ff9e3809686d4ea3253a631cae4761": "કૃપા કરીને 2 દશાંશ સ્થાનો પર યોગ્ય મૂલ્ય દાખલ કરો.",
  "7ed0ef23d527c2876015161e422f49ce": "કૃપા કરીને તમારું નામ કન્નડ ભાષામાં દાખલ કરો.",
  "230486b5dd7afed96556ba9bd4175423": "પીન કોડ ખોટો છે",
  "9f44bd93c8988c682d5ef5369fd11f47": "કૃપા કરીને માન્ય ઇમેઇલ દાખલ કરો",
  "ac598199ea0dd028ade4677a435caf4b": "માન્ય આધાર નંબર દાખલ કરો",
  "10803b83a68db8f7e7a33e3b41e184d0": "જન્મ તારીખ",
  "d0de720ccdffea8490dfd2893c50ebc5": "તે {{displayName}} કરતાં મોટું હોવું જોઈએ",
  "ced6e171e13ebd5c37415c822706b9d4": "તે {{displayName}} કરતાં ઓછું હોવું જોઈએ",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "તે {{displayName}} ની બરાબર હોવી જોઈએ",
  "9483135517d156229d0439dff74b1ca0": "તે {{displayName}} કરતાં મોટું અથવા બરાબર હોવું જોઈએ",
  "01e9cd748e852b1ae389b980bff195b0": "તે {{displayName}} કરતાં ઓછું અથવા બરાબર હોવું જોઈએ",
  "TM_Pag37": "સમીક્ષા વિગતો",
  "TM_Rec18": "તમારી ભરેલી વિગતો બદલવા માટે “સંપાદિત કરો” પર ક્લિક કરો",
  "TM_Alt51": "જોડાણ(ઓ):",
  "TM_Alt42": "આભાર",
  "TM_But9":"વધુ સેવાઓનું અન્વેષણ કરો",
  "TM_Con17":"તમારી એપ્લિકેશન ડાઉનલોડ કરવામાં આવી છે.",
  "TM_Sub48": "મદદ અને આધાર",
  "TM_Alt66": "અમને <b>jioeasygov@easygov.co.in</b> પર એક ઈમેલ મોકલો અને અમારી ટીમ ટૂંક સમયમાં તમને પરત કરશે",
  "TM_Alt65": "કોઈપણ ક્વેરી માટે સહાયની જરૂર છે?",
  "TM_Pag54": "સેવાના લાભો",
  "TM_Alt37": "સરકારી ફી",
  "TM_But105" : "અને શોધો",
  "TM_But17": "સબમિટ કરો",
  "TM_Alt39": "સબમિશન સરનામું",
  "TM_But58": "કેવી રીતે અરજી કરવી",
  "TM_But100" : "પાછા જાવ",
  "TM_But95": "હવે પૈસા આપો",
  "TM_Alt88": "તમારી અરજી જમા કરવા માટે કૃપા કરીને નીચેની રકમ ચૂકવો",
  "TM_Con32": "તમારી અરજી સફળતાપૂર્વક जमा કરવામાં આવી છે",
  "TM_But110": "અરજી",
  "TM_Txf16": "અહીં શોધો",
  "TM_Hep69": "'સ્કેમ સપોર્ટ મેળવો' પર ક્લિક કરો અને એક નાનું સર્વેક્ષણ કરો, જે તમને યોગ્ય સમર્થન આપવામાં અમને મદદ કરશે.",
  "TM_But107": "ગેટ સ્કીમ સપોર્ટ શું છે?",
  "TM_But106": "સ્કીમ સપોર્ટ મેળવો",
  "TM_Pag32": "સ્કીમ અવેરનેસ સર્વે",
  "TM_Con14": "વિગતો શેર કરવા બદલ આભાર.",
  "TM_Tag5": "ખાલી જગ્યા",
  "TM_Alt107": "દિવસ",
  "TM_Rec11":"શું તમે ખરેખર પસંદ કરેલી નોટિફિકેશન કાઢી નાખવા માંગો છો?",
  "TM_Mod7":"નોટિફિકેશન ડિલીટ કરો",
  "TM_But25":"બધા પસંદ કરો",
  "TM_Mod1": "સ્કીમ કાઢી નાખો",
  "TM_But24":"प्रोफाइल तयार करणे",
  "3f68e67dc6c397aaa9d1c24c356f754f":"ચકાસણી",
  "TM_Rec50": "શું તમે ખરેખર તમારી અરજી પાછી ખેંચવા માંગો છો ?",
  "TM_But23": "પુષ્ટિ કરો",
  "TM_But12": "રદ કરો",
  "TM_Con6" : "તમારા રજિસ્ટર્ડ મોબાઈલ નંબર (xxxxxx{{phoneNumber}}) પર એક OTP મોકલવામાં આવ્યો છે.",
  "TM_But17": "સબમિટ કરો",
  "TM_But61": "OTP ફરીથી મોકલો",
  "TM_Con20": "તમારી અરજી સફળતાપૂર્વક પાછી ખેંચી લેવામાં આવી છે",
  "TM_But38": "પૂર્ણ ક્રિયાઓ",
  "TM_But62": "અભીપ્રાય આપો",
  "TM_Lab11": "અરજી પાછી ખેંચો",
  "TM_Err3" : "અમાન્ય OTP",
  "TM_Err13": "OTP સમાપ્ત",
  "TM_Err14": "તમે OTP જનરેટ કરવાની મહત્તમ મર્યાદાને વટાવી દીધી છે.  કૃપા કરીને 3 કલાક પછી ફરી પ્રયાસ કરો.",
  "TM_But40": "ક્રિયાઓ",
  "TM_Tag9": "કાર્યવાહી કરવી જરૂરી",
  "TM_Tag10": "કોઈ કાર્યવાહીની જરૂર નથી",
  "TM_Tag8" : "સ્થિતિ",
  "TM_But76" : "જુઓ",
  "TM_Alt87" : "ઓનલાઈન ઉપલબ્ધ નથી",
  "TM_Pag34": "ચુકવણીની વિગતો",
  "TM_Hea1": "કુલ રકમ",
  "TM_Hea2": "સારાંશ",
  "TM_Sub56": "સારાંશ",
  "TM_But42": "ચૂકવણી કરો",
  "TM_Lab18": "રસીદ જુઓ",
  "TM_Err34": "ચુકવણી નિષ્ફળતાને કારણે તમારી અરજી સબમિટ કરી શકાઈ નથી.",
  "TM_Rec55": "તમારી ચુકવણીની સ્થિતિની પુષ્ટિ કરવામાં સમય લાગી રહ્યો છે. અમે તમારી બેંક સાથે સતત તપાસ કરી રહ્યા છીએ. કૃપા કરીને આગામી 48 કલાક માટે કોઈપણ વધારાની ચૂકવણી કરવાનું ટાળો.",
  "TM_But13": "પાછા જાવ",
  "TM_Alt124":"મહેરબાની કરી રાહ જુવો",
  "TM_Rec58": "હોલ્ડ કરો. અમે તમારી ચુકવણીની સ્થિતિ ચકાસી રહ્યા છીએ",
  "TM_Rec59": "આ સેવા માટેની તમારી છેલ્લી ચુકવણી બાકી સ્થિતિમાં છે. જ્યારે તે પૂર્ણ થશે ત્યારે અમે તમને સૂચિત કરીશું. તે દરમિયાન, અમારી અન્ય સેવાઓનું અન્વેષણ કરવા માટે નિઃસંકોચ રહો.ધૈર્ય રાખવા બદલ આભાર.",
  "TM_But133": "લિંક પર ક્લિક કરો"

}

export default language
