import axios from "axios";
import CryptoJS from "crypto-js";
import { saveJwt } from './saveJwtAction';
import browserStorageKeys from "../../common/browserStorageKeys";
import browserStorage from '../../utility/browserStorage'
import {HandleApiError} from "../../utility/handleApiError"
import { responseTime } from "../../utility/responseTime";
const getTranslatedValue = (translatedText, hash) => {
  let translatedObj = translatedText[hash];
  if (translatedObj) {
    return translatedText[hash];
  } else {
    return null;
  }
};

export const getUserProfile = (loader = true) => {
  let authToken = "";
  if (browserStorage.getItemValue(browserStorageKeys.AUTHORIZATION_TOKEN)) {
    authToken = browserStorage.getItemValue(browserStorageKeys.AUTHORIZATION_TOKEN);
  }

  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: authToken,
    },
  });

  return (dispatch) => {
    const startTime = performance.now();
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    let geographyObj = JSON.parse(
      browserStorage.getItemValue(browserStorageKeys.SELECTED_GEOGRAPHY)
    );
    let requestObj = {};
    requestObj["stateId"] = geographyObj.stateId;
    if (browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)) {
      requestObj["locale"] = JSON.parse(
        browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
      ).code;
    }
    instance
      .post("getUserProfileBySource/", requestObj)
      .then((response) => {
        responseTime(performance.now(),startTime)
        if (response.status === 200) {
          let translatedText = response.data.translation;
          if (
            JSON.parse(browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)).code !== "en"
          ) {
            if (response.data.sources && response.data.sources.length > 0) {
              response.data.sources.map((source) => {

                if (getTranslatedValue( translatedText, CryptoJS.MD5(source.name.replace(/\s/g, "")).toString(CryptoJS.enc.Hex))) {
                  source["untranslated_name"] = source.name;
                  source.name = getTranslatedValue(
                    translatedText,
                    CryptoJS.MD5(source.name.replace(/\s/g, "")).toString(
                      CryptoJS.enc.Hex
                    )
                  );
                }

                source.groups.map((group) => {
                  if (getTranslatedValue( translatedText, CryptoJS.MD5(group.name.replace(/\s/g, "")).toString(CryptoJS.enc.Hex))) {
                    group["untranslated_name"] = group.name;
                    group.name = getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(group.name.replace(/\s/g, "")).toString(
                        CryptoJS.enc.Hex
                      )
                    );
                  }

                  group.userProfiles.map((profile) => {
                    if ( profile.fieldDisplayName && getTranslatedValue( translatedText, CryptoJS.MD5(profile.fieldDisplayName.replace(/\s/g, "")).toString(CryptoJS.enc.Hex))) {
                      profile["untranslated_fieldDisplayName"] = profile.fieldDisplayName;
                      profile.fieldDisplayName = getTranslatedValue( translatedText, CryptoJS.MD5(profile.fieldDisplayName.replace(/\s/g, "")).toString(CryptoJS.enc.Hex));
                    }
                    if(profile.fieldValue && profile.fieldType === "multiselect"){
                      let temp = profile.fieldValue.split(",");
                      let fieldValue = "";
                      temp.map(item =>{
                        fieldValue = fieldValue + getTranslatedValue( translatedText, CryptoJS.MD5(item.replace(/\s/g, "")).toString(CryptoJS.enc.Hex)) + ",";
                      })
                      profile["untranslated_fieldValue"] = profile.fieldValue;
                      profile.fieldValue = fieldValue.substring(0,fieldValue.length-1);
                    }
                    if ( profile.fieldValue && profile.fieldType !=="multiselect" && getTranslatedValue( translatedText, CryptoJS.MD5(profile.fieldValue.replace(/\s/g, "")).toString(CryptoJS.enc.Hex))) {
                      profile["untranslated_fieldValue"] = profile.fieldValue;
                      profile.fieldValue = getTranslatedValue( translatedText, CryptoJS.MD5(profile.fieldValue.replace(/\s/g, "")).toString(CryptoJS.enc.Hex));
                    }
                    if (profile.fieldValueOptions) {profile.fieldValueOptions.map((field) => {
                        if (getTranslatedValue( translatedText, CryptoJS.MD5(field.value.replace(/\s/g, "")).toString(CryptoJS.enc.Hex))) {
                          field["untranslated_value"] = field.value;
                          field.value = getTranslatedValue(translatedText, CryptoJS.MD5(field.value.replace(/\s/g, "")).toString(CryptoJS.enc.Hex));
                        }
                      });
                    }
                  });
                });
              });
            }
          }
          dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
          dispatch({
            type: "GET_USER_PROFILE",
            payload: response.data.sources,
          });
          }
          
        }
      )
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
        console.log(err);
        HandleApiError(err)
      });
  };
};
