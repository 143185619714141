var language = {
	"TM_Txf6" : "कृपया तुमची पसंतीची भाषा निवडा",
	"TM_But15":"पुढे जा",
	"TM_Pag17": "स्थान बदला",
  "TM_Pag13": "नोकरीचे फायदे",
	"TM_Pag19" : "भाषा निवडा",
	"TM_But1":"चला सुरू करुया",
	"TM_Pag20":"स्थान निवडा",
	"TM_Txf9":"तुमचे स्थान प्रदान केल्याने आम्हाला तुमच्या क्षेत्रातील तुम्ही पात्र असलेले फायदे दर्शविण्यात मदत होईल.",
	"TM_Txf3": "शोध स्थिती",
	"TM_Txf4": "शोध जिल्हा",
	"TM_But16": "पुढे",
  "TM_But37":"अर्ज पहा",
  "TM_Tag8":"स्थिती",
  "TM_Pag35": "अर्जाची स्थिती",
	"TM_Alt1": "3000+ कल्याणकारी योजनांसाठी पात्रता तपासा",
	"TM_Alt3": "तुमच्या जवळील नोकरीची संधी शोधा",
	"TM_Alt2": "तुम्ही अर्ज करू इच्छित असलेल्या सेवा तपासा",
	"TM_Lin1": "अॅपमध्ये प्रवेश करून, तुम्ही EasyGov’s सहमती दर्शवता",
	"TM_Alt5": "तुम्ही पात्र असू शकतील असे कल्याणकारी फायदे शोधण्यात आम्हाला मदत करण्यासाठी प्रोफाइल तयार करा",
	"TM_But2": "तुमचे प्रोफाइल तयार करा",
	"TX_Sub1": "योजना श्रेणी",
	"TX_Sub2": "फ्लॅगशिप योजना",
	"TX_Sub3": "नव्याने जोडलेल्या योजना",
	"TX_Sub4": "लोकप्रिय योजना",
	"TM_Pag1": "योजना श्रेणी",
	"TM_Alt6": "योजना",
	"TM_Alt7": "सेवा",
	"TM_Alt8": "नोकऱ्या",
	"TM_Alt9": "शोधा",
	"TM_Alt10": "प्रोफाइल",
	"TX_Sub7": "सेवा श्रेणी",
	"TX_Sub8": "शीर्ष रेट सेवा",
	"TX_Sub9": "नव्याने जोडलेल्या सेवा",
	"TX_Sub10": "लोकप्रिय सेवा",
  "TX_Sub13":  "माझ्या सेवा",
  "TM_Pag6":  "माझ्या सेवा",
	"TM_Pag18": "सेवा श्रेणी",
	"TM_Alt16": "तुमच्यासाठी सर्वात योग्य नोकर्‍या शोधण्यात आम्हाला मदत करण्यासाठी प्रोफाइल तयार करा",
	"TM_Alt17": "नोकरीचे तपशील",
  "TM_Alt18" : "कामाचे स्वरूप",
  "TM_Alt19":"इंग्रजी", 
  "TM_Alt20":"हिंदी",
  "TM_Alt21":"तमिळ",
  "TM_Alt22":"कन्नड",
  "TM_Alt23":"पंजाबी",
  "TM_Alt24":"मल्याळम",
  "TM_Alt25":"बंगाली",
  "TM_Alt26":"गुजराती",
  "TM_Alt27":"तेलुगु",
  "TM_Alt28":"मराठी",
  "TM_But3":"सर्व पहा",
"TM_But5":"पात्रता तपासा",
"TM_But10":"अधिक नोकऱ्या एक्सप्लोर करा",
"TX_Sub15":"टॉप रेट केलेल्या नोकऱ्या",
"TX_Sub16":"नव्याने जोडलेल्या नोकऱ्या",
"TX_Sub17":"लोकप्रिय नोकर्‍या",
"TX_Sub18":"शिफारस केलेल्या नोकऱ्या",
"TX_Sub20":"माझी नोकरी",
"TM_alt11":"फायदे",
"TM_Pag4":"योजनेचे तपशील",
"TM_Pag21": "योजना",
"TM_Pag22": "सेवा",
"TM_Pag23": "नोकऱ्या",
"TM_Alt30" : "योजना, सेवा आणि नोकऱ्या शोधा",
"TM_Lab6": "अलीकडे शोधले",
"TM_Err4": "कोणतेही परिणाम आढळले नाहीत",
"TM_Err12": "ही सेवा तुमच्या ठिकाणी उपलब्ध नाही.",
"TM_Mod25":"भाषा बदला",
"TM_Rec33":"ही सेवा फक्त इंग्रजी भाषेत उपलब्ध आहे. ऐप ची भाषा बदलण्यासाठी पुढे जा वर क्लिक करा",
"TM_Pag12":"नोकरीचे तपशील",
"TM_Alt12":"पात्रता",
"TM_Tag1":"आत्ताच लाँच केले",
"TX_Sub25" : "वारंवार विचारले जाणारे प्रश्न",
"TM_Sup1": "आम्हाला फॉलो करा",
"TM_Hep17":"आपल्याकडे काही क्वेरी असल्यास आम्हाला कळवा आणि आम्ही ४८ व्यावसायिक तासांच्या आत आपल्याकडे परत येऊ.",
"TM_Lab4":"ईमेल",
"TM_Lin2":"अटी व शर्ती",
"TM_Lin3":"गोपनीयता धोरण",
"TM_But4":"वापरकर्ता बदला",
"TM_Pag15": "वापरकर्ता बदला",
"TM_Hep36":"पात्रता पाहण्यासाठी किंवा तुमच्या सेव्ह केलेल्या डेटावर पुढील प्रक्रिया करण्यासाठी तुम्ही तुमचे इच्छित प्रोफाइल निवडू शकता किंवा जोडू शकता.",
"TM_But18":"वापरकर्ता जोडा",
"TM_Mod5": "तुम्हाला खात्री आहे की तुम्ही {{Name}} खाते कायमचे हटवू इच्छिता?",
"TM_Rec7":"जेव्हा तुम्ही तुमचे EasyGov खाते हटवता, तेव्हा तुम्ही तुमच्या प्रोफाइलमध्ये आणि तुमच्या खात्याशी संबंधित इतर प्रोफाइलमध्ये संग्रहित केलेला कोणताही डेटा किंवा माहिती पुनर्प्राप्त करू शकणार नाही. तुमच्या सर्व शिफारस केलेल्या आणि जतन केलेल्या योजना देखील हटवल्या जातील.",
"TM_Err2":"तुम्ही {{Name}} प्रोफाईल हटवू शकत नाही कारण त्यात चालू असलेले अॅप्लिकेशन(ले).elete आहे",
"TM_But11":"हटवा",
"TM_But12": "रद्द करा",
"TM_But30" : "सर्व नोकऱ्या पहा",
"TM_But31" : "सर्व योजना पहा",
"TM_Con11": "लोड करत आहे",
"TM_The1":"साठवा",
"TM_The2":"ड्राफ्ट",
"TM_The3":"अर्ज केला आहे",
"TM_The4":"नावनोंदणी केली",
"TM_The5":"प्रक्रिया केली",
"TM_Hep7":"जतन केलेल्या योजना नाहीत", 	
"TM_Hep21":"मसुदा योजना नाहीत",	
"TM_Hep22":"कोणत्याही लागू योजना नाहीत",
"TM_Hep23":"नोंदणीकृत योजना नाहीत",	
"TM_But7":"अधिक योजना एक्सप्लोर करा",
"TM_Rec1": "तुम्‍हाला खात्री आहे की तुम्‍हाला तुमच्‍या 'माझ्या योजना' मधून ही योजना हटवायची आहे?",
"TM_Rec2":"तुमची खात्री आहे की तुम्ही ही सेवा तुमच्या ‘माय सर्व्हिसेस’ मधून हटवू इच्छिता?",
"TM_Pag2":"माझ्या योजना",
"TM_Hep10": "तुम्ही प्रोफाइलमधून तुमची पसंतीची भाषा आणि स्थान बदलू शकता",
"TM_But21": "समजले!",
"TM_Alt29":"भाषा आणि स्थान",
"TM_Hep38":"{{category}} श्रेणीतील योग्य योजना ओळखण्यात आम्हाला मदत करण्यासाठी, कृपया {{category}} शी संबंधित काही अतिरिक्त प्रश्नांची उत्तरे द्या",
"TM_Hep11":"तुमच्या प्रोफाइलच्या आधारावर, आम्हाला तुम्ही कोणत्याही योजनेसाठी पात्र असल्याचे आढळले नाही. आम्ही सतत आणखी योजना जोडत आहोत, कृपया नंतर तपासा.",
"TM_Hep39":"{{category}} श्रेणीतील योग्य नोकऱ्या ओळखण्यात आम्हाला मदत करण्यासाठी, कृपया {{category}} शी संबंधित काही अतिरिक्त प्रश्नांची उत्तरे द्या",
"TM_Rec6": "याक्षणी तुमच्या राज्यात कोणतीही नोकरी कॉन्फिगर केलेली नाही, आम्ही सतत अपडेट करत आहोत, कृपया आमच्याशी पुन्हा तपासा",
"TM_But27": "ठीक आहे",
"TX_Sub5": "शिफारस केलेल्या योजना",
"TM_Pag9": "नोकरीच्या श्रेणी",
"TM_Hep42": "तुमच्या प्रोफाइलच्या आधारे, आम्हाला तुम्ही कोणत्याही नोकरीसाठी पात्र आढळले नाही. आम्ही सतत अधिक नोकर्‍या जोडत आहोत, कृपया नंतर तपासा.",
"TM_Hep40":"लागू" ,
"TM_Hep41":"तास",
"TM_Hep2": "श्रेणी",
"TM_Alt31": "च्या नोकरीत",
  "TM_Alt32" : "योजनेत",
  "TM_Alt33" : "नोकरी मध्ये",
  "TM_Pag14": "प्रोफाइल तयार करा",
  "TM_Hep6":"जतन केलेल्या सेवा नाहीत",
  "TM_Hep13":"मसुदा सेवा नाहीत",
  "TM_Hep14":"कोणतीही लागू सेवा नाहीत.",
  "TM_Hep15":"कोणत्याही सूचना नाहीत",
  "TM_Hep44":"प्रक्रिया केलेल्या सेवा नाहीत",
  "TM_Hep54":"आज",
  "TM_Hep55":"काल",
  "TM_Hep9": "प्रोफाइल तयार केल्याने तुम्हाला स्वारस्य असलेल्या सर्व संधी जतन करण्याची आणि तुमच्या सेव्ह केलेल्या डेटावर पुढील प्रक्रिया करण्यासाठी नंतर कधीही परत येण्याची परवानगी मिळते.",
  "TM_Txf1": "पूर्ण नाव",
  "TM_Txf2": "संबंध",
  "TM_But29": "जतन करा",
  "TM_Err1": "समान नाव आणि नातेसंबंध असलेली प्रोफाइल आधीपासूनच अस्तित्वात आहे!",
  "TM_Err6": "फक्त वर्णांना परवानगी आहे",
  "TM_Pag16": "भाषा बदला",
  "TM_Pag33": "स्थान अद्ययावत करा",
  "TM_But22": " अद्ययावत करा ",
  "TM_But14": "प्रोफाइल जोडा",
  "TM_Txf15": "नाव जोडा",
    "TM_Con9":"क्षमस्व! आम्हाला तुमच्यासाठी कोणत्याही जुळणार्‍या योजना सापडल्या नाहीत",
  "TM_Con10":"क्षमस्व! आम्हाला तुमच्यासाठी कोणत्याही जुळणाऱ्या नोकऱ्या सापडल्या नाहीत",
  "TM_But26": "मुख्यपृष्ठ",
  "TM_Rec35": "संसाधन उपलब्ध नाही",
  "TM_Rec36":"सर्व्हर टाइम आउट!",
  "TM_Rec37":"प्रवेश नाकारला!",
  "TM_Mod28":"काहीतरी चूक झाली.",
  "TM_Rec10": "आम्ही सतत सुधारणा करत आहोत, कृपया नंतर पुन्हा प्रयत्न करा.",
  "TM_But24":"पुन्हा प्रयत्न करा",
  "TM_But20":"सुधारणे",
  "TM_Hep16":"ऐप वापरताना तुमचा अनुभव वाढवण्यासाठी तुमचे योग्य स्थान निवडा आणि तुमच्यासाठी अचूक योजना/सेवा/नोकरी दाखवा.",
  "TM_Pag26": "स्थानाची पुष्टी करा",
  "TM_But23": "पुष्टी करा",
  "TX_Sub21":"अधिसूचना",
  "TX_Sub22":"प्रोफाइल व्यवस्थापित करा",
  "TX_Sub23":"स्थान",
  "TX_Sub24":"भाषा",
  "TX_Sub25":"वारंवार विचारले जाणारे प्रश्न",
  "TX_Sub26":"आमच्याबद्दल",
  "TX_Sub27":"खाते हटवा",
  "TX_Sub28":"वैयक्तिक माहिती",
  "TX_Sub29":"आर्थिक तपशील",
  "TX_Sub30":"गृहनिर्माण तपशील",
  "TX_Sub31":"शैक्षणिक प्रोफाइल",
  "TX_Sub32":"व्यवसाय प्रोफाइल",
  "TX_Sub33":"आरोग्य प्रोफाइल",
  "TM_Txf13":"राज्य निवडा",
  "TM_Txf14":"जिल्हा निवडा",
  "TM_Hep43":"मागील प्रश्नासाठी उजवीकडे स्वाइप करा",
  "TM_Hep28":"तुमच्या प्रोफाइलनुसार संबंधित योजना शोधण्यासाठी प्रश्नांची उत्तरे द्या",
  "TM_Hep30":"तुमच्या प्रोफाइलनुसार संबंधित नोकऱ्या शोधण्यासाठी प्रश्नांची उत्तरे द्या",
  "TM_Hep32": "तुम्ही यासाठी पात्र असाल",
  "TM_Hep35":"काही प्रश्नांची उत्तरे द्या",
  "TM_Rel1":"मुलगी",
  "TM_Rel2":"वडील",
  "TM_Rel3":"आई",
  "TM_Rel4":"मुलगा",
  "TM_Rel5":"भाऊ",
  "TM_Rel6":"बहीण",
  "TM_Rel7":"जोडीदार",
  "TM_Rel8":"दुसरा कोणी",
  "TM_Rel9": "स्वतः",
  "TM_Mod4": "लक्ष द्या",
  "TM_Mod2": "सेवा हटवा",
  "TM_Rec12": "तुम्ही डेटा बदलल्यास तो तुमच्या प्रोफाइल डेटामध्ये देखील अपडेट केला जाईल. तुम्हाला सुरू ठेवायचे आहे का?",
  "TM_Hep12": "तुमच्या पूर्वी जतन केलेल्या डेटाचे पुनरावलोकन करा आणि पुष्टी करा.",
  "TM_But28": "सुरू",
  "TM_Pag24": "तुमच्या तपशीलांचे पुनरावलोकन करा",
  "TM_Pag25": "तुमचे तपशील अपडेट करा",
  "TM_Hep33":"तुम्ही चांगले करत आहात! चालू ठेवा...",
  "TM_Con15":"कृपया आम्ही तपशील आणत असताना प्रतीक्षा करा",
  "TM_But9" : "अधिक सेवा एक्सप्लोर करा",
  "TM_Pag5":"योजना तपशील",
  "TM_Pag8":"सेवा तपशील",
  "TM_Hep3": "सेवा पातळी",
  "TM_But6": "लिंक कॉपी करा",
  "TM_But19": "डाउनलोड करा",
  "TM_Con1": "लिंक कॉपी केली",
  "TM_Con5": "डाउनलोड केले",
  "TM_Alt14": "अर्ज कसा करावा",
  "TM_Alt13": "कागदपत्रे",
  "TM_Alt15": "माहिती",
  "TM_Con3":  `ही योजना तुमच्या “माय स्कीम” मध्ये सेव्ह केली आहे.`,
  "TM_Con2": `ही सेवा तुमच्या "माझी सेवा" मध्ये जतन केली गेली आहे`,
  "TM_Alt34":"अर्ज करण्याची प्रक्रिया",
  "TM_Alt35":"अर्जाची लिंक",
  "TM_Alt36":"फॉर्म डाउनलोड करा",
  "TM_Alt38":"निवड प्रक्रिया",
  "TM_Alt41":"योजनेचे फायदे",
  "TM_Alt56":"पद",
  "TM_Alt57":"पगार",
  "TM_Alt58":"नोकरी तपशील",
  "TM_Alt59":"अर्ज करण्याची शेवटची तारीख",
  "TM_Txf12": "कारण",
  "TM_Hep51": "पात्र मूल्ये",
  "TM_Hep52": "तुम्ही पुरवलेली मूल्ये",
  "TM_Con7" : "क्षमस्व, तुम्ही या योजनेसाठी पात्र नाही",
  "TM_Con8" : "क्षमस्व, तुम्ही या नोकरीसाठी पात्र नाही",
  "TM_Err5" : "कोणतेही पर्याय शिल्लक नाहीत",
  "TM_Con13" : "तुमचा डेटा यशस्वीरित्या सेव्ह झाला आहे.",

  "TM_Hep8" : "जतन केलेल्या नोकऱ्या नाहीत",
  "TM_Pag10": "माझी नोकरी",
  "TM_Mod3" : "नोकरी हटवा",
  "TM_Rec3" : "तुमची खात्री आहे की तुम्ही तुमच्या ‘माय जॉब्स’ मधून ही नोकरी हटवू इच्छिता?",
  "TM_Hep24": "ड्राफ्ट नोकऱ्या नाहीत",
  "TM_Hep25": "लागू केलेल्या नोकऱ्या नाहीत",
  "TM_Hep26": "नोंदणीकृत नोकऱ्या नाहीत",
  "TM_Hep27": 'कालबाह्य झालेल्या सर्व नोकर्‍या कालबाह्य तारखेपासून 30 दिवसांच्या कालावधीनंतर तुमच्या "माय जॉब्स" मधून काढून टाकल्या जातील.',
  "TM_Tag3" : "कालबाह्य",
  "TM_Tag4" : "द्वारे अर्ज करा",
  "TM_Con4":  'ही नोकरी तुमच्या "माय जॉब" मध्ये जतन केली गेली आहे',
  "TM_Rec5": "तुमचा सर्व डेटा हटवला गेला आहे",
  "TM_Rec4": "तुमचे खाते हटवण्यासाठी तुम्ही प्रथम तुमच्या खात्याशी संबंधित सर्व प्रोफाइल हटवणे आवश्यक आहे",
  "TM_Pag41": "अर्ज तपशील",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "तुम्हाला खात्री आहे की तुम्ही हा दस्तऐवज काढू इच्छिता?",
  "TM_Mod18": "गॅलरी",
  "TM_Mod17": "कॅमेरा",
  "91412465ea9169dfd901dd5e7c96dd99": "अपलोड करा",
  "af6ebe0c0e89201cb2294f32955f7b15": "एकावेळी फक्त 20 कागदपत्रांना परवानगी आहे.",
  "d5de543c0e83073f05a846d62cc83c81": "त्याच फील्डसाठी दस्तऐवज आधीच अपलोड केला आहे.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "वैध प्रविष्ट करा",
  "3f68e67dc6c397aa9d1c24c356f754f": "सत्यापित",
  "1e410e1b9903e7a99b93857f536b7e1d": "सत्यापित नाही",
  "f8d855b26faffa0f8779fb44632d6bc1": "अवैध कॅप्चा कोड",
  "d53b4d564b9054db9b4b4a50e713197f": "कृपया येथे कॅप्चा प्रविष्ट करा",
  "TM_Con18": "तुमचा अर्ज तुमच्या 'ड्राफ्ट स्कीम'मध्ये यशस्वीरित्या सेव्ह झाला आहे",
  "TM_Con24": "तुमचा अर्ज तुमच्या 'ड्राफ्ट जॉब'मध्ये यशस्वीरित्या सेव्ह झाला आहे",
  "TM_Con23": `तुमचा अर्ज तुमच्या "ड्राफ्ट सर्व्हिसेस" मध्ये यशस्वीरित्या सेव्ह झाला आहे`,
  "6cf24d7a07132fe88b610e2b3d3def28": "स्वत: घोषणा",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "हे फील्ड आवश्यक आहे.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "कृपया फक्त अक्षरे, संख्या, स्वल्पविराम, -, _, / प्रविष्ट करा",
  "562d3d08919ae105c3ebc42d9607d266": "कृपया फक्त अक्षरे किंवा संख्या प्रविष्ट करा",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "फक्त अक्षरांना परवानगी आहे.",
  "4906d981bb592b32f6982fb4187c7b60": "एक वैध आयएफएससी कोड प्रविष्ट करा.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "फक्त 11 वर्णांना परवानगी आहे.",
  "7c58cde3b827bc7d57cbf77865046169": "कृपया फक्त संख्या प्रविष्ट करा",
  "544b0264d55a1e4cf476b279240d9be4": "फक्त दहा अंकांना परवानगी आहे. कृपया 0 किंवा +91 उपसर्ग लावू नका",
  "73f0d980e48b8141e0776e02d96a872f": "फक्त बारा अंकांना परवानगी आहे.",
  "0ce98bd973419ff33921a2275e7903b9": "कृपया वैध पॅन क्रमांक प्रविष्ट करा.",
  "e55e425dbaf6b6449940eac8ea749092": "कृपया फक्त अक्षरे प्रविष्ट करा.",
  "64ff9e3809686d4ea3253a631cae4761": "कृपया 2 दशांश ठिकाणी योग्य मूल्य प्रविष्ट करा.",
  "64ff9e3809686d4ea3253a631cae4761": "कृपया 2 दशांश ठिकाणी योग्य मूल्य प्रविष्ट करा.",
  "230486b5dd7afed96556ba9bd4175423": "पिन कोड चुकीचा आहे",
  "9f44bd93c8988c682d5ef5369fd11f47": "कृपया वैध ईमेल प्रविष्ट करा",
  "ac598199ea0dd028ade4677a435caf4b": "एक वैध आधार क्रमांक प्रविष्ट करा",
  "10803b83a68db8f7e7a33e3b41e184d0": "जन्म दिनांक",
  "d0de720ccdffea8490dfd2893c50ebc5": "ते {{displayName}} पेक्षा मोठे असावे",
  "ced6e171e13ebd5c37415c822706b9d4": "ते {{displayName}} पेक्षा कमी असावे",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "ते {{displayName}} च्या बरोबरीचे असावे",
  "9483135517d156229d0439dff74b1ca0": "ते {{displayName}} पेक्षा मोठे किंवा समान असावे",
  "01e9cd748e852b1ae389b980bff195b0": "ते {{displayName}} पेक्षा कमी किंवा समान असावे",
  "TM_Pag37": "तपशीलांचे पुनरावलोकन करा",
  "TM_Rec18": "तुमचे भरलेले तपशील बदलण्यासाठी 'संपादित करा' वर क्लिक करा.",
  "TM_Alt51": "संलग्नक(चे):",
  "TM_Alt42": "धन्यवाद",
  "TM_But9":"अधिक सेवा एक्सप्लोर करा",
  "TM_Con17":"तुमचा अर्ज डाउनलोड झाला आहे.",
  "TM_Sub48": "मदत आणि आधार",
  "TM_Alt66": "आम्हाला <b>jioeasygov@easygov.co.in</b> वर ईमेल पाठवा आणि आमची टीम लवकरच तुमच्याकडे परत येईल",
  "TM_Alt65": "कोणत्याही प्रश्नासाठी मदत हवी आहे?",
  "TM_Pag54": "सेवा के लाभ",
  "TM_Alt37": "सरकारी शुल्क",
  "TM_But105" : "आणि शोधा",
  "TM_But17": "प्रस्तुत करणे",
  "TM_Alt39": "सबमिशन पत्ता",
  "TM_But58": "आवेदन करण्यासाठी पुढे जा",
  "TM_But100" : "परत जा",
  "TM_But95": "आता द्या",
  "TM_Alt88": "तुमचा अर्ज जमा करण्यासाठी कृपया खालील रक्कम भरा",
  "TM_Con32": "तुमचा अर्ज यशस्वीरित्या जमा केला गेला आहे",
  "TM_But110": "अर्ज",
  "TM_Txf16": "येथे शोधा",
  "TM_Hep69": "'योजना समर्थन मिळवा' वर क्लिक करा आणि एक लहान सर्वेक्षण करा, जे आम्हाला तुम्हाला योग्य समर्थन प्रदान करण्यात मदत करेल.",
  "TM_But107": "योजनेचे समर्थन मिळवा म्हणजे काय?",
  "TM_But106": "योजनेचे समर्थन मिळवा",
  "TM_Pag32": "योजना जागरूकता सर्वेक्षण",
  "TM_Con14": "तपशील सामायिक केल्याबद्दल धन्यवाद",
  "TM_Tag5": "रिक्त पद",
  "TM_Alt107": "दिवस",
  "TM_Rec11":"तुमची खात्री आहे की तुम्ही निवडलेल्या नोटिफिकेशन हटवू इच्छिता?",
  "TM_Mod7":"नोटिफिकेशन हटवा",
  "TM_But25":"सर्व निवडा",
  "TM_Mod1": "योजना हटवा",
  "TM_Pag29": "प्रोफाइल तयार करणे",
  "3f68e67dc6c397aaa9d1c24c356f754f":"सत्यापित",
  "TM_Rec50": "तुमची खात्री आहे की तुम्ही तुमचा अर्ज मागे घेऊ इच्छिता ?",
  "TM_But23": "पुष्टी",
  "TM_But12": "रद्द करा",
  "TM_Con6" : "तुमच्या नोंदणीकृत मोबाईल क्रमांकावर (xxxxxx{{phoneNumber}}) एक OTP पाठवण्यात आला आहे.",
  "TM_But17": "प्रस्तुत करणे",
  "TM_But61": "OTP पुन्हा पाठवा",
  "TM_Con20": "तुमचा अर्ज यशस्वीरित्या मागे घेण्यात आला आहे",
  "TM_But38": "पूर्ण क्रिया",
  "TM_But62": "अभिप्राय द्या",
  "TM_Lab11": "अर्ज मागे घ्या",
  "TM_Err3" : "अवैध OTP",
  "TM_Err13": "OTP कालबाह्य झाला",
  "TM_Err14": "तुम्ही OTP जनरेट करण्याची कमाल मर्यादा ओलांडली आहे.  कृपया 3 तासांनंतर पुन्हा प्रयत्न करा.",
  "TM_But40": "क्रिया",
  "TM_Tag9": "कृति करणे आवश्यक आहे",
  "TM_Tag10": "कोणत्याही कृति ची गरज नाही",
  "TM_Tag8" : "स्थिती",
  "TM_But76" : "पहा",
  "TM_Alt87" : "ऑनलाइन उपलब्ध नाही",
  "TM_Pag34": "देयक तपशील",
  "TM_Hea1": "एकूण रक्कम",
  "TM_Hea2": "सारांश",
  "TM_Sub56": "एकूण",
  "TM_But42": "भुगतान करा",
  "TM_Lab18": "पावती पहा",
  "TM_Err34": "पेमेंट अयशस्वी झाल्यामुळे तुमचा अर्ज सबमिट करता आला नाही.",
  "TM_Rec55": "तुमच्या पेमेंटच्या स्थितीची पुष्टी करण्यासाठी वेळ लागत आहे. आम्ही तुमच्या बँकेकडे सतत तपासत आहोत. कृपया पुढील 48 तासांसाठी कोणतेही अतिरिक्त पेमेंट करणे टाळा.",
  "TM_But13": "परत जा",
  "TM_Alt124":"कृपया थांबा",
  "TM_Rec58": "थांबा. आम्ही तुमची पेमेंट स्थिती सत्यापित करत आहोत",
  "TM_Rec59": "या सेवेसाठी तुमचे शेवटचे पेमेंट प्रलंबित स्थितीत आहे. ते पूर्ण झाल्यावर आम्ही तुम्हाला सूचित करू. यादरम्यान, आमच्या इतर सेवा एक्सप्लोर करण्यास संकोच करू नका. आपल्या संयमाबद्दल आभार मानतो.",
  "TM_But133": "लिंक वर क्लिक करा"

  }
  
  
  export default language
