const browserStorageKeys = {
  LANGUAGE_OBJ: "langObj",
  SAVE_API_CALLED: "saveApiCalled",
  JWT_DATA: "jwtData",
  GET_SETTINGS_VERSION: "getSettingVersion",
  SETTINGS_DATA: "settingsData",
  CURRENT_PRODUCT: "currentProduct",
  PRODUCT_MAPPING_TYPE: "productMappingType",
  NATIVE_LANG: "NATIVE_LANG",
  NATIVE_LOCATION: "NATIVE_LOCATION",
  ADVERTISEMENT_LIST: "advertisementList",
  DISTRICT_LIST: "districtList",
  GEOGRAPHY_ID: "geographyId",
  SHOW_UPDATE_GEOGRAPHY: "showupdategeography",
  IS_SCHEME_FIRST_VISIT: "isSchemeFirstVisit",
  NOT_ELIGIBLE_CHECK: "notEligibleCheck",
  AUTHORIZATION_TOKEN: "authorizationToken",
  USERNAME: "username",
  ACTIVE_PROFILE: "activeProfile",
  FAMILY_MEMBERS_LIST: "familyMembersList",
  SELECTED_GEOGRAPHY: "selectedGeography",
  USER_TYPE: "userType",
  PRODUCT_LIST: "productList",
  TAGGING_LIST:"taggingList",
  TAGGING_DATA_LIST: "taggingDataList",
  LANG_TYPE: "langType",
  USER_PROFILE: "userProfile",
  SCHEMES_CATEGORY_LIST: "schemesCategoryList",
  SELECTED_CATEGORY_LIST: "selectedCategoryList",
  RECOMENDED_SCHEMES: "recommenedSchemes",
  // CATEGORY_LIST: "categoryList",
  SELECTED_SCHEME_OBJECT: "selectedSchemeObject",
  SELECTED_JOB_OBJECT: "selectedJobObject",
  SERVICES_CATEGORY_LIST: "servicesCategoryList",
  SELECTED_SERVICE_OBJECT: "selectedServiceObject",
  CURRENT_PRODUCT_URL: "currentUrl",
  SURVEY_RESPONSE: "surveyResponse",
  PROFILE_SURVEY_RESPONSE: "profileSurveyResponse",
  USER_PROFILE_LIST: "userProfileList",
  NEXT_URL: "nextUrl",
  RESPONSE_DATA: "responseData",
  PREVIOUS_PROFILE: "previousProfile",
  PREVIOUS_PROFILE_TOKEN: "previousProfileToken",
  DRAFT_APPLIED_SCHEMES: "draftAppliedSchemes",
  USER_PROFILE_DOCUMENT:"userProfileDocumnets",
  IS_GUEST_USER: "isGuestUser",
  RECENT_SEARCH_PRODUCT: "recentSearchProduct",
  FROM_NOTIFICATION:"fromNotification",
  NEWSIGNIN: "newSignIn",
  SURVEY_TYPE: "surveyType",
  AADHAAR_EKYC_DATA: "aadhaarEKycData",
  NO_QUALIFIED_SCHEMES: "noQualifiedSchemes",
  IS_SURVEY_COMPLETED: "isSurveyCompleted",
  PROFILE_QUALIFIED_SCHEMES: "profileQualifiedSchemes",
  QUALIFIED_SCHEMES: "qualifiedSchemes",
  PASSED_SCHEME_ID_LIST: "passedSchemeIdList",
  LOGIN_WITH_OTP: "loginWithOtp",
  AUTHORITY_DATA: "authorityData",
  BENEFITS_LIST: "benefitsList",
  ACTIVE_INDEX: "activeIndex",
  IS_SAVED: "isSaved",
  NATIVE_API_DATA:"nativeApiData",
  LANGUAGE_API_DATA: "languageApiData",
  IS_VIEW_ALL: "isViewAll",
  NEXT_URL: "nextUrl",
  PROFILE_QUALIFIED_JOBS: "profileQualifiedJobs",
  PROACTIVE_QUALIFIED_SCHEMES: "proactiveQualifiedSchemes",
  PROFILE_CONFIRMED_FIELDS: "profileConfirmedFields",
  USE_USER_PROFILE: "useUserProfile",
  PROACTIVE_QUALIFIED_JOBS: "proactiveQualifiedJobs",
  IS_REPEAT_USER: "isRepeatUser",
  SELECTED_STATE_NAME: 'SelectedStateName',
  SELECTED_DISTRICT_NAME: 'SelectedDistrictName',
  NON_ELIGIBLE_OBJ: "nonEligibleObj",
  PRODUCT_DETAILS: "productDetails",
  NO_QUALIFIED_JOBS:"noQualifiedJobs",
  PREVIOUS_SCREEN: "previousScreen",
  SELECTED_PROFILE_DETAILS: "selectedProfileDetails",
  LIST_PROFILE: "listProfile",
  INELIGIBLE_SURVEY_TYPE: "ineligibleSurveyType",
  SCHEMES_LIST:"schemesList",
  IS_INTRODUCTION_BUTTON_CLICKED: "isIntroductionButtonClicked",
  TYPE_OF_APPLICATION: "typeOfApplication",
  SGM_FOR_STATUS: "sgmForStatus",
  SOURCE_ID: "sourceId",
  FROM_COMPLETE_ACTION: "fromCompleteAction",
  IS_BACK_FROM_APPLICATION: "isBackFromApplication",
  EXTERNAL_PROFILE_DATA: "externalProfileData",
  TERM_AND_CONDITIONS: "termAndConditions",
  APPLICATION_FORM: "applicationForm",
  DOCUMENT_LIST: "documentList",
  SELF_DECLERATION: "selfDecleration",
  USER_KYC: "userKyc",
  USER_SERVICE_ID: "userServiceId",
  TERM_AND_CONDITIONS: "termAndConditions",
  SAVE_KYC: "saveKyc",
  APPLICATION_FORM_ID: "applicationFormId",
  APPLICATION_FORM_FIELD_VALUES: "applicationFormFieldValues",
  SAVE_API_CALLED: "saveApiCalled",
  SAVED_PRODUCT: "savedProduct",
  ACTIVE_INDEX: "activeIndex",
  SERVICE_ACTIVE_INDEX: "serviceActiveIndex",
  SERVICE_CATEGORY_INDEX: "serviceCategoryIndex",
  APPLICATION_FORM_DOCUMENT: "applicationFormDocument",
  MAPPING_IDS_FOR_DOCUMENT: "mappingIdsForDocument",
  KYC_FORM: "kycForm",
  KYC_SUBMITTED: "kycSubmitted",
  PROFILE_PIC: "profilePic",
  SHOW_FEEDBACK: "showFeedBack",
  SERVICE_CATEGORY_LIST: "serviceCategoryList",
  SET_PASSWORD: "setPassword",
  SUB_SERVICE_NAME: "subServiceName",
  APPLIED_DATA: "appliedData",
  USER_TYPE: "userType",
  THEME_TYPE:"themeColor",
  PREVIOUS_PROFILE_BUSINESS: "previousProfileBusiness",
  SETTINGS_DATA: "settingsData",
  APPLICATION_FILES: "applicationFiles",
  CURRENT_THEME: "currentTheme",
  NOTIFICATIONS: 'notifications',
  LAST_VISIT_NOTIFICATION: 'lastVisitNotification',
  FROM_DRAFT: "fromDraft",
  FORM_APPLIED: "formAppplied",
  PREVIOUS_DOCS_TO_DELETE: "previousDocsToDelete",
  SHOW_UPDATE_GEOGRAPHY_BUSINESS: "showupdategeographyBusiness",
  HASHED_AADHAAR: "hashedAadhaar",
  CALL_PRODUCT_API: "callProductApi",
  FROM_SAVED_PAGE: "fromSavedPage",
  DOCUMENT_LIST_FOR_API: "documentListForApis",
  AADHAAR_EKYC_DATA: "aadhaarEKycData",
  SGM_FOR_STATUS: "sgmForStatus",
  DRAFT_APPLIED_SCHEMES: "draftAppliedSchemes",
  SURVEY_DATA_OF_APPLICATION: 'surveyDataOfApplication',
  SELECTED_STATE_NAME: 'SelectedStateName',
  SELECTED_DISTRICT_NAME: 'SelectedDistrictName',
  Is_ESign_Done: 'isESignDone',
  IsServiceOnlineDone: 'isServiceOnlineDone',
  IsDbtAadhaarDone: "isDbtAadhaarDone",
  FLAG_VERIFIED: "Verified",
  FLAG_UNVERIFIED: "Unverified",
  FLAG_NOT_VERIFIED: "Not Verified",
  KYC_IMAGE : "kycImage",
  FROM_COMPLETE_ACTION:"fromCompleteAction",
  REQUEST_FOR_COMPLETE_ACTION:"requestForCompleteAction",
  FROM_NOTIFICATION:"fromNotification",
  IS_BACK_FROM_APPLICATION:"isBackFromApplication",
  IS_ADVERTISEMENT_CLICKED:"isAdvertisementClicked",
  USER_PROFILE_DOCUMENT:"userProfileDocumnets",
  SOURCE_LIST:"sourceList",
  LOGGEDIN_MOBILE_NUMBER:"LOGGEDIN_MOBILE_NUMBER",
  AUTHORITY_DATA: "authorityData",
  BENEFITS_LIST: "benefitsList",
  SELECTED_SOURCE_FROM_KYC:"selectedSourceFromKyc",
  SURVEY_DATA_FOR_EXTERNAL_SOURCE:"surveyDataForExternalSource",
  LANGUAGE_LIST:"languageList",
  DO_NOT_SET_FIELD_VALUES:"doNotSetFieldValues",
  IS_SWITCH_CHECKED:"switchChecked",
  IS_NEW_VERSION:"isNewVersion",
  SURVEY_TYPE_ADVERTISEMENT: "surveyTypeAdvertisement",
  MESSAGE : "errorMessageIsON",
  CHECKED_MULTISELECT: "checkedMultiSelect",
  NOTIFICATIONS:"notifications",
  LAST_VISIT_NOTIFICATION:"lastVisitNotification",
  SUBMIT_MESSAGE_TEXT: "submitMessageText",
  DOWNLOAD_BUTTON: "downloadButton",
  LIVELIHOOD_SELECTED: "livelihoodOptionsSelected",
  EDIT_APPLICATION: "editApplication",
  GROUP_ANSWERED: "groupAnswered",
  AD_PRODUCTTYPE:"adProductType",
  IS_PRINTABLE_APPLICATION_FORM_PRESENT: "isPrintableApplicationFormPresent",
  FEES_OBJ: "feesObj",
  IS_AWARNESS_SURVEY_DONE: "isAwarnessSurveyDone",
  DEEP_LINK_URL: "deepLinkUrl",
  // Below keys are mandatory for NuPay Payment gateway
  SERVICE_LEVEL:"serviceLevel",
  LANGUAGE_MODAL:"languageModal",
  IS_APPLICATION_WITHDRAWN: "isApplicationWithdrawn",
  PAYMENT_STATUS: "paymentStatus",
  PSP_APP_LIST: "pspAppList",
  IS_PAYMENT_DONE: "isPaymentDone",
  PAYMENT_STATUS_CODE: "paymentStatusCode",
  RECOMMENDED_SCHEMES_LIST: "recSchemesList",
  LANGUAGE_MODAL_PRODUCT:"languageModalProduct",
  TRANSACTION_ID: "transactionId",
  STATUS_FREQUENCY: "statusFrequency",
  EXPIRATION_TIME: "expirationTime",
  LINK_INTENT: "linkIntent"
};

export default browserStorageKeys;
