import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import browserStorageKeys from "../../../common/browserStorageKeys";
import browserStorage from "../../../utility/browserStorage";
import gaTrackEvent from "../../../utility/gaTrackEvent";
import url_utility from "../../../utility/url"


export default function HeaderMobileView(props) {
  const productType = browserStorage.getItemValue(
    browserStorageKeys.CURRENT_PRODUCT
  ).toLowerCase();
  let adProductType;
  const familyMemberList = JSON.parse(browserStorage.getItemValue(browserStorageKeys.FAMILY_MEMBERS_LIST))
  const inEligibleSurveyType =  browserStorage.getItemValue(browserStorageKeys.INELIGIBLE_SURVEY_TYPE);
  const isSaved = JSON.parse(browserStorage.getItemValue(browserStorageKeys.IS_SAVED));
  const schemesList = JSON.parse(browserStorage.getItemValue(browserStorageKeys.SCHEMES_LIST));
  const isAdvertisementClicked = JSON.parse(browserStorage.getItemValue(browserStorageKeys.IS_ADVERTISEMENT_CLICKED));
  const isLanguageModal = JSON.parse(browserStorage.getItemValue(browserStorageKeys.LANGUAGE_MODAL));
  let profilePercentage = browserStorage.getItemValue(browserStorageKeys.ACTIVE_PROFILE);
  let isAwarnessSurveyDone = browserStorage.getItemValue(browserStorageKeys.IS_AWARNESS_SURVEY_DONE);
  profilePercentage = profilePercentage ? JSON.parse(profilePercentage).profilePercentage : 0;
  const navigate = useNavigate();
  if (browserStorage.getItemValue(browserStorageKeys.AD_PRODUCTTYPE)){
    adProductType = browserStorage.getItemValue(browserStorageKeys.AD_PRODUCTTYPE).toLowerCase();
  }
  const handleBackButton = () => {
    let langObj = JSON.parse(
      browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
    ).code;
    browserStorage.setItemValue(browserStorageKeys.LANG_TYPE, langObj)

    if (!props.isStateView && props.showBackButtonLocation) {
        props.handleBack();
      } else {
        //Temporary solution only
//Note:- Anybody get stuck in back button don't touch this component kindly connect with Varun or Sidharth.
        if (
          window.location.pathname !== "/specificSurvey" && window.location.pathname !== "/home/benefit"
           && ((browserStorage.getItemValue(browserStorageKeys.IS_SURVEY_COMPLETED)))
        ) {
          if (productType === "scheme" && window.location.pathname === "/home/category") {
             navigate("/home");
          } else {
            if (productType === "jobs" && window.location.pathname === "/home/category") {
              navigate("/homejobs");
            } 
            if(productType ==='service'){
              navigate("/services");
            }
          }
          if(productType === "scheme" && window.location.pathname === "/schemes"){
            navigate("/home/category");
          }
        } 

        if (window.location.pathname === "/reviewApplicationDetails") {
          navigate("/applicationForm");
        }

      if ((window.location.pathname === "/specificSurvey" || window.location.pathname === "/categorySurvey") && !isAdvertisementClicked) {
        if (productType === "scheme" || productType === "jobs") {
          if(profilePercentage > 0) {
            navigate(-3);
          }
          else {
            navigate(-2);
          }
        }
        if (productType === 'service') {
          navigate("/services");
        }
        if (productType.toLocaleLowerCase() === 'search') {
          navigate("/search");
        }
      }

      if (
        (window.location.pathname === "/specificSurvey" ||
          window.location.pathname === "/home/benefit" ||
          window.location.pathname === "/applicationForm") &&
        isAdvertisementClicked === true
      ) {
        if (adProductType === "scheme") {
          navigate("/home");
        } else {
          if (adProductType === "jobs") {
            navigate("/homejobs");
          }
          if (adProductType === "service") {
            navigate("/services");
          }
        }
      }
      if (window.location.pathname === "/home/benefit") {
        gaTrackEvent(`${props?.surveyType?.productObj?.productType} benefits`, props?.productCategory, "back", {additionalInfo: props?.surveyType?.name})
        if (productType === "scheme") {
          if(isAwarnessSurveyDone){ 
            navigate("/home");
          }else if(schemesList){
            navigate(-1)
          } else if(isSaved){
            navigate(-3);
          } else{
            navigate(-4);
          }
        }
        if (productType === "jobs") {
          if(isSaved){
            navigate(-3);
          }else{
            navigate(-4);
          }
        }
        if (productType === 'service') {
          navigate(-2);
        }
        if (productType.toLocaleLowerCase() === 'search') {
          navigate("/search");
        }
      }
      if(window.location.pathname === "/applicationStatus")
      {
        const targetPath = productType === 'scheme' ? '/home' : '/services';
        navigate(targetPath);
      }
      
      if(window.location.pathname === "/createProfile" && productType === "scheme"){
        navigate("/home")
      }else if(window.location.pathname === "/createProfile" &&productType === "service"){
        navigate("/services")
      }else if(window.location.pathname === "/createProfile" &&productType === "jobs"){
        navigate("/homejobs")
      }
        if (window.location.pathname === "/changeProfile") {
          navigate("/profile");
        }
        if(window.location.pathname === "/reviewSurvey" && isAdvertisementClicked === true){
          navigate(-1)
        }
        if(window.location.pathname === "/specificSurvey" && isAdvertisementClicked === true){
          if(isSaved){
            navigate(-2)
          }else{
            navigate(-3)
            }
        }
        if(window.location.pathname === "/home/viewAllSchemes" || window.location.pathname === "/home/knowmore"){
          if (productType === "scheme")  {
            navigate("/home");
          }
          else {
           if (productType === "jobs" ) {
             navigate("/homejobs");
           } 
           if(productType ==='service'){
             navigate("/services");
           } 
        }
      }
        if(window.location.pathname === "/selectLocation"){
          navigate("/profile")
        }
        if(window.location.pathname === "/selectLanguage"){
          navigate(-1)
        }
        if(window.location.pathname==="/myservices"){
          gaTrackEvent("services", "my services", "back");
          navigate(-1)
        }
        if(window.location.pathname === "/createProfile" && familyMemberList.length > 1){
            navigate('/changeProfile')
        }else if (window.location.pathname === "/createProfile" && familyMemberList.length === 1){
          navigate('/profile')
        }
        if(window.location.pathname.toLowerCase() === "/confirmlocation")
        {
          navigate('/createProfile')
        }
        if(window.location.pathname === "/updatelocation"){
          navigate('/confirmLocation')
        }
        if(window.location.pathname === "/survey"){
          if (productType === "scheme") {
            navigate("/home");
          } else {
            if (productType === "jobs") {
              navigate("/homejobs");
            } 
            if(productType ==='services'){
              navigate("/services");
            }
          }
        }
        if(window.location.pathname === "/noschemesjobs")
        {
          if (productType === "scheme") {
            navigate("/home");
          } else {
            if (productType === "jobs") {
              navigate("/homejobs");
            } 
            if(productType ==='service'){
              navigate("/services");
            }
          }
      } 
      if(window.location.pathname === "/aboutus"){
        navigate("/profile")
      }
      if(window.location.pathname === "/faq"){
        navigate("/profile")
      }
      if(window.location.pathname === "/reviewPayment"){
        navigate("/reviewApplicationDetails")
      }
      if(window.location.pathname === "/notifications"){
        navigate("/profile")
      }
      if(window.location.pathname === "/reviewSurvey"){
        if (productType === "scheme") {
          navigate("/home");
        } else {
          if (productType === "jobs") {
            navigate("/homejobs");
          } 
          if(productType ==='services'){
            navigate("/services");
          }
        }
      }
      if (productType === "scheme" && window.location.pathname === "/home/category") {
        navigate("/home");
     } else {
       if (productType === "jobs" && window.location.pathname === "/home/category") {
         navigate("/homejobs");
       } 
       if(productType ==='service' && window.location.pathname === "/home/category"){
         navigate("/services");
       }
     }
      if(window.location.pathname === "/ineligible"){
        inEligibleSurveyType==="schemes"?navigate("/home"):navigate("/homejobs");
      }
      if(window.location.pathname === "/manageProfile"){
        if(props.isEditable === true){
          props.cancelEditFields()
        }else{
          navigate("/profile")
        }
      }
      if(window.location.pathname === "/myschemes"){
        gaTrackEvent("schemes", "my schemes", "back");
        navigate("/home")
      }
      if(window.location.pathname === "/myjobs"){
        gaTrackEvent("jobs", "my jobs", "back");
        navigate("/homejobs")
      }
      if(window.location.pathname === "/confirmSurvey"){
        if (productType === "scheme") {
          gaTrackEvent("schemes", "my schemes", "Back after clicking on scheme");
          navigate("/myschemes");
        } else {
          if (productType === "jobs") {
            gaTrackEvent("jobs", "my jobs", "Back after clicking on job");
            navigate("/myjobs");
          } 
          if(productType ==='service'){
            gaTrackEvent("services", "my services", "Back after clicking on service");
            navigate("/services");
          }
        }
      }
      if(window.location.pathname === "/reviewSurvey" && productType === "search"){
        navigate('/search')
      }

      if(window.location.pathname === "/applicationForm"){
        if (isLanguageModal) {
          if (productType === "search") {
            navigate("/search");
          } else if (productType === "service") {
            navigate("/services");
          } else if (productType === "scheme") {
            navigate("/home");
          } else {
            navigate("/homejobs");
          }
          browserStorage.setItemValue(browserStorageKeys.LANGUAGE_MODAL, false);
        } else {
          navigate(-2);
        }
      }
      if(window.location.pathname === "/helpAndSupport"){
        navigate(-1)
      }
      if(window.location.pathname === "/home/schemeAwareness"){
        if(productType === "scheme"){
          navigate("/home/benefit")
        }
      }
      if(window.location.pathname === "/home/thankYou"){
          navigate("/home/benefit")
      }
      /*Code for handling header back button for deep links start*/
      let params_link = url_utility.params.get_param();

      if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("productType")){
        if(params_link.productType === "scheme"){
          navigate("/home")
          if(browserStorage.getItemValue(browserStorageKeys.DEEP_LINK_URL)){
            //this condition is to handle looping of 1st time and 2nd time user
            browserStorage.removeItemValue(browserStorageKeys.DEEP_LINK_URL)
          }
        }else if(params_link.productType === "service"){
          navigate("/services")
          if(browserStorage.getItemValue(browserStorageKeys.DEEP_LINK_URL)){
            //this condition is to handle looping of 1st time and 2nd time user
            browserStorage.removeItemValue(browserStorageKeys.DEEP_LINK_URL)
          }
        }else if(params_link.productType === "jobs"){
          navigate("/homejobs")
          if(browserStorage.getItemValue(browserStorageKeys.DEEP_LINK_URL)){
            //this condition is to handle looping of 1st time and 2nd time user
            browserStorage.removeItemValue(browserStorageKeys.DEEP_LINK_URL)
          }
        }else{
          navigate("/home")
          if(browserStorage.getItemValue(browserStorageKeys.DEEP_LINK_URL)){
            //this condition is to handle looping of 1st time and 2nd time user
            browserStorage.removeItemValue(browserStorageKeys.DEEP_LINK_URL)
          }
        }
      }
      /*Code for handling header back button for deep links End*/
    }//TO DO LIST => Need to find some solution to navigate when multiple pages/components are there as Navigate(-1) will epmty the whole stack and it will become blocker.
  };
  return (
    <>
      <div className="header">
        {props.showBackButton || props.showBackButtonLocation ? (
          <img
            src={process.env.PUBLIC_URL + "/imgs/header/back-arrow.svg"}
            alt="back arrow"
            // onClick={() => {
            //   props.isStateView || props.showBackButtonLocation
            //     ?  props.handleBack() : props.showBackButton ? props.handleBack()
            //     : navigate(-1) ;
            // }}
            onClick={() => handleBackButton()}
          ></img>
        ) : null}
        <span className="size-xxxl text-white lh-2-8 ls-1 padding-l-4 padding-r-1-6 header-text">
          {props.title && props.title !== "undefineds" ? props.title : "Services"}
        </span>
      </div>
      {/* incase of benefit page */}
      {props.showShareButton ? (
        <button className="bttn-success bttn-xxs place">
          <img
            src={process.env.PUBLIC_URL + "/imgs/button/whatsapp-share.svg"}
            alt="Whatsapp share"
          />
          <span>Share</span>
        </button>
      ) : null}
      {/* incase of survey */}
      {/* <img className="place" src={process.env.PUBLIC_URL + "/imgs/header/home.svg"} ></img> */}
    </>
  );
}
