var language = {
	"TM_Txf6" : "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
	"TM_But15":"ಮುಂದುವರೆಯಲು",
	"TM_Pag17": "ಸ್ಥಳವನ್ನು ಬದಲಾಯಿಸಿ",
  "TM_Pag13": "ಉದ್ಯೋಗ ಪ್ರಯೋಜನಗಳು",
	"TM_Pag19" : "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
	"TM_But1":"ನಾವೀಗ ಆರಂಭಿಸೋಣ",
	"TM_Pag20":"ಸ್ಥಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
	"TM_Txf9":"ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ಒದಗಿಸುವುದರಿಂದ ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ನೀವು ಅರ್ಹರಾಗಿರುವ ಪ್ರಯೋಜನಗಳನ್ನು ತೋರಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
	"TM_Txf3": "ಹುಡುಕಾಟ ಸ್ಥಿತಿ",
	"TM_Txf4": "ಹುಡುಕಾಟ ಜಿಲ್ಲೆ",
	"TM_But16": "ಮುಂದೆ",
  "TM_But37":"ಅಪ್ಲಿಕೇಶನ್ ವೀಕ್ಷಿಸಿ",
  "TM_Tag8":"ಸ್ಥಿತಿ",
  "TM_Pag35": "ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿ",
	"TM_Alt1": "3000+ ಕಲ್ಯಾಣ ಯೋಜನೆಗಳಿಗೆ ಅರ್ಹತೆಯನ್ನು ಪರಿಶೀಲಿಸಿ",
	"TM_Alt3": "ನಿಮ್ಮ ಹತ್ತಿರದ ಉದ್ಯೋಗಾವಕಾಶಗಳನ್ನು ಹುಡುಕಿ",
	"TM_Alt2": "ನೀವು ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಬಯಸುವ ಸೇವೆಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
	"TM_Lin1": "ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಪ್ರವೇಶಿಸುವ ಮೂಲಕ, ನೀವು EasyGov’s ಗೆ ಒಪ್ಪುತ್ತೀರಿ",
	"TM_Alt5": "ನೀವು ಅರ್ಹರಾಗಬಹುದಾದ ಕಲ್ಯಾಣ ಪ್ರಯೋಜನಗಳನ್ನು ಹುಡುಕಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಲು ಪ್ರೊಫೈಲ್ ರಚಿಸಿ",
	"TM_But2": "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ರಚಿಸಿ",
	"TX_Sub1": "ಸ್ಕೀಮ್ ವರ್ಗಗಳು",
	"TX_Sub2": "ಪ್ರಮುಖ ಯೋಜನೆಗಳು",
	"TX_Sub3": "ಹೊಸದಾಗಿ ಸೇರ್ಪಡೆಗೊಂಡ ಯೋಜನೆಗಳು",
	"TX_Sub4": "ಜನಪ್ರಿಯ ಯೋಜನೆಗಳು",
	"TM_Pag1": "ಸ್ಕೀಮ್ ವರ್ಗಗಳು",
	"TM_Alt6": "ಯೋಜನೆಗಳು",
	"TM_Alt7": "ಸೇವೆಗಳು",
	"TM_Alt8": "ಉದ್ಯೋಗಗಳು",
	"TM_Alt9": "ಹುಡುಕಿ",
	"TM_Alt10": "ಪ್ರೊಫೈಲ್",
	"TX_Sub7": "ಸೇವೆ ವರ್ಗಗಳು",
	"TX_Sub8": "ಉನ್ನತ ದರ್ಜೆಯ ಸೇವೆಗಳು",
	"TX_Sub9": "ಹೊಸದಾಗಿ ಸೇರ್ಪಡೆಗೊಂಡ ಸೇವೆಗಳು",
	"TX_Sub10": "ಜನಪ್ರಿಯ ಸೇವೆಗಳು",
  "TX_Sub13":"ನನ್ನ ಸೇವೆಗಳು",
  "TM_Pag6": "ನನ್ನ ಸೇವೆಗಳು",
	"TM_Pag18": "ಸೇವೆ ವರ್ಗಗಳು",
	"TM_Alt16": "ನಿಮಗಾಗಿ ಹೆಚ್ಚು ಸೂಕ್ತವಾದ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಲು ಪ್ರೊಫೈಲ್ ರಚಿಸಿ",
	"TM_Alt17": "ಉದ್ಯೋಗದ ವಿವರಗಳು",
  "TM_Alt18" : "ಕೆಲಸದ ವಿವರ",
  "TM_Alt19":"ಆಂಗ್ಲ", 
  "TM_Alt20":"ಹಿಂದಿ",
  "TM_Alt21":"ತಮಿಳು",
  "TM_Alt22":"ಕನ್ನಡ",
  "TM_Alt23":"ಪಂಜಾಬಿ",
  "TM_Alt24":"ಮಲಯಾಳಂ",
  "TM_Alt25":"ಬೆಂಗಾಲಿ",
  "TM_Alt26":"ಗುಜರಾತಿ",
  "TM_Alt27":"ತೆಲುಗು",
  "TM_Alt28":"ಮರಾಠಿ",
  "TM_But3":"ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ",
"TM_But5":"ಅರ್ಹತೆಯನ್ನು ಪರಿಶೀಲಿಸಿ",
"TM_But10":"ಇನ್ನಷ್ಟು ಉದ್ಯೋಗಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
"TX_Sub15":"ಉನ್ನತ ದರ್ಜೆಯ ಉದ್ಯೋಗಗಳು",
"TX_Sub16":"ಹೊಸದಾಗಿ ಸೇರ್ಪಡೆಗೊಂಡ ಉದ್ಯೋಗಗಳು",
"TX_Sub17":"ಜನಪ್ರಿಯ ಉದ್ಯೋಗಗಳು",
"TX_Sub18":"ಶಿಫಾರಸು ಮಾಡಿದ ಉದ್ಯೋಗಗಳು",
"TX_Sub20":"ನನ್ನ ಉದ್ಯೋಗಗಳು",
"TM_alt11":"ಪ್ರಯೋಜನಗಳು",
"TM_Alt13":"ದಾಖಲೆಗಳು",
"TM_Pag4":"ಯೋಜನೆಯ ವಿವರಗಳು",
"TM_Pag21": "ಯೋಜನೆಗಳು",
"TM_Pag22": "ಸೇವೆಗಳು",
"TM_Pag23": "ಉದ್ಯೋಗಗಳು",
"TM_Alt30" : "ಯೋಜನೆಗಳು, ಸೇವೆಗಳು ಮತ್ತು ಉದ್ಯೋಗಗಳಿಗಾಗಿ ಹುಡುಕಿ",
"TM_Lab6": "ಇತ್ತೀಚೆಗೆ ಹುಡುಕಲಾಗಿದೆ",
"TM_Err4": "ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಕಂಡುಬರಲಿಲ್ಲ",
"TM_Err12": "ನಿಮ್ಮ ಸ್ಥಳದಲ್ಲಿ ಈ ಸೇವೆ ಲಭ್ಯವಿಲ್ಲ.",
"TM_Mod25":"ಭಾಷೆ ಬದಲಿಸಿ",
"TM_Rec33":"ಈ ಸೇವೆ ಇಂಗ್ಲಿಷ್ ಭಾಷೆಯಲ್ಲಿ ಮಾತ್ರ ಲಭ್ಯವಿದೆ. ಅಪ್ಲಿಕೇಶನ್‌ನ ಭಾಷೆಯನ್ನು ಬದಲಾಯಿಸಲು ಮುಂದುವರೆಯಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
"TM_Pag12": "ಕೆಲಸದ ವಿವರಗಳು",
"TM_Alt12":"ಅರ್ಹತೆ",
"TM_Tag1":"ಈಗಷ್ಟೇ ಪ್ರಾರಂಭಿಸಲಾಗಿದೆ",
"TM_Sup1": "ನಮ್ಮನ್ನು ಹಿಂಬಾಲಿಸಿ",
"TM_Hep17":"ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಯನ್ನು ಹೊಂದಿದ್ದರೆ ನಮಗೆ ತಿಳಿಸಿ ಮತ್ತು ನಾವು 48 ಕೆಲಸದ ಗಂಟೆಗಳಲ್ಲಿ ನಿಮ್ಮ ಬಳಿಗೆ ಹಿಂತಿರುಗುತ್ತೇವೆ.",
"TM_Lab4":"ಇಮೇಲ್",
"TM_Lin2":"ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು",
"TM_Lin3":"ಗೌಪ್ಯತೆ ನೀತಿ",
"TM_But4":"ಬಳಕೆದಾರರನ್ನು ಬದಲಾಯಿಸಿ",
"TM_Pag15": "ಬಳಕೆದಾರರನ್ನು ಬದಲಾಯಿಸಿ",
"TM_Hep36":"ಅರ್ಹತೆಯನ್ನು ವೀಕ್ಷಿಸಲು ಅಥವಾ ನಿಮ್ಮ ಉಳಿಸಿದ ಡೇಟಾವನ್ನು ಮತ್ತಷ್ಟು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ನೀವು ಬಯಸಿದ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಬಹುದು ಅಥವಾ ಸೇರಿಸಬಹುದು.",
"TM_But18":"ಬಳಕೆದಾರರನ್ನು ಸೇರಿಸಿ",
"TM_Mod5": "{{Name}} ಖಾತೆಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
"TM_Rec7":"ನಿಮ್ಮ EasyGov ಖಾತೆಯನ್ನು ನೀವು ಅಳಿಸಿದಾಗ, ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಿಸಿದ ಇತರ ಪ್ರೊಫೈಲ್‌ಗಳಲ್ಲಿ ಸಂಗ್ರಹವಾಗಿರುವ ಯಾವುದೇ ಡೇಟಾ ಅಥವಾ ಮಾಹಿತಿಯನ್ನು ಹಿಂಪಡೆಯಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. ನಿಮ್ಮ ಎಲ್ಲಾ ಶಿಫಾರಸು ಮಾಡಿದ ಮತ್ತು ಉಳಿಸಿದ ಸ್ಕೀಮ್‌ಗಳನ್ನು ಸಹ ಅಳಿಸಲಾಗುತ್ತದೆ.",
"TM_Err2":"ನೀವು {{Name}} ಪ್ರೊಫೈಲ್ ಅನ್ನು ಅಳಿಸಲಾಗುವುದಿಲ್ಲ ಏಕೆಂದರೆ ಅದು ನಡೆಯುತ್ತಿರುವ ಅಪ್ಲಿಕೇಶನ್(ಗಳು) ಅನ್ನು ಹೊಂದಿದೆ.",
"TM_But11":"ಅಳಿಸಿ",
"TM_But12": "ರದ್ದುಮಾಡು",
"TM_But30" : "ಎಲ್ಲಾ ಉದ್ಯೋಗಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
"TM_But31" : "ಎಲ್ಲಾ ಯೋಜನೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
"TM_Con11": "ಲೋಡ್ ಆಗುತ್ತಿದೆ",
"TM_The1":"ಉಳಿಸಿ",
"TM_The2":"ಡ್ರಾಫ್ಟ್",
"TM_The3":"ಅರ್ಜಿ ಹಾಕಲಾಗಿದೆ",
"TM_The4":"ಸೇರಿಕೊಂಡಳು",
"TM_The5":"ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗಿದೆ",
"TM_Hep7":"ಯಾವುದೇ ಉಳಿಸಿದ ಯೋಜನೆಗಳಿಲ್ಲ", 	
"TM_Hep21":"ಯಾವುದೇ ಕರಡು ಯೋಜನೆಗಳಿಲ್ಲ",	
"TM_Hep22":"ಯಾವುದೇ ಅನ್ವಯಿಕ ಯೋಜನೆಗಳಿಲ್ಲ",
"TM_Hep23":"ನೋಂದಾವಣೆಗೊಂಡ ಯೋಜನೆಗಳಿಲ್ಲ",	
"TM_But7":"ಇನ್ನಷ್ಟು ಯೋಜನೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
"TM_Rec1": "ನಿಮ್ಮ ‘ನನ್ನ ಸ್ಕೀಮ್‌ಗಳಿಂದ’ ಈ ಯೋಜನೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
"TM_Rec2":"ನಿಮ್ಮ 'ನನ್ನ ಸೇವೆಗಳಿಂದ' ಈ ಸೇವೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
"TM_Pag2":"ನನ್ನ ಯೋಜನೆಗಳು",
"TM_Hep10": "ನೀವು ಪ್ರೊಫೈಲ್‌ನಿಂದ ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆ ಮತ್ತು ಸ್ಥಳವನ್ನು ಬದಲಾಯಿಸಬಹುದು",
"TM_But21": "ಅರ್ಥವಾಯಿತು!",
"TM_Alt29":"ಭಾಷೆ ಮತ್ತು ಸ್ಥಳ",
"TM_Hep38":"{{category}} ವರ್ಗದಲ್ಲಿ ಸೂಕ್ತವಾದ ಯೋಜನೆಗಳನ್ನು ಗುರುತಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಲು, ದಯವಿಟ್ಟು {{category}} ಗೆ ಸಂಬಂಧಿಸಿದ ಕೆಲವು ಹೆಚ್ಚುವರಿ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ",
"TM_Hep11":"ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಧರಿಸಿ, ನೀವು ಯಾವುದೇ ಯೋಜನೆಗೆ ಅರ್ಹರಾಗಿದ್ದೀರಿ ಎಂದು ನಮಗೆ ಕಂಡುಬಂದಿಲ್ಲ. ನಾವು ನಿರಂತರವಾಗಿ ಹೆಚ್ಚಿನ ಯೋಜನೆಗಳನ್ನು ಸೇರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಂತರ ಪರಿಶೀಲಿಸಿ.",
"TM_Hep39":"{{category}} ವರ್ಗದಲ್ಲಿ ಸೂಕ್ತವಾದ ಉದ್ಯೋಗಗಳನ್ನು ಗುರುತಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಲು, ದಯವಿಟ್ಟು {{category}} ಗೆ ಸಂಬಂಧಿಸಿದ ಕೆಲವು ಹೆಚ್ಚುವರಿ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ",
"TM_Rec6": "ಈ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ರಾಜ್ಯದಲ್ಲಿ ಯಾವುದೇ ಉದ್ಯೋಗಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ, ನಾವು ನಿರಂತರವಾಗಿ ನವೀಕರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಮ್ಮೊಂದಿಗೆ ಮತ್ತೊಮ್ಮೆ ಪರಿಶೀಲಿಸಿ",
"TM_But27": "ಸರಿ",
"TX_Sub5": "ಶಿಫಾರಸು ಮಾಡಲಾದ ಯೋಜನೆಗಳು",
"TM_Pag9": "ಉದ್ಯೋಗ ವರ್ಗಗಳು",
"TM_Hep42": "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಧರಿಸಿ, ನೀವು ಯಾವುದೇ ಕೆಲಸಕ್ಕೆ ಅರ್ಹರಾಗಿಲ್ಲ ಎಂದು ನಮಗೆ ಕಂಡುಬಂದಿಲ್ಲ. ನಾವು ನಿರಂತರವಾಗಿ ಹೆಚ್ಚಿನ ಉದ್ಯೋಗಗಳನ್ನು ಸೇರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಂತರ ಪರಿಶೀಲಿಸಿ.",
"TM_Hep40":"ಅರ್ಜಿ ಹಾಕು" ,
"TM_Hep41":"ಗಂಟೆಗಳು",
"TM_Hep2": "ವರ್ಗ",
"TM_Alt31": "ಸೇವೆಯಲ್ಲಿ",
  "TM_Alt32" : "ಯೋಜನೆಯಲ್ಲಿ",
  "TM_Alt33" : "ಉದ್ಯೋಗದಲ್ಲಿ",
  "TM_Pag14": "ಪ್ರೊಫೈಲ್ ರಚಿಸಿ",
  "TM_Hep9": "ಪ್ರೊಫೈಲ್ ಅನ್ನು ರಚಿಸುವುದರಿಂದ ನೀವು ಆಸಕ್ತಿ ಹೊಂದಿರುವ ಎಲ್ಲಾ ಅವಕಾಶಗಳನ್ನು ಉಳಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಉಳಿಸಿದ ಡೇಟಾವನ್ನು ಮತ್ತಷ್ಟು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಹಿಂತಿರುಗಲು ನಿಮಗೆ ಅನುಮತಿಸುತ್ತದೆ.",
  "TM_Txf1": "ಪೂರ್ಣ ಹೆಸರು",
  "TM_Txf2": "ಸಂಬಂಧ",
  "TM_But29": "ಉಳಿಸಿ",
  "TM_Err1": "ಅದೇ ಹೆಸರು ಮತ್ತು ಸಂಬಂಧದೊಂದಿಗೆ ಪ್ರೊಫೈಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ!",
  "TM_Err6": "ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ",
  "TM_Pag16": "ಭಾಷೆ ಬದಲಾಯಿಸಿ",
  "TM_Pag33": "ಸ್ಥಾನವನ್ನು ನವೀಕರಿಸಿ",
  "TM_But22": " ನವೀಕರಣ ಮಾಡಿ ",
  "TM_But14": "ಪ್ರೊಫೈಲ್ ಸೇರಿಸಿ",
  "TM_Txf15": "ಹೆಸರು ಸೇರಿಸಿ",
  "TM_But20":"ತಿದ್ದು",
  "TM_Pag29":"ಪ್ರೊಫೈಲ್ ರಚನೆ",
  "TM_Con9":"ಕ್ಷಮಿಸಿ! ನಿಮಗಾಗಿ ಯಾವುದೇ ಹೊಂದಾಣಿಕೆಯ ಸ್ಕೀಮ್‌ಗಳನ್ನು ನಾವು ಹುಡುಕಲಾಗಲಿಲ್ಲ",
  "TM_Con10":"ಕ್ಷಮಿಸಿ! ನಿಮಗಾಗಿ ಯಾವುದೇ ಹೊಂದಾಣಿಕೆಯ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಲು ನಮಗೆ ಸಾಧ್ಯವಾಗಲಿಲ್ಲ",
  "TM_But26": "ಮನೆ",
  "TM_Rec35": "ಸಂಪನ್ಮೂಲ ಲಭ್ಯವಿಲ್ಲ",
  "TM_Rec36":"ಸರ್ವರ್ ಸಮಯ ಮೀರಿದೆ!",
  "TM_Rec37":"ಪ್ರವೇಶವನ್ನು ನಿರಾಕರಿಸಲಾಗಿದೆ!",
  "TM_Mod28":"ಏನೋ ತಪ್ಪಾಗಿದೆ.",
  "TM_Rec10": "ನಾವು ನಿರಂತರವಾಗಿ ಸುಧಾರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
  "TM_But24":"ಮರುಪ್ರಯತ್ನಿಸಿ",
  "TM_Hep6":"ಉಳಿಸಿದ ಸೇವೆಗಳಿಲ್ಲ",
  "TM_Hep13":"ಯಾವುದೇ ಕರಡು ಸೇವೆಗಳಿಲ್ಲ.",
  "TM_Hep14":"ಯಾವುದೇ ಅನ್ವಯಿಕ ಸೇವೆಗಳಿಲ್ಲ.",
  "TM_Hep15":"ಯಾವುದೇ ಅಧಿಸೂಚನೆಗಳಿಲ್ಲ",
  "TM_Hep54":"ಇಂದು",
  "TM_Hep55":"ನಿನ್ನೆ",
  "TM_Hep44":"ಯಾವುದೇ ಸಂಸ್ಕರಿಸಿದ ಸೇವೆಗಳಿಲ್ಲ",
  "TM_Hep16":"ಅಪ್ಲಿಕೇಶನ್ ಬಳಸುವಾಗ ನಿಮ್ಮ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸಲು ಮತ್ತು ನಿಮಗಾಗಿ ನಿಖರವಾದ ಯೋಜನೆಗಳು/ಸೇವೆಗಳು/ಉದ್ಯೋಗಗಳನ್ನು ಪ್ರದರ್ಶಿಸಲು ನಿಮ್ಮ ಸರಿಯಾದ ಸ್ಥಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  "TM_Pag26": "ಸ್ಥಳವನ್ನು ದೃಢೀಕರಿಸಿ",
  "TM_But23": "ದೃಢೀಕರಿಸಿ",
  "TX_Sub21":"ಅಧಿಸೂಚನೆಗಳು",
  "TX_Sub22":"ಪ್ರೊಫೈಲ್ ನಿರ್ವಹಿಸಿ",
  "TX_Sub23":"ಸ್ಥಳ",
  "TX_Sub24":"ಭಾಷೆ",
  "TX_Sub25":"ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು",
  "TX_Sub26":"ನಮ್ಮ ಬಗ್ಗೆ",
  "TX_Sub27":"ಖಾತೆಯನ್ನು ಅಳಿಸಿ",
  "TX_Sub28":"ವೈಯಕ್ತಿಕ ವಿವರಗಳು",
  "TX_Sub29":"ಆರ್ಥಿಕ ವಿವರಗಳು",
  "TX_Sub30":"ವಸತಿ ವಿವರಗಳು",
  "TX_Sub31":"ಶಿಕ್ಷಣದ ವಿವರ",
  "TX_Sub32":"ಉದ್ಯೋಗದ ವಿವರ",
  "TX_Sub33":"ಆರೋಗ್ಯ ವಿವರ",
  "TM_Txf13":"ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "TM_Txf14":"ಜಿಲ್ಲೆ ಆಯ್ಕೆಮಾಡಿ",
  "TM_Hep43":"ಹಿಂದಿನ ಪ್ರಶ್ನೆಗೆ ಬಲಕ್ಕೆ ಸ್ವೈಪ್ ಮಾಡಿ",
  "TM_Hep28":"ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ ಸಂಬಂಧಿತ ಯೋಜನೆಗಳನ್ನು ಹುಡುಕಲು ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ",
  "TM_Hep30":"ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ ಸಂಬಂಧಿತ ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಲು ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ",
  "TM_Hep32": "ನೀವು ಅರ್ಹರಾಗಿರಬಹುದು",
  "TM_Hep35":"ಕೆಲವು ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ",
  "TM_Rel1":"ಮಗಳು",
  "TM_Rel2":"ತಂದೆ",
  "TM_Rel3":"ತಾಯಿ",
  "TM_Rel4":"ಮಗ",
  "TM_Rel5":"ಸಹೋದರ",
  "TM_Rel6":"ಸಹೋದರಿ",
  "TM_Rel7":"ಸಂಗಾತಿ",
  "TM_Rel8":"ಬೇರೆ ಯಾರೋ",
  "TM_Rel9": "ಸ್ವತಃ",
  "TM_Mod4": "ಗಮನ",
  "TM_Mod2": "ಸೇವೆಗಳನ್ನು ಅಳಿಸಿ",
  "TM_Rec12": "ನೀವು ಡೇಟಾವನ್ನು ಬದಲಾಯಿಸಿದರೆ ಅದೇ ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಡೇಟಾದಲ್ಲಿ ನವೀಕರಿಸಲಾಗುತ್ತದೆ. ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತೀರಾ?",
  "TM_Hep12": "ನಿಮ್ಮ ಹಿಂದೆ ಉಳಿಸಿದ ಡೇಟಾವನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ದೃಢೀಕರಿಸಿ.",
  "TM_But28": "ಮುಂದುವರಿಸಿ",
  "TM_Pag24": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
  "TM_Pag25": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನವೀಕರಿಸಿ",
  "TM_Hep33":"ನೀವು ಚೆನ್ನಾಗಿ ಮಾಡುತ್ತಿದ್ದೀರಿ! ಹೋಗ್ತಾ ಇರು...",
  "TM_Con15":"ನಾವು ವಿವರಗಳನ್ನು ಪಡೆಯುವವರೆಗೆ ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ",
  "TM_But9" : "ಹೆಚ್ಚಿನ ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
  "TM_Pag5":"ಯೋಜನೆಯ ವಿವರಗಳು",
  "TM_Pag8":"ಸೇವೆಯ ವಿವರಗಳು",
  "TM_Hep3": "ಸೇವಾ ಮಟ್ಟ",
  "TM_But6": "ಲಿಂಕ್ ನಕಲಿಸಿ",
  "TM_Con1": "ಲಿಂಕ್ ನಕಲಿಸಲಾಗಿದೆ",
  "TM_Con5": "ಡೌನ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
  "TM_Alt14": "ಹೇಗೆ ಅನ್ವಯಿಸಬೇಕು",
  "TM_Alt15": "ಮಾಹಿತಿ",
  "TM_Con3": `ಈ ಯೋಜನೆಯನ್ನು ನಿಮ್ಮ "ನನ್ನ ಸ್ಕೀಮ್" ನಲ್ಲಿ ಉಳಿಸಲಾಗಿದೆ`,
  "TM_Con2": `ಈ ಸೇವೆಯನ್ನು ನಿಮ್ಮ "ನನ್ನ ಸೇವೆ"ಯಲ್ಲಿ ಉಳಿಸಲಾಗಿದೆ`,
  "TM_Alt34":"ಅರ್ಜಿ ಸಲ್ಲಿಸುವ ವಿಧಾನ",
  "TM_Alt35":"ಅರ್ಜಿ ಲಿಂಕ್",
  "TM_Alt36":"ಡೌನ್‌ಲೋಡ್ ಫಾರ್ಮ್",
  "TM_Alt38":"ಆಯ್ಕೆ ವಿಧಾನ",
  "TM_Alt41":"ಯೋಜನೆಯ ಪ್ರಯೋಜನಗಳು",
  "TM_Alt56":"ಪೋಸ್ಟ್",
  "TM_Alt57":"ಸಂಬಳ",
  "TM_Alt58":"ಕೆಲಸದ ವಿವರಗಳು",
  "TM_Alt59":"ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಕೊನೆಯ ದಿನಾಂಕ",
  "TM_Txf12": "ಕಾರಣ",
  "TM_Hep51": "ಅರ್ಹ ಮೌಲ್ಯಗಳು",
  "TM_Hep52": "ನೀವು ಒದಗಿಸಿದ ಮೌಲ್ಯ",
  "TM_Con7" : "ಕ್ಷಮಿಸಿ, ನೀವು ಈ ಯೋಜನೆಗೆ ಅರ್ಹರಲ್ಲ",
  "TM_Con8" : "ಕ್ಷಮಿಸಿ, ನೀವು ಈ ಕೆಲಸಕ್ಕೆ ಅರ್ಹರಲ್ಲ",  
  "TM_Err5" : "ಯಾವುದೇ ಆಯ್ಕೆಗಳು ಉಳಿದಿಲ್ಲ",
  "TM_Con13" : "ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ..",

  "TM_Hep8" : "ಉಳಿಸಿದ ಉದ್ಯೋಗಗಳಿಲ್ಲ",
  "TM_Pag10": "ನನ್ನ ಉದ್ಯೋಗಗಳು",
  "TM_Mod3" : "ಉದ್ಯೋಗವನ್ನು ಅಳಿಸಿ",
  "TM_Rec3" : "ನಿಮ್ಮ 'ನನ್ನ ಉದ್ಯೋಗಗಳು' ನಿಂದ ಈ ಕೆಲಸವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  "TM_Hep24": "ಡ್ರಾಫ್ಟ್ ಉದ್ಯೋಗಗಳಿಲ್ಲ",
  "TM_Hep25": "ಯಾವುದೇ ಅನ್ವಯಿಕ ಉದ್ಯೋಗಗಳಿಲ್ಲ",
  "TM_Hep26": "ನೋಂದಾವಣೆಯಾದ ಉದ್ಯೋಗಗಳಿಲ್ಲ",
  "TM_Hep27": 'ಮುಕ್ತಾಯ ದಿನಾಂಕದಿಂದ 30 ದಿನಗಳ ಅವಧಿಯ ನಂತರ ನಿಮ್ಮ "ನನ್ನ ಉದ್ಯೋಗಗಳು" ನಿಂದ ಎಲ್ಲಾ ಅವಧಿ ಮೀರಿದ ಕೆಲಸಗಳನ್ನು ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ.',
  "TM_Tag3" : "ಅವಧಿ ಮೀರಿದೆ",
  "TM_Tag4" : "ಮೂಲಕ ಅನ್ವಯಿಸಿ",
  "TM_Con4":  'ಈ ಕೆಲಸವನ್ನು ನಿಮ್ಮ "ನನ್ನ ಕೆಲಸ" ನಲ್ಲಿ ಉಳಿಸಲಾಗಿದೆ',
  "TM_Rec5" : "ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾವನ್ನು ಅಳಿಸಲಾಗಿದೆ",
  "TM_Rec4": "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಮೊದಲು ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಿಸಿದ ಎಲ್ಲಾ ಪ್ರೊಫೈಲ್‌ಗಳನ್ನು ಅಳಿಸಬೇಕು",
  "TM_Pag41": "ಅಪ್ಲಿಕೇಶನ್ ವಿವರಗಳು",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  "TM_Mod18": "ಗ್ಯಾಲರಿ",
  "TM_Mod17": "ಕ್ಯಾಮೆರಾ",
  "91412465ea9169dfd901dd5e7c96dd99": "ಅಪ್‌ಲೋಡ್",
  "af6ebe0c0e89201cb2294f32955f7b15": "ಒಂದು ಬಾರಿಗೆ ಕೇವಲ 20 ದಾಖಲೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ .",
  "d5de543c0e83073f05a846d62cc83c81": "ಅದೇ ಕ್ಷೇತ್ರಕ್ಕೆ ಈಗಾಗಲೇ ಡಾಕ್ಯುಮೆಂಟ್ ಅಪ್‌ಲೋಡ್ ಆಗಿದೆ.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "ಮಾನ್ಯವನ್ನು ನಮೂದಿಸಿ",
  "3f68e67dc6c397aaa9d1c24c356f754f": "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
  "1e410e1b9903e7a99b93857f536b7e1d": "ಪರಿಶೀಲಿಸಲಾಗಿಲ್ಲ",
  "f8d855b26faffa0f8779fb44632d6bc1": "ಅಮಾನ್ಯ ಕ್ಯಾಪ್ಚಾ ಕೋಡ್",
  "d53b4d564b9054db9b4b4a50e713197f": "ದಯವಿಟ್ಟು ಕ್ಯಾಪ್ಚಾವನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ",
  "TM_Con18": "ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ನಿಮ್ಮ 'ಡ್ರಾಫ್ಟ್ ಸ್ಕೀಮ್' ನಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
  "TM_Con24": "ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿಮ್ಮ 'ಡ್ರಾಫ್ಟ್ ಜಾಬ್' ನಲ್ಲಿ ಉಳಿಸಲಾಗಿದೆ",
  "TM_Con23": `ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿಮ್ಮ "ಡ್ರಾಫ್ಟ್ ಸೇವೆಗಳಲ್ಲಿ" ಉಳಿಸಲಾಗಿದೆ`,
  "6cf24d7a07132fe88b610e2b3d3def28": "ಸ್ವಯಂ ಘೋಷಣೆ",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "ಈ ಕ್ಷೇತ್ರದ ಅಗತ್ಯವಿದೆ.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "ದಯವಿಟ್ಟು ಕೇವಲ ವರ್ಣಮಾಲೆಗಳು, ಸಂಖ್ಯೆಗಳು, ಅಲ್ಪವಿರಾಮ, -, _, /",
  "562d3d08919ae105c3ebc42d9607d266": "ದಯವಿಟ್ಟು ಅಕ್ಷರಮಾಲೆಗಳು ಅಥವಾ ಸಂಖ್ಯೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "ವರ್ಣಮಾಲೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.",
  "4906d981bb592b32f6982fb4187c7b60": "ಮಾನ್ಯವಾದ ಐಎಫ್ಎಸ್ ಸಿసిಕೋಡ್ ನಮೂದಿಸಿ.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "ಕೇವಲ 11 ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.",
  "7c58cde3b827bc7d57cbf77865046169": "ದಯವಿಟ್ಟು ಸಂಖ್ಯೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ",
  "544b0264d55a1e4cf476b279240d9be4": "ಕೇವಲ ಹತ್ತು ಅಂಕೆಗಳನ್ನು ಅನುಮತಿಸಲಾಗಿದೆ. ದಯವಿಟ್ಟು 0 ಅಥವಾ +91 ಅನ್ನು ಪೂರ್ವಪ್ರತ್ಯಯ ಮಾಡಬೇಡಿ",
  "73f0d980e48b8141e0776e02d96a872f": "ಕೇವಲ ಹನ್ನೆರಡು ಅಂಕೆಗಳನ್ನು ಅನುಮತಿಸಲಾಗಿದೆ.",
  "0ce98bd973419ff33921a2275e7903b9": "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಪ್ಯಾನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.",
  "e55e425dbaf6b6449940eac8ea749092": "ದಯವಿಟ್ಟು ಅಕ್ಷರಮಾಲೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ.",
  "64ff9e3809686d4ea3253a631cae4761": "ದಯವಿಟ್ಟು 2 ದಶಮಾಂಶ ಸ್ಥಾನಗಳಿಗೆ ಸರಿಯಾದ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ.",
  "7ed0ef23d527c2876015161e422f49ce": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಹೆಸರನ್ನು ಕನ್ನಡ ಭಾಷೆಯಲ್ಲಿ ನಮೂದಿಸಿ.",
  "230486b5dd7afed96556ba9bd4175423": "ಪಿನ್ ಕೋಡ್ ತಪ್ಪಾಗಿದೆ",
  "9f44bd93c8988c682d5ef5369fd11f47": "ದಯವಿಟ್ಟು ಮಾನ್ಯ ಇಮೇಲ್ ನಮೂದಿಸಿ",
  "ac598199ea0dd028ade4677a435caf4b": "ಮಾನ್ಯವಾದ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  "10803b83a68db8f7e7a33e3b41e184d0": "ಹುಟ್ಟಿದ ದಿನಾಂಕ",
  "d0de720ccdffea8490dfd2893c50ebc5": "ಇದು {{displayName}} ಗಿಂತ ಹೆಚ್ಚಾಗಿರಬೇಕು",
  "ced6e171e13ebd5c37415c822706b9d4": "ಇದು {{displayName}} ಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "ಇದು {{displayName}} ಗೆ ಸಮನಾಗಿರಬೇಕು",
  "9483135517d156229d0439dff74b1ca0": "ಇದು {{displayName}} ಗಿಂತ ಹೆಚ್ಚು ಅಥವಾ ಸಮನಾಗಿರಬೇಕು",
  "01e9cd748e852b1ae389b980bff195b0": "ಇದು {{displayName}} ಗಿಂತ ಕಡಿಮೆ ಅಥವಾ ಸಮನಾಗಿರಬೇಕು",
  "TM_Pag37": "ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
  "TM_Rec18": "ನಿಮ್ಮ ತುಂಬಿದ ವಿವರಗಳನ್ನು ಬದಲಾಯಿಸಲು 'ಸಂಪಾದಿಸು' ಕ್ಲಿಕ್ ಮಾಡಿ",
  "TM_Alt51": "ಲಗತ್ತು(ಗಳು):",
  "TM_Alt42": "ಧನ್ಯವಾದಗಳು",
  "TM_But9":"ಹೆಚ್ಚಿನ ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ",
  "TM_But19":"ಡೌನ್‌ಲೋಡ್",
  "TM_Con17":"ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ.",
  "TM_Sub48": "ಸಹಾಯ ಮತ್ತು ಬೆಂಬಲ",
  "TM_Alt66": "<b>jioeasygov@easygov.co.in</b> ನಲ್ಲಿ ನಮಗೆ ಇಮೇಲ್ ಕಳುಹಿಸಿ ಮತ್ತು ನಮ್ಮ ತಂಡವು ಶೀಘ್ರದಲ್ಲೇ ನಿಮಗೆ ಹಿಂತಿರುಗುತ್ತದೆ",
  "TM_Alt65": "ಯಾವುದೇ ಪ್ರಶ್ನೆಗೆ ಸಹಾಯ ಬೇಕೇ?",
  "TM_Pag54": "ಸೇವಾ ಪ್ರಯೋಜನಗಳು",
  "TM_Alt37": "ಸರ್ಕಾರಿ ಶುಲ್ಕ",
  "TM_But105" : "ಇನ್ನಷ್ಟು ಅನ್ವೇಷಿಸಿ",
  "TM_But17": "ಸಲ್ಲಿಸು",
  "TM_Alt39": "ಸಲ್ಲಿಕೆ ವಿಳಾಸ",
  "TM_But58": "ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಮುಂದುವರಿಯಿರಿ",
  "TM_But100" : "ಹಿಂದೆ ಹೋಗು",
  "TM_But95": "ಈಗ ಪಾವತಿಸಿ",
  "TM_Alt88": "ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಲು ದಯವಿಟ್ಟು ಕೆಳಗಿನ ಮೊತ್ತವನ್ನು ಪಾವತಿಸಿ",
  "TM_Con32" : "ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
  "TM_But110": "അപേക്ഷ",
  "TM_Txf16": "ಇಲ್ಲಿ ಹುಡುಕಿ",
  "TM_Hep69": "'ಸ್ಕೀಮ್ ಬೆಂಬಲವನ್ನು ಪಡೆಯಿರಿ' ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಸಣ್ಣ ಸಮೀಕ್ಷೆಯನ್ನು ಕೈಗೊಳ್ಳಿ, ಇದು ನಿಮಗೆ ಸರಿಯಾದ ಬೆಂಬಲವನ್ನು ಒದಗಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
  "TM_But107": "ಸ್ಕೀಮ್ ಬೆಂಬಲವನ್ನು ಪಡೆಯಿರಿ ಎಂದರೇನು?",
  "TM_But106": "ಸ್ಕೀಮ್ ಬೆಂಬಲವನ್ನು ಪಡೆಯಿರಿ",
  "TM_Pag32": "ಯೋಜನೆಯ ಜಾಗೃತಿ ಸಮೀಕ್ಷೆ",
  "TM_Con14": "ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.",
  "TM_Tag5": "ಖಾಲಿ ಸ್ಥಾನ",
  "TM_Alt107": "ದಿನಗಳು",
  "TM_Rec11":"ಆಯ್ಕೆಮಾಡಿದ ಅಧಿಸೂಚನೆಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  "TM_Mod7":"ಅಧಿಸೂಚನೆಗಳನ್ನು ಅಳಿಸಿ",
  "TM_But25":"ಎಲ್ಲವನ್ನು ಆರಿಸು",
  "TM_Mod1": "ಸ್ಕೀಮ್ ಅಳಿಸಿ",
  "TM_Rec50": "ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಹಿಂಪಡೆಯಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ ?",
  "TM_But23": "ದೃಢೀಕರಿಸಿ",
  "TM_But12": "ರದ್ದುಮಾಡಿ",
  "TM_Con6" : "ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ (xxxxxx{{phoneNumber}}) OTP ಕಳುಹಿಸಲಾಗಿದೆ",
  "TM_But17": "ಸಲ್ಲಿಸು",
  "TM_But61": "OTP ಅನ್ನು ಮರುಕಳುಹಿಸಿ",
  "TM_Con20": "ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಹಿಂಪಡೆಯಲಾಗಿದೆ",
  "TM_But38": "ಸಂಪೂರ್ಣ ಕ್ರಿಯೆಗಳು",
  "TM_But62": "ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಿ",
  "TM_Lab11": "ಅರ್ಜಿಯನ್ನು ಹಿಂಪಡೆಯಿರಿ",
  "TM_Err3" : "ಅಮಾನ್ಯ OTP",
  "TM_Err13": "OTP ಅವಧಿ ಮುಗಿದಿದೆ",
  "TM_Err14": "OTP ರಚಿಸಲು ನೀವು ಗರಿಷ್ಠ ಮಿತಿಯನ್ನು ಮೀರಿದ್ದೀರಿ.  ದಯವಿಟ್ಟು 3 ಗಂಟೆಗಳ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
  "TM_But40": "ಕ್ರಿಯೆಗಳು",
  "TM_Tag9": "ಕ್ರಮಗಳು ಅಗತ್ಯವಾಗಿವೆ",
  "TM_Tag10": "ಯಾವುದೇ ಕ್ರಮ ಅಗತ್ಯವಿಲ್ಲ",
  "TM_Tag8" : "പദവി",
  "TM_But76" : "കാണുക",
  "TM_Alt87" : "ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಲಭ್ಯವಿಲ್ಲ",
  "TM_Pag34": "ಪಾವತಿ ವಿವರಗಳು",
  "TM_Hea1": "ಒಟ್ಟು ಮೊತ್ತ",
  "TM_Hea2": "ಸಾರಾಂಶ",
  "TM_Sub56": "ಒಟ್ಟು",
  "TM_But42": "ಪಾವತಿ",
  "TM_Lab18": "ರಶೀದಿಯನ್ನು ವೀಕ್ಷಿಸಿ",
  "TM_Err34": "ಪಾವತಿ ವಿಫಲವಾದ ಕಾರಣ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಲಿಲ್ಲ.",
  "TM_Rec55": "ನಿಮ್ಮ ಪಾವತಿಯ ಸ್ಥಿತಿಯನ್ನು ಖಚಿತಪಡಿಸಲು ಇದು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದೆ. ನಾವು ನಿಮ್ಮ ಬ್ಯಾಂಕ್‌ನೊಂದಿಗೆ ನಿರಂತರವಾಗಿ ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ. ದಯವಿಟ್ಟು ಮುಂದಿನ 48 ಗಂಟೆಗಳವರೆಗೆ ಯಾವುದೇ ಹೆಚ್ಚುವರಿ ಪಾವತಿಗಳನ್ನು ಮಾಡುವುದನ್ನು ತಪ್ಪಿಸಿ.",
  "TM_But13": "ಹಿಂದೆ ಹೋಗು",
  "TM_Alt124": "ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ",
  "TM_Rec58": "ಹೋಲ್ಡ್ ಮಾಡಿ.ನಾವು ನಿಮ್ಮ ಪಾವತಿ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ",
  "TM_Rec59": "ಈ ಸೇವೆಗಾಗಿ ನಿಮ್ಮ ಕೊನೆಯ ಪಾವತಿಯು ಬಾಕಿ ಉಳಿದಿದೆ. ಅದು ಪೂರ್ಣಗೊಂಡಾಗ ನಾವು ನಿಮಗೆ ಸೂಚಿಸುತ್ತೇವೆ. ಈ ಮಧ್ಯೆ, ನಮ್ಮ ಇತರ ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಮುಕ್ತವಾಗಿರಿ. ನಿಮ್ಮ ಸಹನೆಗೆ ಧನ್ಯವಾದಗಳು.",
  "TM_But133": "ಲಿಂಕ್ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ"

  }
  
  export default language
