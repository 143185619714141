var language = {
	"TM_Txf6": "कृपया अपनी पसंदीदा भाषा चुनें",
	"TM_But15": "आगे बढ़ें",
	"TM_Pag19": "भाषा चुने",
	"TM_Pag17": "स्थान बदलें",
  "TM_Pag13": "नौकरी लाभ",
	"TM_But1": "आएँ शुरू करें",
	"TM_Pag20": "स्थान चुनें",
	"TM_Txf9": "आपके स्थान की जानकारी देने से हमें आपको उन सभी लाभों को दिखाने में सहायता मिलेगी जिनके लिए आप पात्र हैं।",
	"TM_Txf3": "राज्य खोजें",
	"TM_Txf4": "जिला खोजें",
	"TM_But16": "अगला",
  "TM_But37":"आवेदन देखें",
  "TM_Tag8": "स्थिति",
  "TM_Pag35": "आवेदन की स्थिति",
	"TM_Alt1": "3000+ कल्याण योजनाओं के लिए पात्रता की जाँच करें",
	"TM_Alt3": "अपने आस-पास नौकरी के उद्घाटन खोजें",
	"TM_Alt2": "उन सेवाओं की जाँच करें जिन्हें आप लागू करना चाहते हैं",
	"TM_Lin1": "ऐप को एक्सेस करके, आप EasyGov’s की सहमति देते हैं",
	"TM_Alt5": "आप जिन कल्याणकारी लाभों के लिए पात्र हो सकते हैं उन्हें ढूंढने में हमारी सहायता के लिए प्रोफ़ाइल बनाएं",
	"TM_But2": "अपना प्रोफ़ाइल बनाए",
	"TX_Sub1": "योजना श्रेणियाँ",
	"TX_Sub2": "प्रमुख योजनाएं",
	"TX_Sub3": "नई जोड़ी गई योजनाएं",
	"TX_Sub4": "लोकप्रिय योजनाएं",
  "TX_Sub13": "मेरी सेवाएं",
  "TM_Pag6": "मेरी सेवाएं",
	"TM_Pag1": "योजना श्रेणियाँ",
	"TM_Alt6": "योजनाओं",
	"TM_Alt7": "सेवाएं",
	"TM_Alt8": "नौकरियां",
	"TM_Alt9": "खोज",
	"TM_Alt10": "प्रोफ़ाइल",
	"TX_Sub7": "सेवा श्रेणियाँ",
	"TX_Sub8": "टॉप रेटेड सेवाएं",
	"TX_Sub9": "नई जोड़ी गई सेवाएं",
	"TX_Sub10": "लोकप्रिय सेवाएं",
	"TM_Pag18": "सेवा श्रेणियाँ",
	"TM_Alt16": "आपके लिए सबसे उपयुक्त नौकरियां खोजने में हमारी सहायता के लिए प्रोफ़ाइल बनाएं",
	"TM_Alt17": "नौकरी विवरण",
  "TM_Alt18" : "नौकरी का विवरण",
  "TM_Alt19":"अंग्रेज़ी", 
  "TM_Alt20":"हिन्दी",
  "TM_Alt21":"तामिल",
  "TM_Alt22":"कन्नड़",
  "TM_Alt23":"पंजाबी",
  "TM_Alt24":"मलयालम",
  "TM_Alt25":"बंगाली",
  "TM_Alt26":"गुजराती",
  "TM_Alt27":"तेलुगू",
  "TM_Alt28":"मराठी",
  "TM_But3":"सभी को देखें",
"TM_But5":"पात्रता की जाँच करें",
"TM_But10":"अधिक नौकरियों का अन्वेषण करें",
"TX_Sub15":"शीर्ष रेटेड नौकरियां",
"TX_Sub16":"नई जोड़ी गई नौकरियां",
"TX_Sub17":"लोकप्रिय नौकरियां",
"TX_Sub18":"अनुशंसित नौकरियां",
"TX_Sub20":"मेरे काम",
"TM_alt11":"फ़ायदे",
"TM_Alt13":"दस्तावेज",
"TM_Pag4":"योजना विवरण",
"TM_Pag21": "योजनाओं",
"TM_Pag22": "सेवाएं",
"TM_Pag23": "नौकरियां",
"TM_Alt30" : "योजनाओं, सेवाओं और नौकरियों के लिए खोजें",
"TM_Lab6": "हाल ही में खोजा गया",
"TM_Err4": "कोई परिणाम नहीं मिला",
"TM_Err12": "आपके स्थान पर यह सेवा उपलब्ध नहीं है।",
"TM_Mod25":"भाषा बदलें",
"TM_Rec33":"यह सेवा सिर्फ अंग्रेजी भाषा में उपलब्ध है। ऐप की भाषा बदलने के लिए 'आगे बढ़ें' पर क्लिक करें",
"TM_Pag12": "नौकरी का विवरण",
"TM_Alt12":"पात्रता",
"TM_Tag1":"अभी-अभी लॉन्च किया गया है",
"TX_Sub25" : "पूछे जाने वाले प्रश्न",
"TM_Sup1": "हमें का पालन करें",
"TM_Hep17":"यदि आपके पास कोई प्रश्न है तो हमें बताएं और हम 48 व्यावसायिक घंटों के भीतर आपके पास वापस आ जाएंगे।",
"TM_Lab4":"ईमेल",
"TM_Lin3":"गोपनीयता नीति",
"TM_But4":"उपयोगकर्ता बदलें",
"TM_Pag15": "उपयोगकर्ता बदलें",
"TM_Hep36":"पात्रता देखने या अपने सहेजे गए डेटा को आगे संसाधित करने के लिए आप अपनी वांछित प्रोफ़ाइल का चयन या जोड़ सकते हैं।",
"TM_But18":"उपयोगकर्ता जोड़ें",
"TM_Mod5": "क्या आप वाकई {{Name}} खाते को स्थायी रूप से हटाना चाहते हैं?",
"TM_Rec7":"जब आप अपना EasyGov खाता हटाते हैं, तो आप अपनी प्रोफ़ाइल और अपने खाते से संबद्ध अन्य प्रोफ़ाइल में संग्रहीत किसी भी डेटा या जानकारी को पुनः प्राप्त नहीं कर पाएंगे। आपकी सभी अनुशंसित और सहेजी गई योजनाएं भी हटा दी जाएंगी।",
"TM_Err2":"आप {{Name}} प्रोफ़ाइल को हटा नहीं सकते क्योंकि इसमें एक चालू एप्लिकेशन है।",
"TM_But11":"मिटाना",
"TM_But12": "रद्द करें",
"TM_But30" : "सभी नौकरियां देखें",
"TM_But31" : "सभी योजनाएं देखें",
"TM_Con11": "लोड हो रहा है",
"TM_The1":"सेव",
"TM_The2":"ड्राफ्ट",
"TM_The3":"आवेदित",
"TM_The4":"नामांकित",
"TM_The5":"संसाधित (प्रासेस्ट)",
"TM_Hep7":"कोई सहेजी गई योजना नहीं",
"TM_Hep21":"कोई ड्राफ्ट योजना नहीं",
"TM_Hep22":"कोई लागू योजना नहीं",
"TM_Hep23":"कोई नामांकित योजना नहीं",
"TM_But7":"अधिक योजनाओं का अन्वेषण करें",
"TM_Rec1": "क्या आप वाकई इस योजना को अपनी 'मेरी योजनाओं' से हटाना चाहते हैं?",
"TM_Rec2":"क्या आप इस सेवा को अपनी 'मेरी सेवाएं ' से हटाना चाहते हैं?",
"TM_Pag2":"मेरी योजनाएं",
"TM_Pag9":"नौकरी श्रेणियां",
"TM_Hep10": "आप प्रोफ़ाइल से अपनी पसंदीदा भाषा और स्थान बदल सकते हैं",
"TM_But21": "समझ गया!",
"TM_Alt29":"भाषा और स्थान",
"TM_Hep38":"{{category}} श्रेणी में उपयुक्त योजनाओं की पहचान करने में हमारी सहायता के लिए, कृपया {{category}} से संबंधित कुछ अतिरिक्त प्रश्नों के उत्तर दें।",
"TM_Hep11":"आपकी प्रोफ़ाइल के आधार पर, हमने आपको किसी भी योजना के लिए योग्य नहीं पाया। हम लगातार और योजनाएँ जोड़ रहे हैं, कृपया बाद में जाँच करें।",
"TM_Hep39":"{{category}} श्रेणी में उपयुक्त नौकरियों की पहचान करने में हमारी सहायता के लिए, कृपया {{category}} से संबंधित कुछ अतिरिक्त प्रश्नों के उत्तर दें।",
"TM_Rec6": "इस समय आपके राज्य में कोई जॉब कॉन्फ़िगर नहीं है, हम लगातार अपडेट कर रहे हैं, कृपया हमारे साथ फिर से जांचें",
"TM_But27": "ठीक है",
"TX_Sub5": "अनुशंसित योजनाएं",
"TM_Hep42": "आपकी प्रोफ़ाइल के आधार पर, हमने आपको किसी भी नौकरी के लिए योग्य नहीं पाया। हम लगातार और नौकरियां जोड़ रहे हैं, कृपया बाद में जांचें।",
"TM_Hep40":"पर लागू" ,
"TM_Hep41":"घंटे",
"TM_Hep2": "श्रेणी",
"TM_Alt31": "सेवा में",
  "TM_Alt32" : "योजना में",
  "TM_Alt33" : "नौकरी में",
  "TM_Pag14": "प्रोफ़ाइल बनाने",
  "TM_Hep9": "एक प्रोफ़ाइल बनाने से आप उन सभी अवसरों को सहेज सकते हैं जिनमें आप रुचि रखते हैं, और अपने सहेजे गए डेटा को आगे संसाधित करने के लिए किसी भी समय बाद में वापस आ सकते हैं।",
  "TM_Txf1": "पूरा नाम",
  "TM_Txf2": "रिश्ता",
  "TM_But29": "बचाना",
  "TM_Err1": "समान नाम और संबंध वाली प्रोफ़ाइल पहले से मौजूद है!",
  "TM_Err6": "केवल वर्णों की अनुमति है",
  "TM_Pag16": "भाषा बदलो",
  "TM_Pag33": "स्थान अद्यतन करें",
  "TM_But22": " आधुनिकीकरणअ ",
  "TM_But14": "प्रोफ़ाइल जोड़ें",
  "TM_Txf15": "नाम जोड़ें",
  "TM_But20":"संपादित करें",
  "TM_Pag29":"प्रोफ़ाइल निर्माण",
  "TM_Con9":"माफ़ करना! हमें आपके लिए कोई मैचिंग स्कीम नहीं मिली",
  "TM_Con10":"माफ़ करना! हमें आपके लिए कोई मिलती-जुलती नौकरी नहीं मिली",
  "TM_But26":"घर",
  "TM_Rec35":"संसाधन उपलब्ध नहीं है",
  "TM_Rec36":"सर्वर का  समय समाप्त!",
  "TM_Rec37":"प्रवेश निषेध!",
  "TM_But24":"पुन: प्रयास करें",
  "TM_Mod28":"कुछ गलत हो गया है।",
  "TM_Rec10": "हम लगातार सुधार कर रहे हैं, कृपया कुछ समय पश्चात पुन: प्रयास करें।",
  "TM_Hep16": "ऐप का उपयोग करते समय अपने अनुभव को बढ़ाने के लिए सही स्थान चुनें जिससे आपके लिए सटीक योजनाएं/सेवाएं/नौकरियां दिखा सके।",
  "TM_Hep6":"कोई सेवा सुरक्षित नहीं की है",
  "TM_Hep13":"ड्राफ्ट में कोई सेवाएं नहीं है।",
  "TM_Hep14":"किसी सेवा के लिए आवेदन नहीं किया गया है।",
  "TM_Hep54":"आज",
  "TM_Hep55":"कल",
  "TM_Hep15":"कोई नोटिफिकेशन नहीं है।",
  "TM_Hep44":"कोई संसाधित सेवाएँ नहीं",
  "TM_Pag26": "स्थान की पुष्टि करें",
  "TM_But23": "पुष्टि करें",
  "TX_Sub21":"नोटिफिकेशन्स",
  "TX_Sub22":"प्रोफ़ाइल मैनेज करें",
  "TX_Sub23":"स्थान",
  "TX_Sub24":"भाषा",
  "TX_Sub25":"पूछे जाने वाले प्रश्न",
  "TX_Sub26":"हमारे बारे में",
  "TX_Sub27":"अकाउंट हटाएं",
  "TX_Sub28":"व्यक्तिगत विवरण",
  "TX_Sub29":"आर्थिक विवरण",
  "TX_Sub30":"आवास विवरण",
  "TX_Sub31":"शिक्षा प्रोफ़ाइल",
  "TX_Sub32":"व्यवसाय प्रोफ़ाइल",
  "TX_Sub33":"स्वास्थ्य प्रोफ़ाइल",
  "TM_Txf13":"राज्य चुनें",
  "TM_Txf14":"जिला चुनें",
  "TM_Hep43":"पिछले प्रश्न के लिए दाईं ओर स्वाइप करें",
  "TM_Hep28":"अपनी प्रोफ़ाइल के अनुसार प्रासंगिक योजनाएँ खोजने के लिए प्रश्नों के उत्तर दें",
  "TM_Hep30":"अपनी प्रोफ़ाइल के अनुसार प्रासंगिक नौकरियां खोजने के लिए सवालों के जवाब दें",
  "TM_Hep32": "आप इसके पात्र हो सकते हैं",
  "TM_Hep35":"कुछ प्रश्नों के उत्तर दीजिए",
  "TM_Rel1":"बेटी",
  "TM_Rel2":"पिता",
  "TM_Rel3":"माता",
  "TM_Rel4":"बेटा",
  "TM_Rel5":"भाई",
  "TM_Rel6":"बहन",
  "TM_Rel7":"पति/पत्नी",
  "TM_Rel8":"कोई और",
  "TM_Rel9": "स्वयं",
  "TM_Mod4": "ध्यान दें",
  "TM_Mod2": "सेवाएं हटाएं",
  "TM_Rec12": "यदि आप डेटा बदलते हैं तो वह आपके प्रोफ़ाइल डेटा में भी अपडेट हो जाएगा। क्या आप जारी रखना चाहते हैं?",
  "TM_Hep12": "अपने पहले सहेजे गए डेटा की समीक्षा करें और पुष्टि करें।",
  "TM_But28": "जारी रखना",
  "TM_Pag24": "अपने विवरण की समीक्षा करें",
  "TM_Pag25": "अपना विवरण अपडेट करें",
  "TM_Hep33":"आप अच्छा कर रहे हैं! आगे बढ़िए...",
  "TM_Con15":"कृपया प्रतीक्षा करें जब तक हम विवरण प्राप्त नहीं कर लेते",
  "TM_But9" : "और सेवाएं देखें",
  "TM_Pag5":"योजना विवरण",
  "TM_Pag8":"सेवा विवरण",
  "TM_Hep3": "सेवा स्तर",
  "TM_But6": "लिंक कॉपी करें",
  "TM_But19": "डाउनलोड करें",
  "TM_Con1": "लिंक कॉपी किया गया",
  "TM_Con5": "डाउनलोड किया गया",
  "TM_Alt14": "आवेदन की प्रक्रिया",
  "TM_Alt15": "जानकारी",
  "TM_Con3": `इस योजना को "मेरी योजना" में सेव किया गया है`,
  "TM_Con2": `यह सेवा आपकी "मेरी सेवाएं" में सहेजी गई है`,
  "TM_Con4": `इस नौकरी को "मेरी नौकरी" में सेव किया गया है`,
  "TM_Alt34":"आवेदन करने की प्रक्रिया",
  "TM_Alt35":"आवेदन लिंक",
  "TM_Alt36":"फॉर्म डाउनलोड करें",
  "TM_Alt38":"चयन प्रक्रिया",
  "TM_Alt41":"योजना लाभ",
  "TM_Alt56":"पद",
  "TM_Alt57":"वेतन",
  "TM_Alt58":"नौकरी विवरण",
  "TM_Alt59":"आवेदन करने की अंतिम तारीख",
  "TM_Txf12": "कारण",
  "TM_Hep51": "योग्य उत्तर",
  "TM_Hep52": "आपके द्वारा दिए गए उत्तर",
  "TM_Con7" : "क्षमा करें, आप इस योजना के लिए योग्य नहीं हैं",
  "TM_Con8" : "क्षमा करें, आप इस नौकरी के लिए योग्य नहीं हैं",
  "TM_Err5" : "कोई विकल्प नहीं बचा",
  "TM_Con13" : "आपका डेटा सफलतापूर्वक सुरक्षित कर लिया गया है।",
  "TM_Hep8" : "कोई नौकरियां नहीं की है",
  "TM_Pag10": "मेरी नौकरियां",
  "TM_Mod3" : "नौकरी हटाएं",
  "TM_Rec3" : "क्या आप इस नौकरी को अपने 'मेरी नौकरियां' से हटाना चाहते हैं?",
  "TM_Hep24": "किसी नौकरी के लिए आवेदन नहीं किया गया है।",
  "TM_Hep25": "किसी नौकरी के लिए आवेदन नहीं किया गया है।",
  "TM_Hep26": "कोई नामांकित नौकरी नहीं है।",
  "TM_Hep27": 'नौकरियों को अंतिम तारीख से 30 दिनों की अवधि के बाद "मेरी नौकरियां" में से समय सीमा समाप्त हो गयी सभी नौकरियों को हटा दिया जाएगा।',
  "TM_Tag3" : "समय सीमा समाप्त",
  "TM_Tag4" : "द्वारा आवेदन करें",
  "TM_Rec5": "आपका सारा डेटा हटा दिया गया है।",
  "TM_Rec4": "अपने अकाउंट को हटाने के लिए आपको पहले अपने अकाउंट से जुड़ी सभी प्रोफ़ाइलों को हटाना होगा।",
  "TM_Pag41": "आवेदन विवरण",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "क्या आप वाकई इस काग़जात को हटाना चाहते हैं?",
  "TM_Lin2": "नियम एवं शर्तें",
  "TM_Mod18": "गैलरी",
  "TM_Mod17": "कैमरा",
  "91412465ea9169dfd901dd5e7c96dd99": "अपलोड करें",
  "af6ebe0c0e89201cb2294f32955f7b15": "एक समय में केवल 20 काग़जात की अनुमति है।",
  "d5de543c0e83073f05a846d62cc83c81": "समान क्षेत्र के लिए काग़जात पहले ही अपलोड हो चुका है।",
  "fca8da28cbcbe094dc646872e7cdbc7b": "वैध दर्ज करें",
  "3f68e67dc6c397aaa9d1c24c356f754f": "प्रमाणित",
  "1e410e1b9903e7a99b93857f536b7e1d": "प्रमाणित नहीं है",
  "f8d855b26faffa0f8779fb44632d6bc1": "अमान्य कैप्चा कोड",
  "d53b4d564b9054db9b4b4a50e713197f": "कृपया कैप्चा यहाँ दर्ज करें",
  "TM_Con18": "आपका आवेदन 'ड्राफ्ट योजना' में सफलतापूर्वक सेव कर लिया गया है",
  "TM_Con24": "आपका आवेदन 'ड्राफ्ट नौकरी' में सफलतापूर्वक सेव कर लिया गया है",
  "TM_Con23": `आपका आवेदन "ड्राफ्ट सेवाओं" में सफलतापूर्वक सेव कर लिया गया है`,
  "6cf24d7a07132fe88b610e2b3d3def28": "स्वघोषणा",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "यह क्षेत्र आवश्यक है।",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "कृपया केवल वर्ण, अंक, अल्पविराम, -, _, / दर्ज करें।",
  "562d3d08919ae105c3ebc42d9607d266": "कृपया केवल वर्ण या अंक दर्ज करें",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "केवल अक्षरों की अनुमति है।",
  "4906d981bb592b32f6982fb4187c7b60": "वैध आईएफएससी कोड दर्ज करें।",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "केवल 11 वर्णों की अनुमति है।",
  "7c58cde3b827bc7d57cbf77865046169": "कृपया केवल अंक दर्ज करें",
  "544b0264d55a1e4cf476b279240d9be4": "केवल दस अंकों की अनुमति है। कृपया 0 या +91 के आगे न लगाएं",
  "73f0d980e48b8141e0776e02d96a872f": "केवल बारह अंकों की अनुमति है।",
  "0ce98bd973419ff33921a2275e7903b9": "कृपया वैध पैन संख्या दर्ज करें।",
  "e55e425dbaf6b6449940eac8ea749092": "कृपया केवल वर्ण दर्ज करें।",
  "64ff9e3809686d4ea3253a631cae4761": "कृपया 2 दशमलव स्थानों तक सही मान दर्ज करें।",
  "7ed0ef23d527c2876015161e422f49ce": "कृपया अपना नाम कन्नड़ भाषा में दर्ज करें।",
  "230486b5dd7afed96556ba9bd4175423": "पिन कोड गलत है",
  "9f44bd93c8988c682d5ef5369fd11f47": "कृपया वैध ई मेल दर्ज करें",
  "ac598199ea0dd028ade4677a435caf4b": "एक वैध आधार संख्या दर्ज करें",
  "10803b83a68db8f7e7a33e3b41e184d0": "जन्म की तारीख",
  "d0de720ccdffea8490dfd2893c50ebc5": "यह {{displayName}} से बड़ा होना चाहिए",
  "ced6e171e13ebd5c37415c822706b9d4": "यह {{displayName}} से छोटा होना चाहिए",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "यह {{displayName}} के बराबर होना चाहिए",
  "9483135517d156229d0439dff74b1ca0": "यह {{displayName}} से बड़ा या बराबर होना चाहिए",
  "01e9cd748e852b1ae389b980bff195b0": "यह {{displayName}} से कम या बराबर होना चाहिए",
  "TM_Pag37": "विवरण की समीक्षा करें",
  "TM_Rec18": "अपने विवरण को बदलने के लिए 'संपादित करें' पर क्लिक करें।",
  "TM_Alt51": "अनुलग्नक(ओं):",
  "TM_Alt42": "आपका धन्यवाद",
  "TM_But9": "अधिक सेवाएं खोजें",
  "TM_Con17": "आपका एप्लिकेशन डाउनलोड हो गया है।",
  "TM_Sub48": "मदद और सहायता",
  "TM_Alt66": "हमें <b>jioeasygov@easygov.co.in</b> पर एक ईमेल भेजें और हमारी टीम शीघ्र ही आपसे संपर्क करेगी",
  "TM_Alt65": "किसी प्रश्न के लिए सहायता चाहिए?",
  "TM_Pag54": "सेवा के लाभ",
  "TM_Alt37": "सरकारी शुल्क",
  "TM_But105" : "और खोजें",
  "TM_Alt39": "जमा करने का पता",
  "TM_But17": "जमा करें",
  "TM_But58": "आवेदन करने के लिए आगे बढ़ें",
  "TM_But100" : "वापस जाएं",
  "TM_But95": "अभी भुगतान करें",
  "TM_Alt88": "कृपया अपना आवेदन जमा करने के लिए निम्नलिखित राशि का भुगतान करें |",
  "TM_Con32" : "आपका आवेदन सफलतापूर्वक जमा हो गया है",
  "TM_But110": "आवेदन",
  "TM_Txf16": "यहां खोजें",
  "TM_Hep69": "'योजना सहायता प्राप्त करें' पर क्लिक करें और एक संक्षिप्त सर्वेक्षण करें, जो आपको सही सहायता प्रदान करने में हमारी मदद करेगा।",
  "TM_But107": "योजना सहायता प्राप्त करें क्या है?",
  "TM_But106": "योजना सहायता प्राप्त करें",
  "TM_Pag32": "योजना जागरूकता सर्वेक्षण",
  "TM_Con14": "जानकारी साझा करने के लिए धन्यवाद।",
  "TM_Tag5": "रिक्त पद",
  "TM_Alt107": "दिन",
  "TM_Rec11":"क्या आप चयनित नोटिफिकेशन को हटाना चाहते हैं?",
  "TM_Mod7":"नोटिफिकेशन हटाएं",
  "TM_But25":"सभी का चयन करें",
  "TM_Mod1": "योजना हटाएं",
  "TM_Rec50": "क्या आप आवेदन वापस लेना चाहते हैं?",
  "TM_But23": "पुष्टि करें",
  "TM_But12": "रद्द करें",
  "TM_Con6" : "आपके पंजीकृत मोबाइल नंबर (xxxxxx{{phoneNumber}}) पर एक OTP भेजा गया है",
  "TM_But17": "जमा करें",
  "TM_But61": "OTP दोबारा भेजें",
  "TM_Con20": "आपका आवेदन सफलतापूर्वक वापस ले लिया गया है",
  "TM_But38": "सभी कार्यवाई",
  "TM_But62": "प्रतिक्रिया दें",
  "TM_Lab11": "आवेदन वापस लें",
  "TM_Err3" : "अमान्य OTP",
  "TM_Err13": "OTP समाप्त हो गया",
  "TM_Err14": "आपने ओटीपी जनरेट करने की अधिकतम सीमा पार कर ली है।  कृपया 3 घंटे बाद फिर से प्रयास करें।",
  "TM_But40": "कार्रवाई",
  "TM_Tag9": "जरूरी कार्रवाई",
  "TM_Tag10": "कोई कार्रवाई की जरूरत नहीं है",
  "TM_Tag8" : "स्थिति",
  "TM_But76" : "देखें",
  "TM_Alt87" : "ऑनलाइन उपलब्ध नहीं है",
  "TM_Pag34": "भुगतान का विवरण",
  "TM_Hea1": "कुल राशि",
  "TM_Hea2": "संक्षिप्त विवरण",
  "TM_Sub56": "योग",
  "TM_But42": "भुगतान करें",
  "TM_Lab18": "रसीद देखें",
  "TM_Err34": "भुगतान विफल होने के कारण आपका आवेदन जमा नहीं किया जा सका।",
  "TM_Rec55": "आपके भुगतान की स्थिति की पुष्टि करने में समय लग रहा है। हम आपके बैंक से लगातार संपर्क कर रहे हैं। कृपया अगले 48 घंटों में कोई अतिरिक्त भुगतान न करें।",
  "TM_But13": "वापस जाएं",
  "TM_Alt124":"कृपया प्रतीक्षा करें",
  "TM_Rec58": "रुकिए, हम आपके भुगतान की स्थिति की पुष्टि कर रहे हैं।",
  "TM_Rec59": "आपके पिछले भुगतान की स्थिति इस सेवा के लिए लंबित है।हम आपको इसके पूरा होने पर सूचित करेंगे। तब तक, आप बेझिझक हमारी अन्य सेवाओं के बारे में जान सकते है। धैर्य बनाएं रखने के लिए आपका धन्यवाद।",
  "TM_But133": "लिंक पर क्लिक करें"

}


export default language
