import axios from "axios";
import keys from '../../common/browserStorageKeys'
import storage from '../../utility/browserStorage'
import { getUserProfile } from "./getUserProfileAction";
import CryptoJS from "crypto-js";
import {HandleApiError} from "../../utility/handleApiError"
const getTranslatedValue = (translatedText, hash) => {
  let translatedObj = translatedText.find((item) => item.hashValue === hash);
  if (translatedObj) {
    return translatedObj.languageData;
  } else {
    return null;
  }
};

export const getUserFamilyProfile = (loader = true) => {
  let authToken = "";
  if (storage.getItemValue(keys.AUTHORIZATION_TOKEN)) {
    authToken = storage.getItemValue(keys.AUTHORIZATION_TOKEN);
  }

  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: authToken,
    },
  });
  let formData = {};
  if (storage.getItemValue(keys.LANGUAGE_OBJ)) {
    formData["locale"] = JSON.parse(
      storage.getItemValue(keys.LANGUAGE_OBJ)
    ).code;
  }
  return (dispatch) => {
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    instance
      .get("getUserFamilyProfile/", { params: formData })
      .then((response) => {
        if (response.status === 200) {
          let familyMembersList = response.data.data.familyMembersList;
          if (familyMembersList && familyMembersList.length > 0) {          
            storage.setItemValue(
              keys.FAMILY_MEMBERS_LIST,
              JSON.stringify(familyMembersList)
            );
            let currentActiveProfile = JSON.parse(
              storage.getItemValue(keys.ACTIVE_PROFILE)
            );
            if (currentActiveProfile && familyMembersList) {
              let activeProfileObj = familyMembersList.find(
                (item) =>
                  item.fullName.toLowerCase().trim() ===
                    currentActiveProfile.name.toLowerCase().trim() &&
                  item.relationship === currentActiveProfile.relation
              );
              if (activeProfileObj) {
                storage.setItemValue(
                  keys.AUTHORIZATION_TOKEN,
                  activeProfileObj.authToken
                );
                if (
                  currentActiveProfile
                ) {
                  currentActiveProfile["userIdTo"] = activeProfileObj.userId;
                  storage.setItemValue(
                    keys.ACTIVE_PROFILE,
                    JSON.stringify(currentActiveProfile)
                  );
                }
              }
            } else {
              let selectedProfile = familyMembersList[0];
              storage.setItemValue(
                keys.AUTHORIZATION_TOKEN,
                selectedProfile.authToken
              );
              let profileObj = {
                name: selectedProfile.fullName,
                relation: selectedProfile.relationship,
                userIdTo: selectedProfile.userId,
              };
              storage.setItemValue(
                keys.ACTIVE_PROFILE,
                JSON.stringify(profileObj)
              );
            }
          }

          if (
            JSON.parse(storage.getItemValue(keys.LANGUAGE_OBJ)).code !== "en"
          ) {
            let translatedText = response.data.translationMap;
            if (familyMembersList && familyMembersList.length > 0) {
              familyMembersList.map((member) => {
                if (
                  getTranslatedValue(
                    translatedText,
                    CryptoJS.MD5(
                      member.relationship.replace(/\s/g, "")
                    ).toString(CryptoJS.enc.Hex)
                  )
                ) {
                  member["untranslated_relationship"] = member.relationship;
                  member.relationship = getTranslatedValue(
                    translatedText,
                    CryptoJS.MD5(
                      member.relationship.replace(/\s/g, "")
                    ).toString(CryptoJS.enc.Hex)
                  );
                }
              });
            }
          }
          dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } })
          response.data.data.familyMembersList = familyMembersList;
          dispatch({
            type: "GET_USER_FAMILY_PROFILE",
            payload: response.data.data,
          }); 
          dispatch(getUserProfile(loader));
          }
        }
      )
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
        console.log(err);
        HandleApiError(err)
      });
  };
};
