var language = {
	"TM_Txf6": "దయచేసి మీరు ఇష్టపడే భాషను ఎంచుకోండి",
	"TM_But15": "కొనసాగండి",
	"TM_Pag19": "భాషను ఎంచుకోండి",
	"TM_But1": "ప్రారంభిద్దాం",
	"TM_Pag20": "స్థానాన్ని ఎంచుకోండి",
	"TM_Txf9": "మీ లొకేషన్‌ను అందించడం వలన మీ ప్రాంతంలో మీకు అర్హత ఉన్న ప్రయోజనాలను చూపడంలో మాకు సహాయపడుతుంది.",
	"TM_Txf3": "శోధన స్థితి",
	"TM_Txf4": "శోధన జిల్లా",
	"TM_But16": "తరువాత",
  "TM_But37":"అప్లికేషన్ చూడండి",
  "TM_Tag8":"స్థితి",
  "TM_Pag35": "అప్లికేషన్ స్థితి",
	"TM_Alt1": "3000+ సంక్షేమ పథకాలకు అర్హతను తనిఖీ చేయండి",
	"TM_Alt3": "మీకు సమీపంలోని ఉద్యోగ అవకాశాలను కనుగొనండి",
	"TM_Alt2": "మీరు దరఖాస్తు చేయాలనుకుంటున్న సేవలను తనిఖీ చేయండి",
	"TM_Lin1": "యాప్‌ని యాక్సెస్ చేయడం ద్వారా, మీరు EasyGov’sకు అంగీకరిస్తున్నారు",
	"TM_Alt5": "మీరు అర్హత పొందగల సంక్షేమ ప్రయోజనాలను కనుగొనడంలో మాకు సహాయపడటానికి ప్రొఫైల్‌ని సృష్టించండి",
	"TM_But2": "మీ ప్రొఫైల్‌ను సృష్టించండి",
	"TX_Sub1": "పథకం వర్గాలు",
	"TX_Sub2": "ఫ్లాగ్‌షిప్ పథకాలు",
	"TX_Sub3": "కొత్తగా చేర్చబడిన పథకాలు",
	"TX_Sub4": "జనాదరణ పొందిన పథకాలు",
	"TM_Pag1": "పథకం వర్గాలు",
	"TM_Alt6": "పథకాలు",
	"TM_Alt7": "సేవలు",
	"TM_Alt8": "ఉద్యోగాలు",
	"TM_Alt9": "వెతకండి",
	"TM_Alt10": "ప్రొఫైల్",
	"TX_Sub7": "சேவை వర్గాలు",
  "TX_Sub13":"నా సేవలు",
  "TM_Pag6": "నా సేవలు",
	"TX_Sub8": "అగ్ర రేటింగ్ పొందిన సేవలు",
	"TX_Sub9": "కొత్తగా చేర్చబడిన సేవలు",
	"TX_Sub10": "జనాదరణ పొందిన సేవలు",
	"TM_Pag18": "சேவை వర్గాలు",
	"TM_Alt16": "మీ కోసం అత్యంత అనుకూలమైన ఉద్యోగాలను కనుగొనడంలో మాకు సహాయపడటానికి ప్రొఫైల్‌ని సృష్టించండి",
	"TM_Alt17": "ఉద్యోగ వివరాలు",
  "TM_Alt18" : "ఉద్యోగ వివరణ",
  "TM_Alt19":"ఆంగ్ల", 
  "TM_Alt20":"హిందీ",
  "TM_Alt21":"తమిళం",
  "TM_Alt22":"కన్నడ",
  "TM_Alt23":"పంజాబీ",
  "TM_Alt24":"మలయాళం",
  "TM_Alt25":"బెంగాలీ",
  "TM_Alt26":"గుజరాతీ",
  "TM_Alt27":"తెలుగు",
  "TM_Alt28":"మరాఠీ",
  "TM_But3":"అన్నీ వీక్షించండి",
"TM_But5":"అర్హతను తనిఖీ చేయండి",
"TM_But10":"మరిన్ని ఉద్యోగాలను అన్వేషించండి",
"TX_Sub15":"టాప్ రేటింగ్ పొందిన ఉద్యోగాలు",
"TX_Sub16":"కొత్తగా చేర్చబడిన ఉద్యోగాలు",
"TX_Sub17":"జనాదరణ పొందిన ఉద్యోగాలు",
"TX_Sub18":"సిఫార్సు చేసిన ఉద్యోగాలు",
"TX_Sub20":"నా ఉద్యోగాలు",
"TM_alt11":"లాభాలు",
"TM_Alt13":"పత్రాలు",
"TM_Pag4":"పథకం వివరాలు",
"TM_Pag21": "పథకాలు",
"TM_Pag22": "సేవలు",
"TM_Pag23": "ఉద్యోగాలు",
"TM_Alt30" : "పథకాలు, సేవలు మరియు ఉద్యోగాల కోసం శోధించండి",
"TM_Lab6": "ఇటీవల శోధించారు",
"TM_Err4": "ఎటువంటి ఫలితాలు లభించలేదు",
"TM_Err12": "ఈ సేవ మీ స్థానంలో అందుబాటులో లేదు.",
"TM_Mod25":"భాష మారండి",
"TM_Rec33":"ఈ సేవ ఆంగ్ల భాషలో మాత్రమే అందుబాటులో ఉంది. యాప్ భాషను మార్చడానికి ప్రొసీడ్‌పై క్లిక్ చేయండి",
"TM_Pag12":"ఉద్యోగ వివరాలు",
"TM_Alt12":"అర్హత",
"TM_Tag1":"ఇప్పుడే లాంఛ్ చేయబడింది",
"TX_Sub25" : "తరచుగా అడగబడే ప్రశ్నలు",
"TM_Sup1": "మమ్మల్ని అనుసరించండి",
"TM_Hep17":"ఒకవేళ మీకు ఏదైనా క్వైరీ ఉన్నట్లయితే, మాకు తెలియజేయండి మరియు 48 పనిగంటల్లోగా మేం మీ వద్దకు తిరిగి వస్తాం.",
"TM_Lab4":"ఇమెయిల్",
"TM_Lin2":"నియమనిబంధనలు",
"TM_Lin3":"గోప్యతా విధానం",
"TM_But4":"వినియోగదారుని మార్చండి",
"TM_Pag15": "వినియోగదారుని మార్చండి",
"TM_Hep36":"మీరు అర్హతను వీక్షించడానికి లేదా మీ సేవ్ చేసిన డేటాను మరింత ప్రాసెస్ చేయడానికి మీకు కావలసిన ప్రొఫైల్‌ను ఎంచుకోవచ్చు లేదా జోడించవచ్చు.",
"TM_But18":"వినియోగదారుని జోడించండి",
"TM_Mod5": "మీరు ఖచ్చితంగా {{Name}} ఖాతాను శాశ్వతంగా తొలగించాలనుకుంటున్నారా?",
"TM_Rec7":"మీరు మీ EasyGov ఖాతాను తొలగించినప్పుడు, మీ ప్రొఫైల్‌లో మరియు మీ ఖాతాతో అనుబంధించబడిన ఇతర ప్రొఫైల్‌లలో నిల్వ చేయబడిన డేటా లేదా సమాచారాన్ని మీరు తిరిగి పొందలేరు. మీరు సిఫార్సు చేసిన మరియు సేవ్ చేసిన అన్ని పథకాలు కూడా తొలగించబడతాయి.",
"TM_Err2":"మీరు {{Name}} ప్రొఫైల్‌లో కొనసాగుతున్న అప్లికేషన్(లు) ఉన్నందున దాన్ని తొలగించలేరు.",
"TM_But11":"తొలగించు",
"TM_But12": "రద్దు చేయండి",
"TM_But30" : "అన్ని ఉద్యోగాలను వీక్షించండి",
"TM_But31" : "అన్ని పథకాలను వీక్షించండి",
"TM_Con11": "లోడ్",
"TM_The1":"సేవ్ చేయండి",
"TM_The2":"ముసాయిదా",
"TM_The3":"వర్తించబడింది",
"TM_The4":"నమోదు చేసుకున్నారు",
"TM_The5":"ప్రాసెస్ చేయబడింది",
"TM_Hep7":"సేవ్ చేసిన పథకాలు లేవు", 	
"TM_Hep21":"డ్రాఫ్ట్ పథకాలు లేవు",	
"TM_Hep22":"వర్తించే పథకాలు లేవు",
"TM_Hep23":"నమోదు చేయబడిన పథకాలు లేవు",	
"TM_But7":"మరిన్ని పథకాలను అన్వేషించండి",
"TM_Rec1": "మీరు మీ ‘నా పథకాలు’ నుండి ఈ పథకాన్ని ఖచ్చితంగా తొలగించాలనుకుంటున్నారా??",
"TM_Rec2":"మీరు మీ ‘నా సేవలు’ నుండి ఈ సేవను ఖచ్చితంగా తొలగించాలనుకుంటున్నారా?",
"TM_Pag2":"నా పథకాలు",
"TM_Hep10": "మీరు ప్రొఫైల్ నుండి మీ ప్రాధాన్య భాష మరియు స్థానాన్ని మార్చవచ్చు",
"TM_But21": "దొరికింది!",
"TM_Alt29":"భాష & స్థానం",
"TM_Hep38":"{{category}} వర్గంలో తగిన పథకాలను గుర్తించడంలో మాకు సహాయపడటానికి, దయచేసి {{category}}కి సంబంధించిన కొన్ని అదనపు ప్రశ్నలకు సమాధానం ఇవ్వండి",
"TM_Hep11":"మీ ప్రొఫైల్ ఆధారంగా, మీరు ఏ స్కీమ్‌కు అర్హులుగా మేము గుర్తించలేదు. మేము నిరంతరం మరిన్ని పథకాలను జోడిస్తున్నాము, దయచేసి తర్వాత తనిఖీ చేయండి.",
"TM_Hep39":"{{category}} వర్గంలో తగిన ఉద్యోగాలను గుర్తించడంలో మాకు సహాయపడటానికి, దయచేసి {{category}}కి సంబంధించిన కొన్ని అదనపు ప్రశ్నలకు సమాధానం ఇవ్వండి",
"TM_Rec6": "ప్రస్తుతం మీ రాష్ట్రంలో కాన్ఫిగర్ చేయబడిన ఉద్యోగాలు ఏవీ లేవు, మేము నిరంతరం అప్‌డేట్ చేస్తున్నాము, దయచేసి మాతో మళ్లీ తనిఖీ చేయండి",
"TM_But27": "అలాగే",
"TX_Sub5": "సిఫార్సు చేసిన పథకాలు",
"TM_Pag9": "ఉద్యోగ వర్గాలు",
"TM_Hep42": "మీ ప్రొఫైల్ ఆధారంగా, మీరు ఏ ఉద్యోగానికీ అర్హులని మేము గుర్తించలేదు. మేము నిరంతరం మరిన్ని ఉద్యోగాలను జోడిస్తున్నాము, దయచేసి తర్వాత తనిఖీ చేయండి.",
"TM_Hep40":"వర్తిస్తాయి" ,
"TM_Hep41":"గంటలు",
"TM_Hep2": "వర్గం",
"TM_Alt31": "సేవలో",
  "TM_Alt32" : "పథకంలో",
  "TM_Alt33" : "ఉద్యోగంలో",
  "TM_Pag14": "ప్రొఫైల్ సృష్టించండి",
  "TM_Hep6":"సేవ్ చేసిన సేవలు లేవు",
  "TM_Hep13":"డ్రాఫ్ట్ సేవలు లేవు.",
  "TM_Hep14":"వర్తించే సేవలు లేవు.",
  "TM_Hep54":"ఈ రోజు",
  "TM_Hep55":"నిన్న",
  "TM_Hep15":"నోటిఫికేషన్‌లు లేవు",
  "TM_Hep44":"ప్రాసెస్ చేయబడిన సేవలు లేవు",
  "TM_Hep9": "ప్రొఫైల్‌ను సృష్టించడం వలన మీకు ఆసక్తి ఉన్న అన్ని అవకాశాలను సేవ్ చేయడానికి మరియు మీ సేవ్ చేసిన డేటాను మరింత ప్రాసెస్ చేయడానికి తర్వాత ఎప్పుడైనా తిరిగి రావడానికి మిమ్మల్ని అనుమతిస్తుంది.",
  "TM_Txf1": "పూర్తి పేరు",
  "TM_Txf2": "సంబంధం",
  "TM_But29": "సేవ్ చేయండి",
  "TM_Err1": "అదే పేరు మరియు సంబంధం ఉన్న ప్రొఫైల్ ఇప్పటికే ఉంది!",
  "TM_Err6": "అక్షరాలు మాత్రమే అనుమతించబడతాయి",
  "TM_Pag16": "సంకే లాంగ్యూకే",
  "TM_Pag33": "స్థానాన్ని నవీకరించు",
  "TM_But22": " అప్ డేట్ చేయి ",
  "TM_But14": "ప్రొఫైల్ జోడించండి",
  "TM_Txf15": "పేరు జోడించు",
  "TM_But20":"సవరించు",
  "TM_Pag17": "స్థానం మార్చండి",
  "TM_Pag13": "ఉద్యోగ ప్రయోజనాలు",
  "TM_Hep16":"యాప్‌ని ఉపయోగిస్తున్నప్పుడు మీ అనుభవాన్ని మెరుగుపరచడానికి మరియు మీ కోసం ఖచ్చితమైన స్కీమ్‌లు/సేవలు/ఉద్యోగాలను ప్రదర్శించడానికి మీ సరైన స్థానాన్ని ఎంచుకోండి.",
  "TM_Pag26": "స్థానాన్ని నిర్ధారించండి",
  "TM_But23": "నిర్ధారించండి",
  "TX_Sub21":"నోటిఫికేషన్‌లు",
  "TX_Sub22":"ప్రొఫైల్‌ని నిర్వహించండి",
  "TX_Sub23":"స్థానం",
  "TX_Sub24":"భాష",
  "TX_Sub25":"తరచుగా అడిగే ప్రశ్నలు",
  "TX_Sub26":"మా గురించి",
  "TX_Sub27":"ఖాతాను తొలగించండి",
  "TX_Sub28":"వ్యక్తిగత సమాచారం",
  "TX_Sub29":"ఆర్థిక వివరాలు",
  "TX_Sub30":"హౌసింగ్ వివరాలు",
  "TX_Sub31":"విద్యా ప్రొఫైల్",
  "TX_Sub32":"వృత్తి ప్రొఫైల్",
  "TX_Sub33":"ఆరోగ్య ప్రొఫైల్",
  "TM_Txf13":"రాష్ట్రం ఎంచుకోండి",
  "TM_Txf14":"జిల్లాను ఎంచుకోండి",
  "TM_Pag29":"ప్రొఫైల్ సృష్టి",
  "TM_Con9":"క్షమించండి! మేము మీ కోసం సరిపోలే స్కీమ్‌లను కనుగొనలేకపోయాము",
  "TM_Con10":"క్షమించండి! మేము మీకు సరిపోయే ఉద్యోగాలను కనుగొనలేకపోయాము",
  "TM_But26": "హోమ్",
  "TM_Rec35": "వనరు అందుబాటులో లేదు",
  "TM_Rec36":"సర్వర్ సమయం ముగిసింది!",
  "TM_Rec37":"అనుమతి లేదు!",
  "TM_Mod28":"ఏదో తప్పు జరిగింది.",
  "TM_Rec10": "మేము నిరంతరం మెరుగుపరుస్తున్నాము, దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.",
  "TM_But24":"మళ్లీ ప్రయత్నించండి",
  "TM_Hep43":"మునుపటి ప్రశ్న కోసం కుడివైపు స్వైప్ చేయండి",
  "TM_Hep28":"మీ ప్రొఫైల్ ప్రకారం సంబంధిత స్కీమ్‌లను కనుగొనడానికి ప్రశ్నలకు సమాధానం ఇవ్వండి",
  "TM_Hep30":"మీ ప్రొఫైల్ ప్రకారం సంబంధిత ఉద్యోగాలను కనుగొనడానికి ప్రశ్నలకు సమాధానం ఇవ్వండి",
  "TM_Hep32": "మీరు దీనికి అర్హులు కావచ్చు",
  "TM_Hep35":"కొన్ని ప్రశ్నలకు సమాధానమివ్వండి",
  "TM_Rel1":"కుమార్తె",
  "TM_Rel2":"తండ్రి",
  "TM_Rel3":"తల్లి",
  "TM_Rel4":"కుమారుడు",
  "TM_Rel5":"సహోదరుడు",
  "TM_Rel6":"సహోదరి",
  "TM_Rel7":"జీవిత భాగస్వామి",
  "TM_Rel8":"ఇంకెవరో",
  "TM_Rel9": "స్వీయ",
  "TM_Mod4": "శ్రద్ధ",
  "TM_Mod2": "సేవలను తొలగించండి",
  "TM_Rec12": "మీరు డేటాను మార్చినట్లయితే, అదే మీ ప్రొఫైల్ డేటాలో కూడా నవీకరించబడుతుంది. మీరు కొనసాగించాలనుకుంటున్నారా?",
  "TM_Hep12": "మీరు గతంలో సేవ్ చేసిన డేటాను సమీక్షించి, నిర్ధారించండి.",
  "TM_But28": "కొనసాగించు",
  "TM_Pag24": "మీ వివరాలను సమీక్షించండి",
  "TM_Pag25": "మీ వివరాలను నవీకరించండి",
  "TM_Hep33":"మీరు బాగా చేస్తున్నారు! కొనసాగించండి....",
  "TM_Con15":"మేము వివరాలను పొందే వరకు దయచేసి వేచి ఉండండి",
  "TM_But9" : "మరిన్ని సేవలను అన్వేషించండి",
  "TM_Pag5":"పథకం వివరాలు",
  "TM_Pag8":"సర్వీస్ వివరాలు",
  "TM_Hep3": "సేవా స్థాయి",
  "TM_But6": "లింక్ను కాపీ చేయండి",
  "TM_Con1": "లింక్ కాపీ చేయబడింది",
  "TM_Con5": "డౌన్‌లోడ్ చేయబడింది",
  "TM_Alt14": "ఎలా దరఖాస్తు చేయాలి",
  "TM_Alt15": "సమాచారం",
  "TM_Con3": `ఈ పథకం మీ “నా స్కీమ్”లో సేవ్ చేయబడింది`,
  "TM_Con2": `ఈ సేవ మీ “నా సేవ”లో సేవ్ చేయబడింది`,
  "TM_Con4": `ఈ జాబ్ మీ “నా జాబ్”లో సేవ్ చేయబడింది`,
  "TM_Alt34":"దరఖాస్తు చేసుకునే విధానం",
  "TM_Alt35":"అప్లికేషన్ లింక్",
  "TM_Alt36":"డౌన్‌లోడ్ ఫారమ్",
  "TM_Alt38":"ఎంపిక విధానం",
  "TM_Alt41":"పథకం ప్రయోజనాలు",
  "TM_Alt56":"పోస్ట్",
  "TM_Alt57":"జీతం",
  "TM_Alt58":"ఉద్యోగ వివరాలు",
  "TM_Alt59":"ఉద్యోగ వివరాలు",
  "TM_Txf12": "కారణం",
  "TM_Hep51": "అర్హతగల విలువలు",
  "TM_Hep52": "మీరు అందించిన విలువ",
  "TM_Con7" : "క్షమించండి, మీరు ఈ స్కీమ్‌కు అర్హులు కాదు",
  "TM_Con8" : "క్షమించండి, మీరు ఈ ఉద్యోగానికి అర్హులు కాదు",
  "TM_Err5" : "ఎంపికలు లేవు",
  "TM_Con13" : "మీ డేటా విజయవంతంగా సేవ్ చేయబడింది.",

  "TM_Hep8" : "సేవ్ చేసిన ఉద్యోగాలు లేవు",
  "TM_Pag10": "నా ఉద్యోగాలు",
  "TM_Mod3" : "ఉద్యోగాన్ని తొలగించండి",
  "TM_Rec3" : "మీరు ఖచ్చితంగా మీ ‘నా ఉద్యోగాలు’ నుండి ఈ జాబ్‌ని తొలగించాలనుకుంటున్నారా?",
  "TM_Hep24": "డ్రాఫ్ట్ ఉద్యోగాలు లేవు",
  "TM_Hep25": "దరఖాస్తు చేసిన ఉద్యోగాలు లేవు",
  "TM_Hep26": "నమోదు చేసుకున్న ఉద్యోగాలు లేవు",
  "TM_Hep27": 'గడువు ముగిసిన తేదీ నుండి 30 రోజుల వ్యవధి తర్వాత మీ "నా ఉద్యోగాలు" నుండి గడువు ముగిసిన అన్ని ఉద్యోగాలు తీసివేయబడతాయి.',
  "TM_Tag3" : "గడువు ముగిసింది",
  "TM_Tag4" : "ద్వారా దరఖాస్తు చేసుకోండి",
  "TM_Rec5":"మీ డేటా మొత్తం తొలగించబడింది",
  "TM_Rec4": "మీ ఖాతాను తొలగించడానికి మీరు ముందుగా మీ ఖాతాతో అనుబంధించబడిన అన్ని ప్రొఫైల్‌లను తొలగించాలి",
  "TM_Pag41": "అప్లికేషన్ వివరాలు",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "మీరు ఖచ్చితంగా ఈ పత్రాన్ని తీసివేయాలనుకుంటున్నారా?",
  "TM_Mod18": "గ్యాలరీ",
  "TM_Mod17": "కెమెరా",
  "91412465ea9169dfd901dd5e7c96dd99": "అప్‌లోడ్",
  "af6ebe0c0e89201cb2294f32955f7b15": "ఒకేసారి 20 పత్రాలు మాత్రమే అనుమతించబడతాయి .",
  "d5de543c0e83073f05a846d62cc83c81": "అదే ఫీల్డ్ కోసం ఇప్పటికే పత్రం అప్‌లోడ్ చేయబడింది.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "చెల్లిని నమోదు చేయండి",
  "3f68e67dc6c397aaa9d1c24c356f754f": "ధృవీకరించబడింది",
  "1e410e1b9903e7a99b93857f536b7e1d": "ధృవీకరించబడలేదు",
  "f8d855b26faffa0f8779fb44632d6bc1": "చెల్లని క్యాప్చా కోడ్",
  "d53b4d564b9054db9b4b4a50e713197f": "దయచేసి ఇక్కడ క్యాప్చాను నమోదు చేయండి",
  "TM_Con18": "మీ అప్లికేషన్ విజయవంతంగా మీ 'డ్రాఫ్ట్ స్కీమ్'లో సేవ్ చేయబడింది",
  "TM_Con24": "మీ అప్లికేషన్ విజయవంతంగా మీ 'డ్రాఫ్ట్ జాబ్'లో సేవ్ చేయబడింది",
  "TM_Con23": `మీ అప్లికేషన్ విజయవంతంగా మీ "డ్రాఫ్ట్ సర్వీసెస్"లో సేవ్ చేయబడింది`,
  "6cf24d7a07132fe88b610e2b3d3def28": "స్వీయ ప్రకటన",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "ఈ ఫీల్డ్ అవసరం.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "దయచేసి అక్షరాలు, సంఖ్యలు, కామా, -, _, / మాత్రమే నమోదు చేయండి",
  "562d3d08919ae105c3ebc42d9607d266": "దయచేసి అక్షరాలు లేదా సంఖ్యలను మాత్రమే నమోదు చేయండి",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "వర్ణమాలలు మాత్రమే అనుమతించబడతాయి.",
  "4906d981bb592b32f6982fb4187c7b60": "చెల్లుబాటు అయ్యే ఐఎఫ్ఎస్ సి కోడ్‌ని నమోదు చేయండి.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "కేవలం 11 అక్షరాలు మాత్రమే అనుమతించబడతాయి.",
  "7c58cde3b827bc7d57cbf77865046169": "దయచేసి నంబర్‌లను మాత్రమే నమోదు చేయండి",
  "544b0264d55a1e4cf476b279240d9be4": "పది అంకెలు మాత్రమే అనుమతించబడతాయి. దయచేసి 0 లేదా +91 ఉపసర్గ చేయవద్దు",
  "73f0d980e48b8141e0776e02d96a872f": "పన్నెండు అంకెలు మాత్రమే అనుమతించబడతాయి.",
  "0ce98bd973419ff33921a2275e7903b9": "దయచేసి చెల్లుబాటు అయ్యే పాన్ నంబర్‌ని నమోదు చేయండి.",
  "e55e425dbaf6b6449940eac8ea749092": "దయచేసి అక్షరాలను మాత్రమే నమోదు చేయండి.",
  "64ff9e3809686d4ea3253a631cae4761": "దయచేసి 2 దశాంశ స్థానాలకు సరైన విలువను నమోదు చేయండి.",
  "7ed0ef23d527c2876015161e422f49ce": "దయచేసి మీ పేరును కన్నడ భాషలో నమోదు చేయండి.",
  "230486b5dd7afed96556ba9bd4175423": "పిన్ కోడ్ తప్పు",
  "9f44bd93c8988c682d5ef5369fd11f47": "దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్‌ని నమోదు చేయండి",
  "ac598199ea0dd028ade4677a435caf4b": "చెల్లుబాటు అయ్యే ఆధార్ నంబర్‌ను నమోదు చేయండి",
  "10803b83a68db8f7e7a33e3b41e184d0": "పుట్టిన తేదీ",
  "d0de720ccdffea8490dfd2893c50ebc5": "ఇది {{displayName}} కంటే ఎక్కువగా ఉండాలి",
  "ced6e171e13ebd5c37415c822706b9d4": "ఇది {{displayName}} కంటే తక్కువగా ఉండాలి",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "ఇది {{displayName}}కి సమానంగా ఉండాలి",
  "9483135517d156229d0439dff74b1ca0": "ఇది {{displayName}} కంటే ఎక్కువగా లేదా సమానంగా ఉండాలి",
  "01e9cd748e852b1ae389b980bff195b0": "ఇది {{displayName}} కంటే తక్కువగా లేదా సమానంగా ఉండాలి",
  "TM_Pag37": "రివ్యూ వివరాలు",
  "TM_Rec18": "మీ పూర్తి వివరాలను మార్చడానికి 'సవరించు'పై క్లిక్ చేయండి.",
  "TM_Alt51": "జోడింపు(లు):",
  "TM_Alt42": "ధన్యవాదాలు",
  "TM_But9":"మరిన్ని సేవలను అన్వేషించండి",
  "TM_But19":"డౌన్‌లోడ్",
  "TM_Con17":"మీ అప్లికేషన్ డౌన్‌లోడ్ చేయబడింది.",
  "TM_Sub48": "సహాయం మరియు మద్దతు",
  "TM_Alt66": "<b>jioeasygov@easygov.co.in</b>లో మాకు ఇమెయిల్ పంపండి మరియు మా బృందం త్వరలో మీకు తిరిగి వస్తుంది.",
  "TM_Alt65": "ఏదైనా ప్రశ్నకు సహాయం కావాలా?",
  "TM_Pag54": "సేవా ప్రయోజనాలు",
  "TM_Alt37": "ప్రభుత్వ రుసుము",
  "TM_But105" : "మరింత అన్వేషించండి",
  "TM_But17": "సమర్పించండి",
  "TM_Alt39": "సమర్పణ చిరునామా",
  "TM_But58": "అప్లై చేయడానికి కొనసాగండి",
  "TM_But100" : "వెనక్కి వెళ్ళు",
  "TM_But95": "ఇప్పుడు చెల్లించండి",
  "TM_Alt88": "దయచేసి మీ దరఖాస్తును సమర్పించడానికి క్రింది మొత్తాన్ని చెల్లించండి",
  "TM_Con32" : "మీ దరఖాస్తు విజయవంతంగా సమర్పించబడింది",
  "TM_But110": "అప్లికేషన్",
  "TM_Txf16": "ఇక్కడ వెతకండి",
  "TM_Hep69": "‘స్కీమ్ సపోర్ట్ పొందండి’పై క్లిక్ చేసి, చిన్న సర్వేలో పాల్గొనండి, ఇది మీకు సరైన మద్దతును అందించడంలో మాకు సహాయపడుతుంది.",
  "TM_But107": "స్కీమ్ సపోర్ట్ పొందడం అంటే ఏమిటి?",
  "TM_But106": "పథకం మద్దతు పొందండి",
  "TM_Pag32": "పథకం అవగాహన సర్వే",
  "TM_Con14": "వివరాలను పంచుకున్నందుకు ధన్యవాదాలు.",
  "TM_Tag5": "ఖాళీ స్థానం",
  "TM_Alt107": "రోజులు",
  "TM_Rec11":"మీరు ఎంచుకున్న నోటిఫికేషన్‌లను ఖచ్చితంగా తొలగించాలనుకుంటున్నారా?",
  "TM_Mod7":"నోటిఫికేషన్‌లను తొలగించండి",
  "TM_But25":"అన్ని ఎంచుకోండి",
  "TM_Mod1": "పథకాన్ని తొలగించండి",
  "TM_Rec50": "మీరు ఖచ్చితంగా మీ దరఖాస్తును ఉపసంహరించుకోవాలనుకుంటున్నారా?",
  "TM_But23": "నిర్ధారించండి",
  "TM_But12": "రద్దు చేయండి",
  "TM_Con6" : "మీ రిజిస్టర్డ్ మొబైల్ నంబర్ (xxxxxx{{phoneNumber}})కి OTP పంపబడింది",
  "TM_But17": "సమర్పించండి",
  "TM_But61": "OTP ను మళ్ళీ పంపు",
  "TM_Con20": "మీ దరఖాస్తు విజయవంతంగా ఉపసంహరించబడింది",
  "TM_But38": "పూర్తి చర్యలు",
  "TM_But62": "అభిప్రాయం తెలియజేయండి",
  "TM_Lab11": "దరఖాస్తును ఉపసంహరించుకోండి",
  "TM_Err3" : "చెల్లని OTP",
  "TM_Err13": "OTP గడువు ముగిసింది",
  "TM_Err14": "మీరు OTPని రూపొందించడానికి గరిష్ట పరిమితిని మించిపోయారు.  దయచేసి 3 గంటల తర్వాత మళ్లీ ప్రయత్నించండి.",
  "TM_But40": "చర్యలు",
  "TM_Tag9":  "అవసరమైన చర్యలు",
  "TM_Tag10": "చర్య అవసరం లేదు",
  "TM_Tag8" : "స్థితి",
  "TM_But76" : "చూడండిw",
  "TM_Alt87" : "ఆన్‌లైన్‌లో అందుబాటులో లేదు",
  "TM_Pag34": "చెల్లింపు వివరాలు",
  "TM_Hea1": "మొత్తం మొత్తం",
  "TM_Hea2": "సారాంశం",
  "TM_Sub56": "మొత్తం",
  "TM_But42": "చెల్లించు",
  "TM_Lab18": "రసీదుని వీక్షించండి",
  "TM_Err34": "చెల్లింపు వైఫల్యం కారణంగా మీ దరఖాస్తును సమర్పించడం సాధ్యం కాలేదు.",
  "TM_Rec55": "మీ చెల్లింపు స్థితిని నిర్ధారించడానికి సమయం తీసుకుంటోంది. మేము మీ బ్యాంక్‌తో నిరంతరం తనిఖీ చేస్తున్నాము. దయచేసి తదుపరి 48 గంటల పాటు ఎటువంటి అదనపు చెల్లింపులు చేయకుండా ఉండండి.",
  "TM_But13": "వెనక్కి వెళ్ళు",
  "TM_Alt124":"దయచేసి వేచి ఉండండి",
  "TM_Rec58": "ఆగండి.మేము మీ చెల్లింపు స్థితిని ధృవీకరిస్తున్నాము",
  "TM_Rec59": "ఈ సేవ కోసం మీ చివరి చెల్లింపు పెండింగ్‌లో ఉంది. ఇది పూర్తయినప్పుడు మేము మీకు తెలియజేస్తాము. ఈలోగా, మా ఇతర సేవలను అన్వేషించడానికి సంకోచించకండి. మీ ఓర్పుకు నా ధన్యవాదములు.",
  "TM_But133": "లింక్‌పై క్లిక్ చేయండి"

}

export default language
