var language = {
	"TM_Txf6": "നിങ്ങൾക്ക് ഇഷ്ടപ്പെട്ട ഭാഷ തിരഞ്ഞെടുക്കുക",
	"TM_But15": "തുടരുക",
	"TM_Pag17": "സ്ഥാനം വ്യത്യാസപ്പെടുത്തുക",
  "TM_Pag13": "ജോലി ആനുകൂല്യങ്ങൾ",
	"TM_Pag19": "ഭാഷ തിരഞ്ഞെടുക്കുക",
	"TM_But1": "നമുക്ക് തുടങ്ങാം",
	"TM_Pag20": "സ്ഥലം തിരഞ്ഞെടുക്കുക",
	"TM_Txf9": "നിങ്ങളുടെ ലൊക്കേഷൻ നൽകുന്നത് നിങ്ങളുടെ പ്രദേശത്ത് നിങ്ങൾക്ക് അർഹതയുള്ള ആനുകൂല്യങ്ങൾ കാണിക്കാൻ ഞങ്ങളെ സഹായിക്കും.",
	"TM_Txf3": "തിരയൽ അവസ്ഥ",
	"TM_Txf4": "തിരയൽ ജില്ല",
  "TM_But37":"ആപ്ലിക്കേഷൻ കാണുക",
  "TM_Tag8":"പദവി",
  "TM_Pag35": "അപേക്ഷാ നില",
	"TM_Alt1": "3000+ ക്ഷേമ പദ്ധതികൾക്കുള്ള യോഗ്യത പരിശോധിക്കുക",
	"TM_Alt3": "നിങ്ങളുടെ അടുത്തുള്ള തൊഴിൽ അവസരങ്ങൾ കണ്ടെത്തുക",
	"TM_Alt2": "നിങ്ങൾ അപേക്ഷിക്കാൻ ആഗ്രഹിക്കുന്ന സേവനങ്ങൾ പരിശോധിക്കുക",
	"TM_Lin1": "ആപ്പ് ആക്‌സസ് ചെയ്യുന്നതിലൂടെ, നിങ്ങൾ EasyGov’s അംഗീകരിക്കുന്നു",
	"TM_Alt5": "നിങ്ങൾക്ക് അർഹതപ്പെട്ടേക്കാവുന്ന ക്ഷേമ ആനുകൂല്യങ്ങൾ കണ്ടെത്താൻ ഞങ്ങളെ സഹായിക്കുന്നതിന് പ്രൊഫൈൽ സൃഷ്‌ടിക്കുക",
	"TM_But2": "നിങ്ങളുടെ പ്രൊഫൈൽ സൃഷ്ടിക്കുക",
	"TX_Sub1": "സ്കീം വിഭാഗങ്ങൾ",
	"TX_Sub2": "മുൻനിര സ്കീമുകൾ",
	"TX_Sub3": "പുതുതായി ചേർത്ത സ്കീമുകൾ",
	"TX_Sub4": "ജനപ്രിയ സ്കീമുകൾ",
	"TM_Pag1": "സ്കീം വിഭാഗങ്ങൾ",
	"TM_Alt6": "സ്കീമുകൾ",
	"TM_Alt7": "സേവനങ്ങള്",
	"TM_Alt8": "ജോലികൾ",
	"TM_Alt9": "തിരയുക",
	"TM_Alt10": "പ്രൊഫൈൽ",
	"TX_Sub7": "സേവനം വിഭാഗങ്ങൾ",
	"TX_Sub8": "മികച്ച റേറ്റുചെയ്ത സേവനങ്ങൾ",
	"TX_Sub9": "പുതുതായി ചേർത്ത സേവനങ്ങൾ",
	"TX_Sub10": "ജനപ്രിയ സേവനങ്ങൾ",
  "TX_Sub13":"എന്‍റെ സേവനങ്ങള്‍",
  "TM_Pag6": "എന്‍റെ സേവനങ്ങള്‍",
	"TM_Pag18": "സേവനം വിഭാഗങ്ങൾ",
	"TM_Alt16": "നിങ്ങൾക്ക് ഏറ്റവും അനുയോജ്യമായ ജോലികൾ കണ്ടെത്താൻ ഞങ്ങളെ സഹായിക്കുന്നതിന് പ്രൊഫൈൽ സൃഷ്‌ടിക്കുക",
	"TM_Alt17": "ജോലിയുടെ വിശദാംശങ്ങൾ",
  "TM_Alt18" : "ജോലി വിവരണം",
  "TM_Alt19":"ഇംഗ്ലീഷ്", 
  "TM_Alt20":"ഹിന്ദി",
  "TM_Alt21":"തമിഴ്",
  "TM_Alt22":"കന്നഡ",
  "TM_Alt23":"പഞ്ചാബി",
  "TM_Alt24":"മലയാളം",
  "TM_Alt25":"ബംഗാളി",
  "TM_Alt26":"ഗുജറാത്തി",
  "TM_Alt27":"തെലുങ്ക്",
  "TM_Alt28":"മറാത്തി",
  "TM_But3":"എല്ലാം കാണുക",
"TM_But5":"യോഗ്യത പരിശോധിക്കുക",
"TM_But10":"കൂടുതൽ ജോലികൾ പര്യവേക്ഷണം ചെയ്യുക",
"TX_Sub15":"മികച്ച റേറ്റുചെയ്ത ജോലികൾ",
"TX_Sub16":"പുതുതായി ചേർത്ത ജോലികൾ",
"TX_Sub17":"ജനപ്രിയ ജോലികൾ",
"TX_Sub18":"ശുപാർശ ചെയ്യുന്ന ജോലികൾ",
"TX_Sub20":"എന്റെ ജോലികൾ",
"TM_alt11":"ആനുകൂല്യങ്ങൾ",
"TM_Alt13":"പ്രമാണങ്ങൾ",
"TM_Pag4":"സ്കീം വിശദാംശങ്ങൾ",
"TM_Pag21": "സ്കീമുകൾ",
"TM_Pag22": "സേവനങ്ങള്",
    "TM_Pag23": "ജോലികൾ",
    "TM_Alt30" : "സ്കീമുകൾ, സേവനങ്ങൾ, ജോലികൾ എന്നിവയ്ക്കായി തിരയുക",
    "TM_Lab6": "അടുത്തിടെ തിരഞ്ഞത്",
    "TM_Err4": "ഒരു ഫലവും കണ്ടെത്താനായില്ല",
	"TM_Pag12": "ജോലിയുടെ വിശദാംശങ്ങൾ",
	"TM_Alt12":"യോഗ്യത",
"TM_Tag1":"ഇപ്പോൾ ലോഞ്ച് ചെയ്തു",
"TM_Err12": "നിങ്ങളുടെ ലൊക്കേഷനിൽ ഈ സേവനം ലഭ്യമല്ല.",
"TM_Mod25":"ഭാഷ മാറുക",
"TM_Rec33":"ഈ സേവനം ഇംഗ്ലീഷ് ഭാഷയിൽ മാത്രമേ ലഭ്യമാകൂ. ആപ്പിന്റെ ഭാഷ മാറ്റാൻ മുന്നോട്ട് ക്ലിക്ക് ചെയ്യുക",
"TX_Sub25" : "ആവര്‍ത്തിച്ച് ഉന്നയിക്കപ്പെടുന്ന ചോദ്യങ്ങള്‍",
"TM_Sup1": "ഞങ്ങളെ പിന്തുടരുക",
"TM_Hep17":"നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യങ്ങൾ ഉണ്ടെങ്കിൽ ഞങ്ങളെ അറിയിക്കുക, 48 ബിസിനസ്സ് മണിക്കൂറിനുള്ളിൽ ഞങ്ങൾ നിങ്ങളെ സമീപിക്കും.",
"TM_Lab4":"ഇമെയിൽ",
"TM_Lin3":"സ്വകാര്യതാ നയം",
"TM_But4":"ഉപയോക്താവിനെ മാറ്റുക",
"TM_Pag15": "ഉപയോക്താവിനെ മാറ്റുക",
"TM_Hep36":"യോഗ്യത കാണാനോ നിങ്ങളുടെ സംരക്ഷിച്ച ഡാറ്റ കൂടുതൽ പ്രോസസ്സ് ചെയ്യാനോ നിങ്ങൾക്ക് ആവശ്യമുള്ള പ്രൊഫൈൽ തിരഞ്ഞെടുക്കാനോ ചേർക്കാനോ കഴിയും.",
"TM_But18":"ഉപയോക്താവിനെ ചേർക്കുക",
"TM_Mod5": "{{Name}} അക്കൗണ്ട് ശാശ്വതമായി ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
"TM_Rec7":"നിങ്ങളുടെ EasyGov അക്കൗണ്ട് ഇല്ലാതാക്കുമ്പോൾ, നിങ്ങളുടെ പ്രൊഫൈലിലും നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട മറ്റ് പ്രൊഫൈലുകളിലും സംഭരിച്ചിരിക്കുന്ന ഡാറ്റയോ വിവരങ്ങളോ വീണ്ടെടുക്കാൻ നിങ്ങൾക്ക് കഴിയില്ല. നിങ്ങളുടെ ശുപാർശ ചെയ്തതും സംരക്ഷിച്ചതുമായ എല്ലാ സ്കീമുകളും ഇല്ലാതാക്കപ്പെടും.",
"TM_Err2":"നിങ്ങൾക്ക് {{Name}} പ്രൊഫൈൽ ഇല്ലാതാക്കാൻ കഴിയില്ല, കാരണം അതിന് നിലവിലുള്ള ഒരു ആപ്ലിക്കേഷൻ(കൾ) ഉണ്ട്.",
"TM_But11":"ഇല്ലാതാക്കുക",
"TM_But12": "റദ്ദാക്കുക",
"TM_But30" : "എല്ലാ ജോലികളും കാണുക",
"TM_But31" : "എല്ലാ സ്കീമുകളും കാണുക",
"TM_Con11": "ലോഡിംഗ്",
"TM_The1":"സംരക്ഷിക്കുക",
"TM_The2":"ഡ്രാഫ്റ്റ്‌",
"TM_The3":"പ്രയോഗിച്ചു",
"TM_The4":"എൻറോൾ ചെയ്തു",
"TM_The5": "പ്രോസസ്സ്ഡ്",
"TM_Hep7":"സംരക്ഷിച്ച സ്കീമുകളൊന്നുമില്ല", 	
"TM_Hep21":"ഡ്രാഫ്റ്റ് സ്കീമുകളൊന്നുമില്ല",	
"TM_Hep22":"പ്രയോഗിച്ച സ്കീമുകളൊന്നുമില്ല",
"TM_Hep23":"എൻറോൾ ചെയ്ത സ്കീമുകളൊന്നുമില്ല",	
"TM_But7":"കൂടുതൽ സ്കീമുകൾ പര്യവേക്ഷണം ചെയ്യുക",
"TM_Rec1": "നിങ്ങളുടെ 'എന്റെ സ്കീമുകളിൽ' നിന്ന് ഈ സ്കീം ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
"TM_Rec2":"നിങ്ങളുടെ 'എന്റെ സേവനങ്ങളിൽ' നിന്ന് ഈ സേവനം ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
"TM_Pag2":"എന്റെ സ്കീമുകൾ",
"TM_Hep10": "പ്രൊഫൈലിൽ നിന്ന് നിങ്ങൾക്ക് ഇഷ്ടപ്പെട്ട ഭാഷയും സ്ഥാനവും മാറ്റാം",
"TM_But21": "മനസ്സിലായി!",
"TM_Alt29":"ഭാഷയും സ്ഥാനവും",
"TM_Hep38":"{{category}} വിഭാഗത്തിൽ അനുയോജ്യമായ സ്കീമുകൾ തിരിച്ചറിയാൻ ഞങ്ങളെ സഹായിക്കുന്നതിന്, {{category}} എന്നതുമായി ബന്ധപ്പെട്ട ചില അധിക ചോദ്യങ്ങൾക്ക് ദയവായി ഉത്തരം നൽകുക",
"TM_Hep11":"നിങ്ങളുടെ പ്രൊഫൈലിനെ അടിസ്ഥാനമാക്കി, ഒരു സ്കീമിനും നിങ്ങൾ യോഗ്യനാണെന്ന് ഞങ്ങൾ കണ്ടെത്തിയില്ല. ഞങ്ങൾ കൂടുതൽ സ്കീമുകൾ തുടർച്ചയായി ചേർക്കുന്നു, ദയവായി പിന്നീട് പരിശോധിക്കുക.",
"TM_Hep39":"{{category}} വിഭാഗത്തിലെ അനുയോജ്യമായ ജോലികൾ തിരിച്ചറിയാൻ ഞങ്ങളെ സഹായിക്കുന്നതിന്, {{category}} എന്നതുമായി ബന്ധപ്പെട്ട ചില അധിക ചോദ്യങ്ങൾക്ക് ദയവായി ഉത്തരം നൽകുക",
"TM_Rec6": "ഇപ്പോൾ നിങ്ങളുടെ സംസ്ഥാനത്ത് കോൺഫിഗർ ചെയ്‌ത ജോലികളൊന്നുമില്ല, ഞങ്ങൾ തുടർച്ചയായി അപ്‌ഡേറ്റ് ചെയ്യുകയാണ്, ദയവായി ഞങ്ങളുമായി വീണ്ടും പരിശോധിക്കുക.",
"TM_But27": "ശരി",
"TX_Sub5": "ശുപാർശ ചെയ്യുന്ന സ്കീമുകൾ",
"TM_Pag9": "ജോലി വിഭാഗങ്ങൾ",
"TM_Hep42": "നിങ്ങളുടെ പ്രൊഫൈലിനെ അടിസ്ഥാനമാക്കി, ഒരു ജോലിക്കും നിങ്ങൾ യോഗ്യനാണെന്ന് ഞങ്ങൾ കണ്ടെത്തിയില്ല. ഞങ്ങൾ തുടർച്ചയായി കൂടുതൽ ജോലികൾ ചേർക്കുന്നു, ദയവായി പിന്നീട് പരിശോധിക്കുക.",
"TM_Hep40":"അപേക്ഷിക്കുക" ,
"TM_Hep41":"മണിക്കൂറുകൾ",
"TM_Hep2": "വിഭാഗം",
"TM_Alt31": "ജോലിയിൽ",
  "TM_Alt32" : "സ്കീമിൽ",
  "TM_Alt33" : "ജോലിയിൽ",
  "TM_Pag14": "പ്രൊഫൈൽ സൃഷ്ടിക്കുക",
  "TM_Hep6":"സംരക്ഷിച്ച സേവനങ്ങളൊന്നുമില്ല",
  "TM_Hep13":"ഡ്രാഫ്റ്റ് സേവനങ്ങളൊന്നുമില്ല.",
  "TM_Hep14":"ബാധകമായ സേവനങ്ങളൊന്നുമില്ല.",
  "TM_Hep15":"അറിയിപ്പുകളൊന്നുമില്ല",
  "TM_Hep54":"ഇന്ന്",
  "TM_Hep55":"ഇന്നലെ",
  "TM_Hep44":"പ്രോസസ്സ് ചെയ്ത സേവനങ്ങളൊന്നുമില്ല",
  "TM_Hep9": "ഒരു പ്രൊഫൈൽ സൃഷ്‌ടിക്കുന്നത് നിങ്ങൾക്ക് താൽപ്പര്യമുള്ള എല്ലാ അവസരങ്ങളും സംരക്ഷിക്കാനും നിങ്ങളുടെ സംരക്ഷിച്ച ഡാറ്റ കൂടുതൽ പ്രോസസ്സ് ചെയ്യുന്നതിന് പിന്നീട് എപ്പോൾ വേണമെങ്കിലും തിരികെ വരാനും നിങ്ങളെ അനുവദിക്കുന്നു.",
  "TM_Txf1": "പൂർണ്ണമായ പേര്",
  "TM_Txf2": "ബന്ധം",
  "TM_But29": "രക്ഷിക്കും",
  "TM_Err1": "അതേ പേരും ബന്ധവുമുള്ള പ്രൊഫൈൽ ഇതിനകം നിലവിലുണ്ട്!",
  "TM_Err6": "കഥാപാത്രങ്ങൾ മാത്രമേ അനുവദിക്കൂ",
  "TM_Pag16": "ഭാഷ മാറ്റുക",
  "TM_Pag33": "ലൊക്കേഷൻ അപ് ഡേറ്റ് ചെയ്യുക",
  "TM_But22": " അപ് ഡേറ്റ് ചെയ്യുക ",
  "TM_But14": "പ്രൊഫൈൽ ചേർക്കുക",
  "TM_Txf15": "പേര് ചേർക്കുക",
  "TM_But20":"എഡിറ്റ് ചെയ്യുക",
  "TM_Pag29":"പ്രൊഫൈൽ സൃഷ്ടിക്കൽ",
  "TM_Con9":"ക്ഷമിക്കണം! നിങ്ങൾക്കായി പൊരുത്തപ്പെടുന്ന സ്കീമുകളൊന്നും കണ്ടെത്താൻ ഞങ്ങൾക്ക് കഴിഞ്ഞില്ല",
  "TM_Con10":"ക്ഷമിക്കണം! നിങ്ങൾക്ക് അനുയോജ്യമായ ജോലികളൊന്നും കണ്ടെത്താൻ ഞങ്ങൾക്ക് കഴിഞ്ഞില്ല",
  "TM_But26": "വീട്",
  "TM_Rec35": "ഉറവിടം ലഭ്യമല്ല",
  "TM_Rec36":"സെർവർ സമയം കഴിഞ്ഞു!",
  "TM_Rec37":"പ്രവേശനം തടയപ്പെട്ടു!",
  "TM_Mod28":"എന്തോ കുഴപ്പം സംഭവിച്ചു.",
  "TM_Rec10": "ഞങ്ങൾ തുടർച്ചയായി മെച്ചപ്പെടുത്തിക്കൊണ്ടിരിക്കുകയാണ്, ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക.",
  "TM_But24":"വീണ്ടും ശ്രമിക്കുക",
  "TM_Hep16":"ആപ്പ് ഉപയോഗിക്കുമ്പോൾ നിങ്ങളുടെ അനുഭവം മെച്ചപ്പെടുത്തുന്നതിനും കൃത്യമായ സ്കീമുകൾ/സേവനങ്ങൾ/ജോലികൾ എന്നിവ പ്രദർശിപ്പിക്കുന്നതിനും നിങ്ങളുടെ ശരിയായ ലൊക്കേഷൻ തിരഞ്ഞെടുക്കുക.",
  "TM_Pag26": "ലൊക്കേഷൻ സ്ഥിരീകരിക്കുക",
  "TM_But23": "സ്ഥിരീകരിക്കുക",
  "TX_Sub21":"അറിയിപ്പുകൾ",
  "TX_Sub22":"പ്രൊഫൈൽ കൈകാര്യം ചെയ്യുക",
  "TX_Sub23":"സ്ഥാനം",
  "TX_Sub24":"ഭാഷ",
  "TX_Sub25":"പതിവായി ചോദിക്കുന്ന ചോദ്യങ്ങൾ",
  "TX_Sub26":"ഞങ്ങളേക്കുറിച്ച്",
  "TX_Sub27":"അക്കൗണ്ട് ഇല്ലാതാക്കുക",
  "TX_Sub28":"വ്യക്തിഗത വിശദാംശങ്ങൾ",
  "TX_Sub29":"സാമ്പത്തിക വിശദാംശങ്ങൾ",
  "TX_Sub30":"ഭവന വിശദാംശങ്ങൾ",
  "TX_Sub31":"വിദ്യാഭ്യാസ പ്രൊഫൈൽ",
  "TX_Sub32":"തൊഴിൽ പ്രൊഫൈൽ",
  "TX_Sub33":"ആരോഗ്യ പ്രൊഫൈൽ",
  "TM_Txf13":"സംസ്ഥാനം തിരഞ്ഞെടുക്കുക",
  "TM_Txf14":"ജില്ല തിരഞ്ഞെടുക്കുക",
  "TM_Hep43":"മുമ്പത്തെ ചോദ്യത്തിന് വലത്തേക്ക് സ്വൈപ്പ് ചെയ്യുക",
  "TM_Hep28":"നിങ്ങളുടെ പ്രൊഫൈൽ അനുസരിച്ച് പ്രസക്തമായ സ്കീമുകൾ കണ്ടെത്താൻ ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകുക",
  "TM_Hep30":"നിങ്ങളുടെ പ്രൊഫൈൽ അനുസരിച്ച് പ്രസക്തമായ ജോലികൾ കണ്ടെത്താൻ ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകുക",
  "TM_Hep32": "നിങ്ങൾ യോഗ്യനായിരിക്കാം",
  "TM_Hep35":"കുറച്ച് ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകുക",
  "TM_But16":"അടുത്തത്",
  "TM_Rel1":"മകൾ",
  "TM_Rel2":"പിതാവ്",
  "TM_Rel3":"മാതാവ്",
  "TM_Rel4":"മകൻ",
  "TM_Rel5":"സഹോദരൻ",
  "TM_Rel6":"സഹോദരി",
  "TM_Rel7":"ജീവിത പങ്കാളി",
  "TM_Rel8":"മറ്റാരെങ്കിലും",
  "TM_Rel9": "സ്വയം",
  "TM_Mod4": "ശ്രദ്ധ",
  "TM_Mod2": "സേവനങ്ങൾ ഇല്ലാതാക്കുക",
  "TM_Rec12": "നിങ്ങൾ ഡാറ്റ മാറ്റുകയാണെങ്കിൽ അത് നിങ്ങളുടെ പ്രൊഫൈൽ ഡാറ്റയിലും അപ്ഡേറ്റ് ചെയ്യപ്പെടും. നിങ്ങൾ തുടരാൻ ആഗ്രഹിക്കുന്നുവോ?",
  "TM_Hep12": "നിങ്ങൾ മുമ്പ് സംരക്ഷിച്ച ഡാറ്റ അവലോകനം ചെയ്ത് സ്ഥിരീകരിക്കുക.",
  "TM_But28": "തുടരുക",
  "TM_Pag24": "നിങ്ങളുടെ വിശദാംശങ്ങൾ അവലോകനം ചെയ്യുക",
  "TM_Pag25": "നിങ്ങളുടെ വിശദാംശങ്ങൾ അപ്ഡേറ്റ് ചെയ്യുക",
  "TM_Hep33":"നിങ്ങൾ നന്നായി ചെയ്യുന്നു! പൊയ്ക്കൊണ്ടേയിരിക്കുന്നു...",
  "TM_Con15":"ഞങ്ങൾ വിശദാംശങ്ങൾ ലഭിക്കുന്നതുവരെ ദയവായി കാത്തിരിക്കുക",
  "TM_But9" : "കൂടുതൽ സേവനങ്ങൾ പര്യവേക്ഷണം ചെയ്യുക",
  "TM_Pag5":"സ്കീം വിശദാംശങ്ങൾ",
  "TM_Pag8":"സേവന വിശദാംശങ്ങൾ",
  "TM_Hep3": "സേവന നില",
  "TM_But6": "ലിങ്ക് പകർത്തുക",
  "TM_But19": "ഡൗൺലോഡ്",
  "TM_Con1": "ലിങ്ക് പകർത്തി",
  "TM_Con5": "ഡൗൺലോഡ് ചെയ്തു",
  "TM_Alt14": "അപേക്ഷിക്കേണ്ടവിധം",
  "TM_Alt15": "വിവരങ്ങൾ",
  "TM_Con3": `ഈ സ്കീം നിങ്ങളുടെ "എന്റെ സ്കീമിൽ" സംരക്ഷിച്ചിരിക്കുന്നു`,
  "TM_Con2": `ഈ സേവനം നിങ്ങളുടെ "എന്റെ സേവനത്തിൽ" സംരക്ഷിച്ചിരിക്കുന്നു`,
  "TM_Con4": `ഈ ജോലി നിങ്ങളുടെ "എന്റെ ജോലിയിൽ" സംരക്ഷിച്ചു`,
  "TM_Alt34":"അപേക്ഷിക്കേണ്ട നടപടിക്രമം",
  "TM_Alt35":"അപേക്ഷ ലിങ്ക്",
  "TM_Alt36":"ഡൗൺലോഡ് ഫോം",
  "TM_Alt38":"തിരഞ്ഞെടുപ്പ് നടപടിക്രമം",
  "TM_Alt41":"സ്കീം ആനുകൂല്യങ്ങൾ",
  "TM_Alt56":"പോസ്റ്റ്",
  "TM_Alt57":"ശമ്പളം",
  "TM_Alt58":"ജോലി വിശദാംശങ്ങൾ",
  "TM_Alt59":"അപേക്ഷിക്കാനുള്ള അവസാന തീയതി",
  "TM_Txf12": "കാരണം",
  "TM_Hep51": "യോഗ്യതാ മൂല്യങ്ങള്‍",
  "TM_Hep52": "താങ്കള്‍ നല്കിയ മൂല്യം",
  "TM_Con7" : "ക്ഷമിക്കണം, നിങ്ങൾ ഈ സ്കീമിന് യോഗ്യനല്ല",
  "TM_Con8" : "ക്ഷമിക്കണം, നിങ്ങൾ ഈ ജോലിക്ക് യോഗ്യനല്ല",
  "TM_Err5" : "ഓപ്ഷനുകളൊന്നും അവശേഷിക്കുന്നില്ല",
  "TM_Con13" : "നിങ്ങളുടെ ഡാറ്റ വിജയകരമായി സംരക്ഷിച്ചു.",
  "TM_Hep8" : "സംരക്ഷിച്ച ജോലികളൊന്നുമില്ല",
  "TM_Pag10": "എന്റെ ജോലികൾ",
  "TM_Mod3" : "ജോലി ഇല്ലാതാക്കുക",
  "TM_Rec3" : "നിങ്ങളുടെ 'എന്റെ ജോലികളിൽ' നിന്ന് ഈ ജോലി ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  "TM_Hep24": "ഡ്രാഫ്റ്റ് ജോലികളൊന്നുമില്ല",
  "TM_Hep25": "അപ്ലൈഡ് ജോലികളൊന്നുമില്ല",
  "TM_Hep26": "എൻറോൾ ചെയ്ത ജോലികളൊന്നുമില്ല",
  "TM_Hep27": 'കാലഹരണപ്പെട്ട എല്ലാ ജോലികളും കാലഹരണപ്പെടുന്ന തീയതി മുതൽ 30 ദിവസത്തിനുള്ളിൽ നിങ്ങളുടെ "എന്റെ ജോലികളിൽ" നിന്ന് നീക്കം ചെയ്യപ്പെടും.',
  "TM_Tag3" : "കാലഹരണപ്പെട്ടു",
  "TM_Tag4" : "പ്രകാരം അപേക്ഷിക്കുക",
  "TM_Rec5": "നിങ്ങളുടെ എല്ലാ ഡാറ്റയും ഇല്ലാതാക്കി",
  "TM_Rec4": "നിങ്ങളുടെ അക്കൗണ്ട് ഇല്ലാതാക്കാൻ ആദ്യം നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട എല്ലാ പ്രൊഫൈലുകളും ഇല്ലാതാക്കണം",
  "TM_Pag41": "അപേക്ഷയുടെ വിശദാംശങ്ങൾ",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "ഈ ഡോക്യുമെന്റ് നീക്കം ചെയ്യണമെന്ന് നിങ്ങൾക്ക് തീർച്ചയാണോ?",
  "TM_Lin2": "നിബന്ധനകളും വ്യവസ്ഥകളും",
  "TM_Mod18": "ഗാലറി",
  "TM_Mod17": "ക്യാമറ",
  "91412465ea9169dfd901dd5e7c96dd99": "അപ്‌ലോഡ് ",
  "af6ebe0c0e89201cb2294f32955f7b15": "ഒരു സമയം 20 പ്രമാണങ്ങൾ മാത്രമേ അനുവദിക്കൂ .",
  "d5de543c0e83073f05a846d62cc83c81": "അതേ ഫീൽഡിനായി ഡോക്യുമെന്റ് ഇതിനകം അപ്‌ലോഡ് ചെയ്തു.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "സാധുതയുള്ളത് നൽകുക",
  "3f68e67dc6c397aaa9d1c24c356f754f": "പരിശോധിച്ചു",
  "1e410e1b9903e7a99b93857f536b7e1d": "പരിശോധിച്ചിട്ടില്ല",
  "f8d855b26faffa0f8779fb44632d6bc1": "അസാധുവായ ക്യാപ്‌ച കോഡ്",
  "d53b4d564b9054db9b4b4a50e713197f": "ദയവായി ഇവിടെ ക്യാപ്‌ച നൽകുക",
  "TM_Con18": "നിങ്ങളുടെ 'ഡ്രാഫ്റ്റ് സ്കീമിൽ' നിങ്ങളുടെ അപേക്ഷ വിജയകരമായി സംരക്ഷിച്ചു",
  "TM_Con24": "നിങ്ങളുടെ അപേക്ഷ നിങ്ങളുടെ 'ഡ്രാഫ്റ്റ് ജോബിൽ' വിജയകരമായി സംരക്ഷിച്ചു",
  "TM_Con23": `നിങ്ങളുടെ അപേക്ഷ നിങ്ങളുടെ "ഡ്രാഫ്റ്റ് സേവനങ്ങളിൽ" വിജയകരമായി സംരക്ഷിച്ചു.`,
  "6cf24d7a07132fe88b610e2b3d3def28": "സ്വയം പ്രഖ്യാപനം",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "ഈ ഫീൽഡ് ആവശ്യമാണ്.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "ദയവായി അക്ഷരമാല, അക്കങ്ങൾ, കോമ, -, _, / മാത്രം നൽകുക",
  "562d3d08919ae105c3ebc42d9607d266": "ദയവായി അക്ഷരമാലകളോ അക്കങ്ങളോ മാത്രം നൽകുക",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "അക്ഷരമാലകൾ മാത്രം അനുവദനീയമാണ്.",
  "4906d981bb592b32f6982fb4187c7b60": "സാധുവായ ഒരു ഐഎഫ്എസ്സി കോഡ് നൽകുക.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "11 പ്രതീകങ്ങൾ മാത്രമേ അനുവദിക്കൂ.",
  "7c58cde3b827bc7d57cbf77865046169": "ദയവായി നമ്പറുകൾ മാത്രം നൽകുക",
  "544b0264d55a1e4cf476b279240d9be4": "പത്ത് അക്കങ്ങൾ മാത്രമേ അനുവദിക്കൂ. ദയവായി 0 അല്ലെങ്കിൽ +91 പ്രിഫിക്‌സ് ചെയ്യരുത്",
  "73f0d980e48b8141e0776e02d96a872f": "പന്ത്രണ്ട് അക്കങ്ങൾ മാത്രമേ അനുവദിക്കൂ.",
  "0ce98bd973419ff33921a2275e7903b9": "ദയവായി ഒരു സാധുവായ പാൻ നമ്പർ നൽകുക.",
  "e55e425dbaf6b6449940eac8ea749092": "ദയവായി അക്ഷരമാലകൾ മാത്രം നൽകുക.",
  "64ff9e3809686d4ea3253a631cae4761": "ദയവായി 2 ദശാംശസ്ഥാനങ്ങളിലേക്ക് ശരിയായ മൂല്യം നൽകുക.",
  "7ed0ef23d527c2876015161e422f49ce": "ദയവായി നിങ്ങളുടെ പേര് കന്നഡ ഭാഷയിൽ നൽകുക.",
  "230486b5dd7afed96556ba9bd4175423": "പിൻ കോഡ് തെറ്റാണ്",
  "9f44bd93c8988c682d5ef5369fd11f47": "ദയവായി സാധുവായ ഇമെയിൽ നൽകുക",
  "ac598199ea0dd028ade4677a435caf4b": "സാധുവായ ഒരു ആധാർ നമ്പർ നൽകുക",
  "10803b83a68db8f7e7a33e3b41e184d0": "ജനന തീയതി",
  "d0de720ccdffea8490dfd2893c50ebc5": "ഇത് {{displayName}} എന്നതിനേക്കാൾ വലുതായിരിക്കണം",
  "ced6e171e13ebd5c37415c822706b9d4": "ഇത് {{displayName}} എന്നതിനേക്കാൾ കുറവായിരിക്കണം",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "ഇത് {{displayName}} എന്നതിന് തുല്യമായിരിക്കണം",
  "9483135517d156229d0439dff74b1ca0": "ഇത് {{displayName}} എന്നതിനേക്കാൾ വലുതോ തുല്യമോ ആയിരിക്കണം",
  "01e9cd748e852b1ae389b980bff195b0": "ഇത് {{displayName}} എന്നതിനേക്കാൾ കുറവോ തുല്യമോ ആയിരിക്കണം",
  "TM_Pag37": "വിശദാംശങ്ങൾ അവലോകനം ചെയ്യുക",
  "TM_Rec18": "നിങ്ങളുടെ പൂരിപ്പിച്ച വിശദാംശങ്ങൾ മാറ്റാൻ 'എഡിറ്റ്' ക്ലിക്ക് ചെയ്യുക.",
  "TM_Alt51": "അറ്റാച്ച്മെന്റ്(കൾ):",
  "TM_Alt42": "നന്ദി",
  "TM_But9":"കൂടുതൽ സേവനങ്ങൾ പര്യവേക്ഷണം ചെയ്യുക",
  "TM_Con17":"നിങ്ങളുടെ ആപ്ലിക്കേഷൻ ഡൗൺലോഡ് ചെയ്തു.",
  "TM_Sub48": "സഹായസഹകരണങ്ങൾ",
  "TM_Alt66": "<b>jioeasygov@easygov.co.in</b>-ൽ ഞങ്ങൾക്ക് ഒരു ഇമെയിൽ അയയ്‌ക്കുക, ഞങ്ങളുടെ ടീം ഉടൻ തന്നെ നിങ്ങളിലേക്ക് മടങ്ങിവരും.",
  "TM_Alt65": "എന്തെങ്കിലും ചോദ്യത്തിന് സഹായം ആവശ്യമുണ്ടോ?",
  "TM_Pag54": "സേവന ആനുകൂല്യങ്ങൾ",
  "TM_Alt37": "സർക്കാർ ഫീസ്",
  "TM_But105" : "കൂടുതൽ പര്യവേക്ഷണം ചെയ്യുക",
  "TM_Alt39": "സമർപ്പിക്കൽ വിലാസം",
  "TM_But17": "സമർപ്പിക്കുക",
  "TM_But58": "അപേക്ഷിക്കുന്നതിനായി മുന്നോട്ടുപോകുക",
  "TM_But100" : "മടങ്ങിപ്പോവുക",
  "TM_But95": "ഇപ്പോള് പണമടയ്ക്കൂ",
  "TM_Alt88": "നിങ്ങളുടെ അപേക്ഷ സമർപ്പിക്കാൻ ഇനിപ്പറയുന്ന തുക അടയ്ക്കുക",
  "TM_Con32" : "നിങ്ങളുടെ അപേക്ഷ വിജയകരമായി സമർപ്പിച്ചു",
  "TM_But110": "ಅಪ್ಲಿಕೇಶನ್",
  "TM_Txf16": "ഇവിടെ തിരയുക",
  "TM_Hep69": "'സ്‌കീം പിന്തുണ നേടുക' എന്നതിൽ ക്ലിക്ക് ചെയ്ത് ഒരു ചെറിയ സർവേ നടത്തുക, അത് നിങ്ങൾക്ക് ശരിയായ പിന്തുണ നൽകാൻ ഞങ്ങളെ സഹായിക്കും.",
  "TM_But107": "എന്താണ് സ്കീം പിന്തുണ നേടുക?",
  "TM_But106": "സ്കീം പിന്തുണ നേടുക",
  "TM_Pag32": "സ്കീം അവബോധ സർവേ",
  "TM_Con14": "വിശദാംശങ്ങൾ പങ്കിട്ടതിന് നന്ദി.",
  "TM_Tag5": "ഒഴിവുള്ള സ്ഥാനം",
  "TM_Alt107": "ദിവസങ്ങളിൽ",
  "TM_Rec11":"ಆಯ್ಕೆಮಾಡಿದ ಅಧಿಸೂಚನೆಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  "TM_Mod7":"അറിയിപ്പുകൾ ഇല്ലാതാക്കുക",
  "TM_But25":"എല്ലാം തിരഞ്ഞെടുക്കുക",
  "TM_Mod1": "സ്കീം ഇല്ലാതാക്കുക",
  "TM_Rec50": "നിങ്ങളുടെ അപേക്ഷ പിൻവലിക്കണമെന്ന് തീർച്ചയാണോ ?",
  "TM_But23": "സ്ഥിരീകരിക്കുക",
  "TM_But12": "റദ്ദാക്കുക",
  "TM_Con6" : "നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത മൊബൈൽ നമ്പറിലേക്ക് ഒരു OTP അയച്ചിട്ടുണ്ട് (xxxxxx{{phoneNumber}})",
  "TM_But17": "സമർപ്പിക്കുക",
  "TM_But61": "OTP വീണ്ടും അയയ്‌ക്കുക",
  "TM_Con20": "നിങ്ങളുടെ അപേക്ഷ വിജയകരമായി പിൻവലിച്ചു",
  "TM_But38": "പൂർണ്ണമായ പ്രവർത്തനങ്ങൾ",
  "TM_But62": "ഫീഡ്ബാക്ക് നൽകുക",
  "TM_Lab11": "അപേക്ഷ പിൻവലിക്കുക",
  "TM_Err3" : "അസാധുവായ OTP",
  "TM_Err13": "OTP കാലഹരണപ്പെട്ടു",
  "TM_Err14": "OTP സൃഷ്ടിക്കുന്നതിനുള്ള പരമാവധി പരിധി നിങ്ങൾ കവിഞ്ഞു.  3 മണിക്കൂറിന് ശേഷം വീണ്ടും ശ്രമിക്കുക.",
  "TM_But40": "പ്രവർത്തനങ്ങൾ",
  "TM_Tag9": "ആവശ്യമായ നടപടികള്‍",
  "TM_Tag10": "നടപടിയൊന്നും ആവശ്യമില്ല",
  "TM_Tag8" : "പദവി",
  "TM_But76" : "കാണുക",
  "TM_Alt87" : "ഓൺലൈനിൽ ലഭ്യമല്ല",
  "TM_Pag34": "പേയ്മെന്റ് വിശദാംശങ്ങൾ",
  "TM_Hea1": "മൊത്തം തുക",
  "TM_Hea2": "സംഗ്രഹം",
  "TM_Sub56": "മൊത്തം",
  "TM_But42": "പണം നൽകുക",
  "TM_Lab18": "രസീത് കാണുക",
  "TM_Err34": "പേയ്‌മെന്റ് പരാജയപ്പെട്ടതിനാൽ നിങ്ങളുടെ അപേക്ഷ സമർപ്പിക്കാനായില്ല.",
  "TM_Rec55": "നിങ്ങളുടെ പേയ്‌മെന്റിന്റെ നില സ്ഥിരീകരിക്കാൻ സമയമെടുക്കുന്നു. ഞങ്ങൾ നിങ്ങളുടെ ബാങ്കുമായി തുടർച്ചയായി പരിശോധിക്കുന്നു. അടുത്ത 48 മണിക്കൂർ നേരത്തേക്ക് അധിക പേയ്‌മെന്റുകൾ നടത്തുന്നത് ഒഴിവാക്കുക.",
  "TM_But13": "തിരിച്ച് പോകൂ",
  "TM_Alt124":"കാത്തിരിക്കൂ",
  "TM_Rec58": "കാത്തിരിക്കൂ. ഞങ്ങൾ നിങ്ങളുടെ പേയ്‌മെന്റ് നില പരിശോധിച്ചുകൊണ്ടിരിക്കുകയാണ്",
  "TM_Rec59": "ഈ സേവനത്തിനായുള്ള നിങ്ങളുടെ അവസാന പേയ്‌മെന്റ് തീർച്ചപ്പെടുത്താത്ത നിലയിലാണ്. അത് പൂർത്തിയാകുമ്പോൾ ഞങ്ങൾ നിങ്ങളെ അറിയിക്കും. അതിനിടയിൽ, ഞങ്ങളുടെ മറ്റ് സേവനങ്ങൾ പര്യവേക്ഷണം ചെയ്യാൻ മടിക്കേണ്ടതില്ല. നിങ്ങളുടെ ക്ഷമക്ക് നന്ദി.",
  "TM_But133": "ലിങ്കിൽ ക്ലിക്ക് ചെയ്യുക"

}
 
export default language
