var language = {
	"TM_Txf6": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਪਸੰਦੀਦਾ ਭਾਸ਼ਾ ਚੁਣੋ",
	"TM_Pag17": "ਸਥਾਨ ਬਦਲੋ",
  "TM_Pag13": "ਨੌਕਰੀ ਦੇ ਲਾਭ",
	"TM_But15": "ਅੱਗੇ ਵਧੋ",
	"TM_Pag19": "ਭਾਸ਼ਾ ਚੁਣੋ",
	"TM_But1": "ਆਓ ਸ਼ੁਰੂ ਕਰੀਏ",
	"TM_Pag20": "ਟਿਕਾਣਾ ਚੁਣੋ",
	"TM_Txf9": "ਤੁਹਾਡਾ ਟਿਕਾਣਾ ਪ੍ਰਦਾਨ ਕਰਨਾ ਸਾਨੂੰ ਤੁਹਾਡੇ ਖੇਤਰ ਵਿੱਚ ਤੁਹਾਡੇ ਲਈ ਯੋਗ ਲਾਭ ਦਿਖਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰੇਗਾ।",
	"TM_Txf3": "ਖੋਜ ਰਾਜ",
	"TM_Txf4": "ਖੋਜ ਜ਼ਿਲ੍ਹਾ",
  "TM_But37":"ਐਪਲੀਕੇਸ਼ਨ ਦੇਖੋ",
  "TM_Tag8":"ਸਥਿਤੀ",
  "TM_Pag35": "ਐਪਲੀਕੇਸ਼ਨ ਸਥਿਤੀ",
	"TM_Alt1": "3000+ ਭਲਾਈ ਸਕੀਮਾਂ ਲਈ ਯੋਗਤਾ ਦੀ ਜਾਂਚ ਕਰੋ",
	"TM_Alt3": "ਆਪਣੇ ਨੇੜੇ ਨੌਕਰੀ ਦੇ ਮੌਕੇ ਲੱਭੋ",
	"TM_Alt2": "ਉਹਨਾਂ ਸੇਵਾਵਾਂ ਦੀ ਜਾਂਚ ਕਰੋ ਜੋ ਤੁਸੀਂ ਅਪਲਾਈ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
	"TM_Lin1": "ਐਪ ਤੱਕ ਪਹੁੰਚ ਕਰਕੇ, ਤੁਸੀਂ EasyGov’s ਨਾਲ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ",
	"TM_Alt5": "ਉਹਨਾਂ ਭਲਾਈ ਲਾਭਾਂ ਨੂੰ ਲੱਭਣ ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰਨ ਲਈ ਪ੍ਰੋਫਾਈਲ ਬਣਾਓ ਜਿਨ੍ਹਾਂ ਲਈ ਤੁਸੀਂ ਯੋਗ ਹੋ ਸਕਦੇ ਹੋ",
	"TM_But2": "ਆਪਣਾ ਪ੍ਰੋਫਾਈਲ ਬਣਾਓ",
	"TX_Sub1": "ਸਕੀਮ ਸ਼੍ਰੇਣੀਆਂ",
	"TX_Sub2": "ਫਲੈਗਸ਼ਿਪ ਸਕੀਮਾਂ",
	"TX_Sub3": "ਨਵੀਆਂ ਸ਼ਾਮਲ ਕੀਤੀਆਂ ਸਕੀਮਾਂ",
	"TX_Sub4": "ਪ੍ਰਸਿੱਧ ਸਕੀਮਾਂ",
	"TM_Pag1": "ਸਕੀਮ ਸ਼੍ਰੇਣੀਆਂ",
	"TM_Alt6": "ਸਕੀਮਾਂ",
	"TM_Alt7": "ਸੇਵਾਵਾਂ",
	"TM_Alt8": "ਨੌਕਰੀਆਂ",
	"TM_Alt9": "ਖੋਜ",
	"TM_Alt10": "ਪ੍ਰੋਫਾਈਲ",
	"TX_Sub7": "ਸੇਵਾ ਸ਼੍ਰੇਣੀਆਂ",
	"TX_Sub8": "ਸਿਖਰ ਦਰਜਾ ਪ੍ਰਾਪਤ ਸੇਵਾਵਾਂ",
	"TX_Sub9": "ਨਵੀਆਂ ਸ਼ਾਮਲ ਕੀਤੀਆਂ ਸੇਵਾਵਾਂ",
	"TX_Sub10": "ਪ੍ਰਸਿੱਧ ਸੇਵਾਵਾਂ",
  "TX_Sub13":"ਮੇਰੀਆਂ ਸੇਵਾਵਾਂ",
  "TM_Pag6": "ਮੇਰੀਆਂ ਸੇਵਾਵਾਂ",
	"TM_Pag18": "ਸੇਵਾ ਸ਼੍ਰੇਣੀਆਂ",
	"TM_Alt16": "ਤੁਹਾਡੇ ਲਈ ਸਭ ਤੋਂ ਅਨੁਕੂਲ ਨੌਕਰੀਆਂ ਲੱਭਣ ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰਨ ਲਈ ਪ੍ਰੋਫਾਈਲ ਬਣਾਓ",
	"TM_Alt17": "ਨੌਕਰੀ ਦੇ ਵੇਰਵੇ",
  "TM_Alt18" : "ਕੰਮ ਦਾ ਵੇਰਵਾ",
  "TM_Alt19":"ਅੰਗਰੇਜ਼ੀ", 
  "TM_Alt20":"ਹਿੰਦੀ",
  "TM_Alt21":"ਤਾਮਿਲ",
  "TM_Alt22":"ਕੰਨੜ",
  "TM_Alt23":"ਪੰਜਾਬੀ",
  "TM_Alt24":"ਮਲਿਆਲਮ",
  "TM_Alt25":"ਬੰਗਾਲੀ",
  "TM_Alt26":"ਗੁਜਰਾਤੀ",
  "TM_Alt27":"ਤੇਲਗੂ",
  "TM_Alt28":"ਮਰਾਠੀ",
  "TM_But3":"ਸਭ ਦੇਖੋ",
"TM_But5":"ਯੋਗਤਾ ਦੀ ਜਾਂਚ ਕਰੋ",
"TM_But10":"ਹੋਰ ਨੌਕਰੀਆਂ ਦੀ ਪੜਚੋਲ ਕਰੋ",
"TX_Sub15":"ਸਿਖਰ ਦਰਜਾ ਪ੍ਰਾਪਤ ਨੌਕਰੀਆਂ",
"TX_Sub16":"ਨਵੀਆਂ ਸ਼ਾਮਲ ਕੀਤੀਆਂ ਨੌਕਰੀਆਂ",
"TX_Sub17":"ਪ੍ਰਸਿੱਧ ਨੌਕਰੀਆਂ",
"TX_Sub18":"ਸਿਫ਼ਾਰਿਸ਼ ਕੀਤੀਆਂ ਨੌਕਰੀਆਂ",
"TX_Sub20":"ਮੇਰੀਆਂ ਨੌਕਰੀਆਂ",
"TM_alt11":"ਲਾਭ",
"TM_Pag4":"ਸਕੀਮ ਦੇ ਵੇਰਵੇ",
"TM_Pag21": "ਸਕੀਮਾਂ",
"TM_Pag22": "ਸੇਵਾਵਾਂ",
"TM_Pag23": "ਨੌਕਰੀਆਂ",
"TM_Alt30" : "ਸਕੀਮਾਂ, ਸੇਵਾਵਾਂ ਅਤੇ ਨੌਕਰੀਆਂ ਦੀ ਖੋਜ ਕਰੋ",
"TM_Lab6": "ਹਾਲ ਹੀ ਵਿੱਚ ਖੋਜ ਕੀਤੀ ਗਈ",
"TM_Err4": "ਕੋਈ ਨਤੀਜਾ ਨਹੀਂ ਮਿਲਿਆ",
"TM_Err12": "ਇਹ ਸੇਵਾ ਤੁਹਾਡੇ ਟਿਕਾਣੇ 'ਤੇ ਉਪਲਬਧ ਨਹੀਂ ਹੈ।",
"TM_Mod25":"ਭਾਸ਼ਾ ਬਦਲੋ",
"TM_Rec33":"ਇਹ ਸੇਵਾ ਸਿਰਫ਼ ਅੰਗਰੇਜ਼ੀ ਭਾਸ਼ਾ ਵਿੱਚ ਉਪਲਬਧ ਹੈ। ਐਪ ਦੀ ਭਾਸ਼ਾ ਨੂੰ ਬਦਲਣ ਲਈ ਅੱਗੇ ਵਧਣ 'ਤੇ ਕਲਿੱਕ ਕਰੋ",
"TM_Pag12":"ਕੰਮ ਵੇਰਵਾ",
"TM_Alt12":"ਯੋਗਤਾ",
"TM_Tag1":"ਹੁਣੇ ਹੀ ਲਾਂਚ ਕੀਤਾ",
"TX_Sub25" : "ਅਕਸਰ ਪੁੱਛੇ ਜਾਣ ਵਾਲੇ ਸਵਾਲ",
"TM_Sup1": "ਸਾਡਾ ਅਨੁਸਰਣ ਕਰੋ",
"TM_Hep17":"ਜੇ ਤੁਹਾਡੀ ਕੋਈ ਪੁੱਛਗਿੱਛ ਹੈ ਤਾਂ ਸਾਨੂੰ ਦੱਸੋ ਅਤੇ ਅਸੀਂ 48 ਕਾਰੋਬਾਰੀ ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਤੁਹਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰਾਂਗੇ।",
"TM_Lab4":"ਈ- ਮੇਲ",
"TM_Lin2":"ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ",
"TM_Lin3":"ਪਰਦੇਦਾਰੀ ਨੀਤੀ",
"TM_But4":"ਉਪਭੋਗਤਾ ਬਦਲੋ",
"TM_Pag15": "ਉਪਭੋਗਤਾ ਬਦਲੋ",
"TM_Hep36":"ਤੁਸੀਂ ਯੋਗਤਾ ਦੇਖਣ ਲਈ ਜਾਂ ਆਪਣੇ ਸੁਰੱਖਿਅਤ ਕੀਤੇ ਡੇਟਾ ਨੂੰ ਅੱਗੇ ਪ੍ਰਕਿਰਿਆ ਕਰਨ ਲਈ ਆਪਣੀ ਲੋੜੀਦੀ ਪ੍ਰੋਫਾਈਲ ਨੂੰ ਚੁਣ ਸਕਦੇ ਹੋ ਜਾਂ ਜੋੜ ਸਕਦੇ ਹੋ।",
"TM_But18":"ਉਪਭੋਗਤਾ ਸ਼ਾਮਲ ਕਰੋ",
"TM_Mod5": "ਕੀ ਤੁਸੀਂ ਪੱਕੇ ਤੌਰ 'ਤੇ {{Name}} ਖਾਤਾ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
"TM_Rec7":"ਜਦੋਂ ਤੁਸੀਂ ਆਪਣਾ EasyGov ਖਾਤਾ ਮਿਟਾਉਂਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਆਪਣੇ ਪ੍ਰੋਫਾਈਲ ਅਤੇ ਤੁਹਾਡੇ ਖਾਤੇ ਨਾਲ ਸੰਬੰਧਿਤ ਹੋਰ ਪ੍ਰੋਫਾਈਲਾਂ ਵਿੱਚ ਸਟੋਰ ਕੀਤੇ ਕਿਸੇ ਵੀ ਡੇਟਾ ਜਾਂ ਜਾਣਕਾਰੀ ਨੂੰ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ ਹੋਵੋਗੇ। ਤੁਹਾਡੀਆਂ ਸਾਰੀਆਂ ਸਿਫ਼ਾਰਿਸ਼ ਕੀਤੀਆਂ ਅਤੇ ਰੱਖਿਅਤ ਕੀਤੀਆਂ ਸਕੀਮਾਂ ਨੂੰ ਵੀ ਮਿਟਾ ਦਿੱਤਾ ਜਾਵੇਗਾ।",
"TM_Err2":"ਤੁਸੀਂ {{Name}} ਪ੍ਰੋਫਾਈਲ ਨੂੰ ਮਿਟਾ ਨਹੀਂ ਸਕਦੇ ਹੋ ਕਿਉਂਕਿ ਇਸ ਵਿੱਚ ਇੱਕ ਚੱਲ ਰਹੀ ਐਪਲੀਕੇਸ਼ਨ ਹੈ।",
"TM_But11":"ਮਿਟਾਓ",
"TM_But12": "ਰੱਦ ਕਰੋ",
"TM_But30" : "ਸਾਰੀਆਂ ਨੌਕਰੀਆਂ ਦੇਖੋ",
"TM_But31" : "ਸਾਰੀਆਂ ਸਕੀਮਾਂ ਦੇਖੋ",
"TM_Con11": "ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ",
"TM_The1":"ਸੇਵ ਕਰੋ",
"TM_The2":"ਡ੍ਰਾਫਟ",
"TM_The3":"ਅਪਲਾਈ ਕੀਤਾ ਗਿਆ",
"TM_The4":"ਦਰਜ ਕੀਤਾ ਗਿਆ",
"TM_The5":"ਪ੍ਰਕਿਰਿਆ ਕਰ ਦਿੱਤੀ ਗਈ ਹੈ",
"TM_Hep7":"ਕੋਈ ਸੁਰੱਖਿਅਤ ਸਕੀਮਾਂ ਨਹੀਂ ਹਨ", 	
"TM_Hep21":"ਕੋਈ ਡਰਾਫਟ ਸਕੀਮਾਂ ਨਹੀਂ",	
"TM_Hep22":"ਕੋਈ ਲਾਗੂ ਸਕੀਮਾਂ ਨਹੀਂ",
"TM_Hep23":"ਕੋਈ ਨਾਮਾਂਕਿਤ ਸਕੀਮਾਂ ਨਹੀਂ",	
"TM_But7":"ਹੋਰ ਸਕੀਮਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ",
"TM_Rec1": "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਸਕੀਮ ਨੂੰ ਆਪਣੀਆਂ 'ਮੇਰੀਆਂ ਸਕੀਮਾਂ' ਵਿੱਚੋਂ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
"TM_Rec2":"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਸੇਵਾ ਨੂੰ ਆਪਣੀਆਂ 'ਮੇਰੀਆਂ ਸੇਵਾਵਾਂ' ਤੋਂ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
"TM_Pag2":"ਮੇਰੀਆਂ ਸਕੀਮਾਂ",
"TM_Hep10": "ਤੁਸੀਂ ਪ੍ਰੋਫਾਈਲ ਤੋਂ ਆਪਣੀ ਪਸੰਦੀਦਾ ਭਾਸ਼ਾ ਅਤੇ ਸਥਾਨ ਬਦਲ ਸਕਦੇ ਹੋ",
"TM_But21": "ਮਿਲ ਗਿਆ!",
"TM_Alt29":"ਭਾਸ਼ਾ ਅਤੇ ਟਿਕਾਣਾ",
"TM_Hep38":"{{category}} ਸ਼੍ਰੇਣੀ ਵਿੱਚ ਢੁਕਵੀਆਂ ਸਕੀਮਾਂ ਦੀ ਪਛਾਣ ਕਰਨ ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ {{category}} ਨਾਲ ਸਬੰਧਤ ਕੁਝ ਵਾਧੂ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ",
"TM_Hep11":"ਤੁਹਾਡੇ ਪ੍ਰੋਫਾਈਲ ਦੇ ਆਧਾਰ 'ਤੇ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਕਿਸੇ ਵੀ ਸਕੀਮ ਲਈ ਯੋਗ ਨਹੀਂ ਪਾਇਆ। ਅਸੀਂ ਲਗਾਤਾਰ ਹੋਰ ਸਕੀਮਾਂ ਜੋੜ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੇਖੋ।",
"TM_Hep39":"{{category}} ਸ਼੍ਰੇਣੀ ਵਿੱਚ ਢੁਕਵੀਆਂ ਨੌਕਰੀਆਂ ਦੀ ਪਛਾਣ ਕਰਨ ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ {{category}} ਨਾਲ ਸਬੰਧਤ ਕੁਝ ਵਾਧੂ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ",
"TM_Rec6": "ਇਸ ਸਮੇਂ ਤੁਹਾਡੇ ਰਾਜ ਵਿੱਚ ਕੋਈ ਨੌਕਰੀਆਂ ਦੀ ਸੰਰਚਨਾ ਨਹੀਂ ਕੀਤੀ ਗਈ ਹੈ, ਅਸੀਂ ਲਗਾਤਾਰ ਅੱਪਡੇਟ ਕਰ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਦੁਬਾਰਾ ਸੰਪਰਕ ਕਰੋ",
"TM_But27": "ਠੀਕ ਹੈ",
"TX_Sub5": "ਸਿਫ਼ਾਰਿਸ਼ ਕੀਤੀਆਂ ਸਕੀਮਾਂ",
"TM_Pag9": "ਨੌਕਰੀ ਦੀਆਂ ਸ਼੍ਰੇਣੀਆਂ",
"TM_Hep42": "ਤੁਹਾਡੇ ਪ੍ਰੋਫਾਈਲ ਦੇ ਆਧਾਰ 'ਤੇ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਕਿਸੇ ਵੀ ਨੌਕਰੀ ਲਈ ਯੋਗ ਨਹੀਂ ਪਾਇਆ। ਅਸੀਂ ਲਗਾਤਾਰ ਹੋਰ ਨੌਕਰੀਆਂ ਜੋੜ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਜਾਂਚ ਕਰੋ।",
"TM_Hep40":"ਤੇ ਲਾਗੂ ਕਰਨਾ" ,
"TM_Hep41":"ਘੰਟੇ",
"TM_Hep2": "ਸ਼੍ਰੇਣੀ",
"TM_Alt31": "ਸੇਵਾ ਵਿੱਚ",
  "TM_Alt32" : "ਸਕੀਮ ਵਿੱਚ",
  "TM_Alt33" : "ਨੌਕਰੀ ਵਿੱਚ",
  "TM_Pag14": "ਪ੍ਰੋਫਾਈਲ ਬਣਾਓ",
  "TM_Hep6":"ਕੋਈ ਸੁਰੱਖਿਅਤ ਸੇਵਾਵਾਂ ਨਹੀਂ",
  "TM_Hep13":"ਕੋਈ ਡਰਾਫਟ ਸੇਵਾਵਾਂ ਨਹੀਂ।",
  "TM_Hep14":"ਕੋਈ ਲਾਗੂ ਸੇਵਾਵਾਂ ਨਹੀਂ।",
  "TM_Hep15":"ਕੋਈ ਸੂਚਨਾਵਾਂ ਨਹੀਂ",
  "TM_Hep44":"ਕੋਈ ਪ੍ਰੋਸੈਸਡ ਸੇਵਾਵਾਂ ਨਹੀਂ",
  "TM_Hep54":"ਅੱਜ",
  "TM_Hep55":"ਕੱਲ",
  "TM_Hep9": "ਇੱਕ ਪ੍ਰੋਫਾਈਲ ਬਣਾਉਣਾ ਤੁਹਾਨੂੰ ਉਹਨਾਂ ਸਾਰੇ ਮੌਕਿਆਂ ਨੂੰ ਬਚਾਉਣ ਦੀ ਆਗਿਆ ਦਿੰਦਾ ਹੈ ਜਿਸ ਵਿੱਚ ਤੁਹਾਡੀ ਦਿਲਚਸਪੀ ਹੈ, ਅਤੇ ਤੁਹਾਡੇ ਸੁਰੱਖਿਅਤ ਕੀਤੇ ਡੇਟਾ ਨੂੰ ਅੱਗੇ ਪ੍ਰਕਿਰਿਆ ਕਰਨ ਲਈ ਕਿਸੇ ਵੀ ਸਮੇਂ ਬਾਅਦ ਵਿੱਚ ਵਾਪਸ ਆਉਣ ਦੀ ਆਗਿਆ ਦਿੰਦਾ ਹੈ।",
  "TM_Txf1": "ਪੂਰਾ ਨਾਂਮ",
  "TM_Txf2": "ਸਬੰਧ",
  "TM_But29": "ਸੇਵ ਕਰੋ",
  "TM_Err1": "ਇੱਕੋ ਨਾਮ ਅਤੇ ਰਿਸ਼ਤੇਦਾਰੀ ਵਾਲਾ ਪ੍ਰੋਫਾਈਲ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ!",
  "TM_Err6": "ਸਿਰਫ਼ ਅੱਖਰਾਂ ਦੀ ਹੀ ਇਜਾਜ਼ਤ ਹੈ",
  "TM_Pag16": "ਭਾਸ਼ਾ ਬਦਲੋ",
  "TM_Pag33": "ਟਿਕਾਣਾ ਅੱਪਡੇਟ ਕਰੋ",
  "TM_But22": " ਅੱਪਡੇਟ ",
  "TM_But14": "ਪ੍ਰੋਫਾਈਲ ਸ਼ਾਮਲ ਕਰੋ",
  "TM_Txf15": "ਨਾਂ ਸ਼ਾਮਲ",
  "TM_But20":"ਸੰਪਾਦਿਤ ਕਰੋ",
  "TM_Hep16":"ਐਪ ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਹੋਏ ਆਪਣੇ ਅਨੁਭਵ ਨੂੰ ਵਧਾਉਣ ਲਈ ਅਤੇ ਤੁਹਾਡੇ ਲਈ ਸਹੀ ਸਕੀਮਾਂ/ਸੇਵਾਵਾਂ/ਨੌਕਰੀਆਂ ਨੂੰ ਪ੍ਰਦਰਸ਼ਿਤ ਕਰਨ ਲਈ ਆਪਣਾ ਸਹੀ ਸਥਾਨ ਚੁਣੋ।",
  "TM_Pag26": "ਟਿਕਾਣੇ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
  "TM_But23": "ਪੁਸ਼ਟੀ ਕਰੋ",
  "TX_Sub21":"ਸੂਚਨਾਵਾਂ",
  "TX_Sub22":"ਪ੍ਰੋਫਾਈਲ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ",
  "TX_Sub23":"ਟਿਕਾਣਾ",
  "TX_Sub24":"ਭਾਸ਼ਾ",
  "TX_Sub25":"ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਸਵਾਲ",
  "TX_Sub26":"ਸਾਡੇ ਬਾਰੇ",
  "TX_Sub27":"ਖਾਤਾ ਮਿਟਾਓ",
  "TX_Sub28":"ਨਿੱਜੀ ਵੇਰਵੇ",
  "TX_Sub29":"ਆਰਥਿਕ ਵੇਰਵੇ",
  "TX_Sub30":"ਹਾਊਸਿੰਗ ਵੇਰਵੇ",
  "TX_Sub31":"ਸਿੱਖਿਆ ਪ੍ਰੋਫ਼ਾਈਲ",
  "TX_Sub32":"ਕਿੱਤਾ ਪ੍ਰੋਫ਼ਾਈਲ",
  "TX_Sub33":"ਸਿਹਤ ਪ੍ਰੋਫਾਈਲ",
  "TM_Txf13":"ਰਾਜ ਚੁਣੋ",
  "TM_Txf14":"ਜ਼ਿਲ੍ਹਾ ਚੁਣੋ",
  "TM_Pag29":"ਪ੍ਰੋਫਾਈਲ ਰਚਨਾ",
  "TM_Con9":"ਮਾਫ ਕਰਨਾ! ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਕੋਈ ਮੇਲ ਖਾਂਦੀਆਂ ਸਕੀਮਾਂ ਨਹੀਂ ਲੱਭ ਸਕੇ",
  "TM_Con10":"ਮਾਫ ਕਰਨਾ! ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਕੋਈ ਮੇਲ ਖਾਂਦੀਆਂ ਨੌਕਰੀਆਂ ਨਹੀਂ ਲੱਭ ਸਕੇ",
  "TM_But26": "ਘਰ",
  "TM_Rec35": "ਸਰੋਤ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
  "TM_Rec36":"ਸਰਵਰ ਟਾਈਮ ਆਉਟ!",
  "TM_Rec37":"ਐਕਸੇਸ ਡਿਨਾਇਡ!",
  "TM_Mod28":"ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ.",
  "TM_Rec10": "ਅਸੀਂ ਲਗਾਤਾਰ ਸੁਧਾਰ ਕਰ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  "TM_But24":"ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
  "TM_Hep43":"ਪਿਛਲੇ ਸਵਾਲ ਲਈ ਸੱਜੇ ਪਾਸੇ ਸਵਾਈਪ ਕਰੋ",
  "TM_Hep28":"ਆਪਣੇ ਪ੍ਰੋਫਾਈਲ ਦੇ ਅਨੁਸਾਰ ਸੰਬੰਧਿਤ ਸਕੀਮਾਂ ਲੱਭਣ ਲਈ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ",
  "TM_Hep30":"ਆਪਣੇ ਪ੍ਰੋਫਾਈਲ ਦੇ ਅਨੁਸਾਰ ਸੰਬੰਧਿਤ ਨੌਕਰੀਆਂ ਲੱਭਣ ਲਈ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ",
  "TM_Hep32": "ਤੁਸੀਂ ਇਸ ਲਈ ਯੋਗ ਹੋ ਸਕਦੇ ਹੋ",
  "TM_Hep35":"ਕੁਝ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ",
  "TM_But16":"ਅਗਲਾ",
  "TM_Rel1":"ਬੇਟੀ",
  "TM_Rel2":"ਪਿਤਾ",
  "TM_Rel3":"ਮਾਤਾ",
  "TM_Rel4":"ਬੇਟਾ",
  "TM_Rel5":"ਭਰਾ",
  "TM_Rel6":"ਭੈਣ",
  "TM_Rel7":"ਜੀਵਨਸਾਥੀ",
  "TM_Rel8":"ਹੋਰ ਕੋਈ",
  "TM_Rel9": "ਸਵੈ",
  "TM_Mod4": "ਧਿਆਨ",
  "TM_Mod2": "ਸੇਵਾਵਾਂ ਮਿਟਾਓ",
  "TM_Rec12": "ਜੇਕਰ ਤੁਸੀਂ ਡੇਟਾ ਨੂੰ ਬਦਲਦੇ ਹੋ ਤਾਂ ਉਹੀ ਤੁਹਾਡੇ ਪ੍ਰੋਫਾਈਲ ਡੇਟਾ ਵਿੱਚ ਵੀ ਅਪਡੇਟ ਕੀਤਾ ਜਾਵੇਗਾ। ਕੀ ਤੁਸੀਂ ਜਾਰੀ ਰੱਖਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  "TM_Hep12": "ਆਪਣੇ ਪਹਿਲਾਂ ਸੁਰੱਖਿਅਤ ਕੀਤੇ ਡੇਟਾ ਦੀ ਸਮੀਖਿਆ ਕਰੋ ਅਤੇ ਪੁਸ਼ਟੀ ਕਰੋ।",
  "TM_But28": "ਜਾਰੀ ਰੱਖੋ",
  "TM_Pag24": "ਆਪਣੇ ਵੇਰਵਿਆਂ ਦੀ ਸਮੀਖਿਆ ਕਰੋ",
  "TM_Pag25": "ਆਪਣੇ ਵੇਰਵਿਆਂ ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ",
  "TM_Hep33":"ਤੁਸੀਂ ਚੰਗਾ ਕਰ ਰਹੇ ਹੋ! ਚੱਲਦੇ ਰਹੋ...",
  "TM_Con15":"ਜਦੋਂ ਤੱਕ ਅਸੀਂ ਵੇਰਵੇ ਪ੍ਰਾਪਤ ਕਰਦੇ ਹਾਂ ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ",
  "TM_But9" : "ਹੋਰ ਸੇਵਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ",
  "TM_Pag5":"ਸਕੀਮ ਵੇਰਵੇ",
  "TM_Pag8":"ਸੇਵਾ ਵੇਰਵੇ",
  "TM_Hep3": "ਸੇਵਾ ਪੱਧਰ",
  "TM_But6": "ਲਿੰਕ ਕਾਪੀ ਕਰੋ",
  "TM_Con1": "ਲਿੰਕ ਕਾਪੀ ਕੀਤਾ ਗਿਆ",
  "TM_Con5": "ਡਾਊਨਲੋਡ ਕੀਤਾ",
  "TM_Alt14": "ਅਰਜ਼ੀ ਕਿਵੇਂ ਦੇਣੀ ਹੈ",
  "TM_Alt13": "ਦਸਤਾਵੇਜ਼",
  "TM_Alt15": "ਜਾਣਕਾਰੀ",
  "TM_Con3": `ਇਹ ਸਕੀਮ ਤੁਹਾਡੀ "ਮਾਈ ਸਕੀਮ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ`,
  "TM_Con2": `ਇਹ ਸੇਵਾ ਤੁਹਾਡੀ "ਮੇਰੀ ਸੇਵਾ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ`,
  "TM_Con4": `ਇਹ ਨੌਕਰੀ ਤੁਹਾਡੀ "ਮੇਰੀ ਨੌਕਰੀ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ`,
  "TM_Alt34":"ਅਪਲਾਈ ਕਰਨ ਦੀ ਪ੍ਰਕਿਰਿਆ",
  "TM_Alt35":"ਐਪਲੀਕੇਸ਼ਨ ਲਿੰਕ",
  "TM_Alt36":"ਫਾਰਮ ਡਾਊਨਲੋਡ ਕਰੋ",
  "TM_Alt38":"ਚੋਣ ਪ੍ਰਕਿਰਿਆ",
  "TM_Alt41":"ਸਕੀਮ ਦੇ ਲਾਭ",
  "TM_Alt56":"ਪੋਸਟ",
  "TM_Alt57":"ਤਨਖਾਹ",
  "TM_Alt58":"ਨੌਕਰੀ ਦੇ ਵੇਰਵੇ",
  "TM_Alt59":"ਅਪਲਾਈ ਕਰਨ ਦੀ ਆਖਰੀ ਮਿਤੀ",
  "TM_Txf12": "ਕਾਰਨ",
  "TM_Hep51": "ਯੋਗ ਮੁੱਲ",
  "TM_Hep52": "ਤੁਹਾਡੇ ਦੁਆਰਾ ਪ੍ਰਦਾਨ ਕੀਤਾ ਗਿਆ ਮੁੱਲ",
  "TM_Con7" : "ਮਾਫ਼ ਕਰਨਾ, ਤੁਸੀਂ ਇਸ ਸਕੀਮ ਲਈ ਯੋਗ ਨਹੀਂ ਹੋ",
  "TM_Con8" : "ਮਾਫ਼ ਕਰਨਾ, ਤੁਸੀਂ ਇਸ ਨੌਕਰੀ ਲਈ ਯੋਗ ਨਹੀਂ ਹੋ",
  "TM_Err5" : "ਕੋਈ ਵਿਕਲਪ ਨਹੀਂ ਬਚਿਆ",
  "TM_Con13" : "ਤੁਹਾਡਾ ਡੇਟਾ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ।",

  "TM_Hep8" : "ਕੋਈ ਰੱਖਿਅਤ ਨੌਕਰੀਆਂ ਨਹੀਂ ਹਨ",
  "TM_Pag10": "ਮੇਰੀਆਂ ਨੌਕਰੀਆਂ",
  "TM_Mod3" : "ਨੌਕਰੀ ਮਿਟਾਓ",
  "TM_Rec3" : "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਨੌਕਰੀ ਨੂੰ ਆਪਣੇ 'ਮੇਰੀਆਂ ਨੌਕਰੀਆਂ' ਵਿੱਚੋਂ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  "TM_Hep24": "ਕੋਈ ਡਰਾਫਟ ਨੌਕਰੀਆਂ ਨਹੀਂ ਹਨ",
  "TM_Hep25": "ਕੋਈ ਅਪਲਾਈਡ ਨੌਕਰੀਆਂ ਨਹੀਂ",
  "TM_Hep26": "ਕੋਈ ਨਾਮਜ਼ਦ ਨੌਕਰੀਆਂ ਨਹੀਂ ਹਨ",
  "TM_Hep27": 'ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਤੋਂ 30 ਦਿਨਾਂ ਦੀ ਮਿਆਦ ਦੇ ਬਾਅਦ ਸਾਰੀਆਂ ਮਿਆਦ ਪੁੱਗ ਚੁੱਕੀਆਂ ਨੌਕਰੀਆਂ ਤੁਹਾਡੀਆਂ "ਮੇਰੀਆਂ ਨੌਕਰੀਆਂ" ਵਿੱਚੋਂ ਹਟਾ ਦਿੱਤੀਆਂ ਜਾਣਗੀਆਂ।',
  "TM_Tag3" : "ਮਿਆਦ ਪੁੱਗ ਗਈ",
  "TM_Tag4" : "ਦੁਆਰਾ ਅਪਲਾਈ ਕਰੋ",
  "TM_Rec5": "ਤੁਹਾਡਾ ਸਾਰਾ ਡਾਟਾ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ",
  "TM_Rec4": "ਆਪਣੇ ਖਾਤੇ ਨੂੰ ਮਿਟਾਉਣ ਲਈ ਤੁਹਾਨੂੰ ਪਹਿਲਾਂ ਆਪਣੇ ਖਾਤੇ ਨਾਲ ਜੁੜੇ ਸਾਰੇ ਪ੍ਰੋਫਾਈਲਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਹੋਵੇਗਾ",
  "TM_Pag41": "ਐਪਲੀਕੇਸ਼ਨ ਵੇਰਵੇ",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਦਸਤਾਵੇਜ਼ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  "TM_Mod18": "ਗੈਲਰੀ",
  "TM_Mod17": "ਕੈਮਰਾ",
  "91412465ea9169dfd901dd5e7c96dd99": "ਅੱਪਲੋਡ ਕਰੋ",
  "af6ebe0c0e89201cb2294f32955f7b15": "ਇੱਕ ਸਮੇਂ ਵਿੱਚ ਸਿਰਫ਼ 20 ਦਸਤਾਵੇਜ਼ਾਂ ਦੀ ਹੀ ਇਜਾਜ਼ਤ ਹੈ।",
  "d5de543c0e83073f05a846d62cc83c81": "ਉਸੇ ਖੇਤਰ ਲਈ ਦਸਤਾਵੇਜ਼ ਪਹਿਲਾਂ ਹੀ ਅੱਪਲੋਡ ਕੀਤਾ ਗਿਆ ਹੈ।",
  "fca8da28cbcbe094dc646872e7cdbc7b": "ਵੈਧ ਦਰਜ ਕਰੋ",
  "3f68e67dc6c397aa9d1c24c356f754f": "ਤਸਦੀਕ",
  "1e410e1b9903e7a99b93857f536b7e1d": "ਪੁਸ਼ਟੀ ਨਹੀਂ ਕੀਤੀ ਗਈ",
  "f8d855b26faffa0f8779fb44632d6bc1": "ਅਵੈਧ ਕੈਪਚਾ ਕੋਡ",
  "d53b4d564b9054db9b4b4a50e713197f": "ਕਿਰਪਾ ਕਰਕੇ ਇੱਥੇ ਕੈਪਚਾ ਦਰਜ ਕਰੋ",
  "TM_Con18": "ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਤੁਹਾਡੀ 'ਡਰਾਫਟ ਸਕੀਮ' ਵਿੱਚ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ",
  "TM_Con24": "ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਫਲਤਾਪੂਰਵਕ ਤੁਹਾਡੇ 'ਡਰਾਫਟ ਜੌਬ' ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ",
  "TM_Con23": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਤੁਹਾਡੀ "ਡਰਾਫਟ ਸੇਵਾਵਾਂ" ਵਿੱਚ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ`,
  "6cf24d7a07132fe88b610e2b3d3def28": "ਸਵੈ ਘੋਸ਼ਣਾ",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "ਇਹ ਖੇਤਰ ਲੋੜੀਂਦਾ ਹੈ।",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ਼ ਵਰਣਮਾਲਾ, ਸੰਖਿਆਵਾਂ, ਕੌਮਾ, -, _, / ਦਰਜ ਕਰੋ।",
  "562d3d08919ae105c3ebc42d9607d266": "ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ਼ ਵਰਣਮਾਲਾ ਜਾਂ ਸੰਖਿਆਵਾਂ ਦਾਖਲ ਕਰੋ",
  "58f7a43f2c3bd7854d99fbbc3fa4ae9": "ਸਿਰਫ਼ ਵਰਣਮਾਲਾ ਦੀ ਇਜਾਜ਼ਤ ਹੈ।",
  "4906d981bb592b32f6982fb4187c7b60": "ਇੱਕ ਵੈਧ ਮੈਂਐੱਫਐੱਸਸੀ ਕੋਡ ਦਾਖਲ ਕਰੋ।",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "ਸਿਰਫ਼ 11 ਅੱਖਰਾਂ ਦੀ ਇਜਾਜ਼ਤ ਹੈ।",
  "7c58cde3b827bc7d57cbf77865046169": "ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ਼ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
  "544b0264d55a1e4cf476b279240d9be4": "ਸਿਰਫ਼ ਦਸ ਅੰਕਾਂ ਦੀ ਇਜਾਜ਼ਤ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ 0 ਜਾਂ +91 ਦਾ ਅਗੇਤਰ ਨਾ ਲਗਾਓ",
  "73f0d980e48b8141e0776e02d96a872f": "ਸਿਰਫ਼ ਬਾਰਾਂ ਅੰਕਾਂ ਦੀ ਇਜਾਜ਼ਤ ਹੈ।",
  "0ce98bd973419ff33921a2275e7903b9": "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਪੈਨ ਨੰਬਰ ਦਾਖਲ ਕਰੋ।",
  "e55e425dbaf6b6449940eac8ea749092": "ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ਼ ਵਰਣਮਾਲਾ ਹੀ ਦਿਓ।",
  "64ff9e3809686d4ea3253a631cae4761": "ਕਿਰਪਾ ਕਰਕੇ 2 ਦਸ਼ਮਲਵ ਸਥਾਨਾਂ 'ਤੇ ਸਹੀ ਮੁੱਲ ਦਾਖਲ ਕਰੋ।",
  "7ed0ef23d527c2876015161e422f49ce": "ਕਿਰਪਾ ਕਰਕੇ ਕੰਨੜ ਭਾਸ਼ਾ ਵਿੱਚ ਆਪਣਾ ਨਾਮ ਦਰਜ ਕਰੋ।",
  "230486b5dd7afed96556ba9bd4175423": "ਪਿੰਨ ਕੋਡ ਗਲਤ ਹੈ",
  "9f44bd93c8988c682d5ef5369fd11f47": "ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
  "ac598199ea0dd028ade4677a435caf4b": "ਇੱਕ ਵੈਧ ਆਧਾਰ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
  "10803b83a68db8f7e7a33e3b41e184d0": "ਜਨਮ ਮਿਤੀ",
  "d0de720ccdffea8490dfd2893c50ebc5": "ਇਹ {{displayName}} ਤੋਂ ਵੱਡਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
  "ced6e171e13ebd5c37415c822706b9d4": "ਇਹ {{displayName}} ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "ਇਹ {{displayName}} ਦੇ ਬਰਾਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
  "9483135517d156229d0439dff74b1ca0": "ਇਹ {{displayName}} ਤੋਂ ਵੱਡਾ ਜਾਂ ਬਰਾਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
  "01e9cd748e852b1ae389b980bff195b0": "ਇਹ {{displayName}} ਤੋਂ ਘੱਟ ਜਾਂ ਬਰਾਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
  "TM_Pag37": "ਵੇਰਵਿਆਂ ਦੀ ਸਮੀਖਿਆ ਕਰੋ",
  "TM_Rec18": "ਆਪਣੇ ਭਰੇ ਹੋਏ ਵੇਰਵਿਆਂ ਨੂੰ ਬਦਲਣ ਲਈ 'ਸੰਪਾਦਨ' 'ਤੇ ਕਲਿੱਕ ਕਰੋ।",
  "TM_Alt51": "ਨੱਥੀ(ਆਂ):",
  "TM_Alt42": "ਧੰਨਵਾਦ",
  "TM_But9":"ਹੋਰ ਸੇਵਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ",
  "TM_But19":"ਡਾਊਨਲੋਡ ਕਰੋ",
  "TM_Con17":"ਤੁਹਾਡੀ ਐਪਲੀਕੇਸ਼ਨ ਡਾਊਨਲੋਡ ਕੀਤੀ ਜਾ ਚੁੱਕੀ ਹੈ।",
  "TM_Sub48": "ਮਦਦ ਅਤੇ ਸਹਾਇਤਾ ",
  "TM_Alt66": "ਸਾਨੂੰ <b>jioeasygov@easygov.co.in</b> 'ਤੇ ਈਮੇਲ ਭੇਜੋ ਅਤੇ ਸਾਡੀ ਟੀਮ ਜਲਦੀ ਹੀ ਤੁਹਾਡੇ ਕੋਲ ਵਾਪਸ ਆ ਜਾਵੇਗੀ।",
  "TM_Alt65": "ਕਿਸੇ ਵੀ ਪੁੱਛਗਿੱਛ ਲਈ ਸਹਾਇਤਾ ਦੀ ਲੋੜ ਹੈ?",
  "TM_Pag54": "ਸੇਵਾ ਲਾਭ",
  "TM_Alt37": "ਸਰਕਾਰੀ ਫੀਸ",
  "TM_But105" : "ਹੋਰ ਪੜਚੋਲ ਕਰੋ",
  "TM_Alt39": "ਸਪੁਰਦਗੀ ਦਾ ਪਤਾ",
  "TM_But17": "ਜਮ੍ਹਾਂ ਕਰੋ",
  "TM_But58": "ਆਵੇਦਨ ਕਰਨ ਲਈ ਵਧੋ",
  "TM_But100" : "ਵਾਪਸ ਜਾਓ",
  "TM_But95": "ਹੁਣੇ ਭੁਗਤਾਨ ਕਰੋ",
  "TM_Alt88": "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਅਰਜ਼ੀ ਜਮ੍ਹਾਂ ਕਰਾਉਣ ਲਈ ਹੇਠਾਂ ਦਿੱਤੀ ਰਕਮ ਦਾ ਭੁਗਤਾਨ ਕਰੋ",
  "TM_Con32" : "ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਫਲਤਾਪੂਰਵਕ ਜਮ੍ਹਾਂ ਹੋ ਗਈ ਹੈ",
  "TM_But110": "ਐਪਲੀਕੇਸ਼ਨ",
  "TM_Txf16": "ਇੱਥੇ ਖੋਜ ਕਰੋ",
  "TM_Hep69": "'योजना समर्थन मिळवा' वर क्लिक करा आणि एक लहान सर्वेक्षण करा, जे आम्हाला तुम्हाला योग्य समर्थन प्रदान करण्यात मदत करेल.",
  "TM_But107": "ਸਕੀਮ ਸਹਾਇਤਾ ਪ੍ਰਾਪਤ ਕਰੋ ਕੀ ਹੈ?",
  "TM_But106": "ਸਕੀਮ ਸਹਾਇਤਾ ਪ੍ਰਾਪਤ ਕਰੋ",
  "TM_Pag32": "ਸਕੀਮ ਜਾਗਰੂਕਤਾ ਸਰਵੇਖਣ",
  "TM_Con14": "ਵੇਰਵੇ ਸਾਂਝੇ ਕਰਨ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ।",
  "TM_Tag5": "ਖਾਲੀ ਸਥਿਤੀ",
  "TM_Alt107": "ਦਿਨ",
  "TM_Rec11":"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਚੁਣੀਆਂ ਗਈਆਂ ਸੂਚਨਾਵਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  "TM_Mod7":"ਸੂਚਨਾਵਾਂ ਮਿਟਾਓ",
  "TM_But25":"ਸਾਰਿਆ ਨੂੰ ਚੁਣੋ",
  "TM_Mod1": "ਸਕੀਮ ਮਿਟਾਓ",
  "3f68e67dc6c397aaa9d1c24c356f754f":"ਪ੍ਰਮਾਣਿਤ",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9":"ਸਿਰਫ਼ ਅੱਖਰਾਂ ਦੀ ਹੀ ਇਜਾਜ਼ਤ ਹੈ",
  "TM_Rec50": "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਆਪਣੀ ਅਰਜ਼ੀ ਵਾਪਸ ਲੈਣਾ ਚਾਹੁੰਦੇ ਹੋ ?",
  "TM_But23": "ਪੁਸ਼ਟੀ ਕਰੋ",
  "TM_But12": "ਰੱਦ ਕਰੋ",
  "TM_Con6" : "ਤੁਹਾਡੇ ਰਜਿਸਟਰਡ ਮੋਬਾਈਲ ਨੰਬਰ (xxxxxx{{phoneNumber}}) 'ਤੇ ਇੱਕ OTP ਭੇਜਿਆ ਗਿਆ ਹੈ।",
  "TM_But17": "ਜਮ੍ਹਾਂ ਕਰੋ",
  "TM_But61": "OTP ਦੁਬਾਰਾ ਭੇਜੋ",
  "TM_Con20": "ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਫਲਤਾਪੂਰਵਕ ਵਾਪਸ ਲੈ ਲਈ ਗਈ ਹੈ",
  "TM_But38": "ਸੰਪੂਰਨ ਕਾਰਵਾਈਆਂ",
  "TM_But62": "ਫੀਡਬੈਕ ਦਿਓ",
  "TM_Lab11": "ਅਰਜ਼ੀ ਵਾਪਸ ਲਓ",
  "TM_Err3" : "ਅਵੈਧ OTP",
  "TM_Err13": "OTP ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ",
  "TM_Err14": "ਤੁਸੀਂ OTP ਬਣਾਉਣ ਲਈ ਅਧਿਕਤਮ ਸੀਮਾ ਨੂੰ ਪਾਰ ਕਰ ਚੁੱਕੇ ਹੋ।  ਕਿਰਪਾ ਕਰਕੇ 3 ਘੰਟੇ ਬਾਅਦ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  "TM_But40": "ਕਾਰਵਾਈਆਂ",
  "TM_Tag9": "ਕਾਰਵਾਈਆਂ ਦੀ ਜਰੂਰਤ ਹੈ",
  "TM_Tag10": "ਕਿਸੇ ਕਾਰਵਾਈ ਦੀ ਜਰੂਰਤ ਨਹੀਂ ਹੈ",
  "TM_Tag8" : "ਸਥਿਤੀ",
  "TM_But76" : "ਦੇਖੋ",
  "TM_Alt87" : "ਔਨਲਾਈਨ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
  "TM_Pag34": "ਭੁਗਤਾਨ ਦੇ ਵੇਰਵੇ",
  "TM_Hea1": "ਕੁੱਲ ਮਾਤਰਾ",
  "TM_Hea2": "ਸੰਖੇਪ",
  "TM_Sub56": "ਕੁੱਲ",
  "TM_But42": "ਭੁਗਤਾਨ ਕਰੋ",
  "TM_Lab18": "ਰਸੀਦ ਦੇਖੋ",
  "TM_Err34": "ਭੁਗਤਾਨ ਅਸਫਲ ਹੋਣ ਕਾਰਨ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਪੁਰਦ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕੀ।",
  "TM_Rec55": "ਤੁਹਾਡੇ ਭੁਗਤਾਨ ਦੀ ਸਥਿਤੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਵਿੱਚ ਸਮਾਂ ਲੱਗ ਰਿਹਾ ਹੈ। ਅਸੀਂ ਤੁਹਾਡੇ ਬੈਂਕ ਨਾਲ ਲਗਾਤਾਰ ਜਾਂਚ ਕਰ ਰਹੇ ਹਾਂ। ਕਿਰਪਾ ਕਰਕੇ ਅਗਲੇ 48 ਘੰਟਿਆਂ ਲਈ ਕੋਈ ਵੀ ਵਾਧੂ ਭੁਗਤਾਨ ਕਰਨ ਤੋਂ ਬਚੋ।",
  "TM_But13": "ਵਾਪਸ ਜਾਓ",
  "TM_Alt124":"ਕ੍ਰਿਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ",
  "TM_Rec58": "ਰੁਕੋ। ਅਸੀਂ ਤੁਹਾਡੀ ਭੁਗਤਾਨ ਸਥਿਤੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰ ਰਹੇ ਹਾਂ",
  "TM_Rec59": "ਇਸ ਸੇਵਾ ਲਈ ਤੁਹਾਡਾ ਆਖਰੀ ਭੁਗਤਾਨ ਬਕਾਇਆ ਸਥਿਤੀ ਵਿੱਚ ਹੈ। ਜਦੋਂ ਇਹ ਪੂਰਾ ਹੋ ਜਾਵੇਗਾ ਤਾਂ ਅਸੀਂ ਤੁਹਾਨੂੰ ਸੂਚਿਤ ਕਰਾਂਗੇ। ਇਸ ਦੌਰਾਨ, ਸਾਡੀਆਂ ਹੋਰ ਸੇਵਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰਨ ਲਈ ਬੇਝਿਜਕ ਮਹਿਸੂਸ ਕਰੋ। ਆਪਣੇ ਧੀਰਜ ਲਈ ਧੰਨਵਾਦ.",
  "TM_But133": "ਲਿੰਕ 'ਤੇ ਕਲਿੱਕ ਕਰੋ"

}


export default language
