import axios from "axios";
import CryptoJS from "crypto-js";
import { saveJwt } from "./saveJwtAction";
import browserStorageKeys from "../../common/browserStorageKeys";
import browserStorage from "../../utility/browserStorage";
import {HandleApiError} from "../../utility/handleApiError"

const getTranslatedValue = (translatedText, hash) => {
  let translatedObj = translatedText[hash];
  if (translatedObj) {
    return translatedText[hash];
  } else {
    return null;
  }
};

export const getProduct = (geographyId, closeLoader) => {
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "",
    },
  });

  return (dispatch) => {
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    let requestObj;
    if (
      browserStorage.getItemValue(browserStorageKeys.USER_TYPE) === "business"
    ) {
      requestObj = { geographyId: geographyId, beneficiaryTypeId: 4, channel: window.outerWidth <=600 ? `${window.displayChannelMobile}` : `${window.displayChannelWeb}`, skipRuleList: true};
    } else {
      requestObj = { geographyId: geographyId, beneficiaryTypeId: 1, channel: window.outerWidth <=600 ? `${window.displayChannelMobile}` : `${window.displayChannelWeb}`, skipRuleList: true};
    }
    // let requestObj = { geographyId: geographyId, beneficiaryTypeId: 1 };
    if (browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)) {
      requestObj["locale"] = JSON.parse(
        browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
      ).code;
    }

    closeLoader && dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    instance
      .get("getproducts/", { params: requestObj })
      .then((response) => {
        
        if (response.status === 200) {
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
            let schemeList = response.data.data.objects;
            let tagsList = response.data.data.taggingData;
            let translatedText = response.data.translation;
            if (
              JSON.parse(
                browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
              ).code !== "en"
            ) {
              if (schemeList && schemeList.length > 0) {
                schemeList.map((scheme) => {
                  if (
                    getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(
                        scheme.service.name.replace(/\s/g, "")
                      ).toString(CryptoJS.enc.Hex)
                    )
                  ) {
                    scheme.service["untranslated_name"] = scheme.service.name;
                    scheme.service.name = getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(
                        scheme.service.name.replace(/\s/g, "")
                      ).toString(CryptoJS.enc.Hex)
                    );
                  }
                  if (
                    scheme.service.benefits &&
                    getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(
                        scheme.service.benefits.replace(/\s/g, "")
                      ).toString(CryptoJS.enc.Hex)
                    )
                  ) {
                    scheme.service["untranslated_benefits"] =
                      scheme.service.benefits;
                    scheme.service.benefits = getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(
                        scheme.service.benefits.replace(/\s/g, "")
                      ).toString(CryptoJS.enc.Hex)
                    );
                  }
                  scheme.productTags.map((productTag) => {
                    if (productTag.visible) {
                      if (
                        getTranslatedValue(
                          translatedText,
                          CryptoJS.MD5(
                            productTag.name.replace(/\s/g, "")
                          ).toString(CryptoJS.enc.Hex)
                        )
                      ) {
                        productTag["untranslated_name"] = productTag.name;
                        productTag.name = getTranslatedValue(
                          translatedText,
                          CryptoJS.MD5(
                            productTag.name.replace(/\s/g, "")
                          ).toString(CryptoJS.enc.Hex)
                        );
                      }
                    }
                  });
                });
              }
              if (tagsList && tagsList.length > 0) {
                tagsList.map((tag) => {
                  if (
                    getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(tag.category.replace(/\s/g, "")).toString(
                        CryptoJS.enc.Hex
                      )
                    )
                  ) {
                    tag["untranslated_category"] = tag.category;
                    tag.category = getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(tag.category.replace(/\s/g, "")).toString(
                        CryptoJS.enc.Hex
                      )
                    );
                  }
                  if (
                    getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(tag.subCategory.replace(/\s/g, "")).toString(
                        CryptoJS.enc.Hex
                      )
                    )
                  ) {
                    tag["untranslated_subCategory"] = tag.subCategory;
                    tag.subCategory = getTranslatedValue(
                      translatedText,
                      CryptoJS.MD5(tag.subCategory.replace(/\s/g, "")).toString(
                        CryptoJS.enc.Hex
                      )
                    );
                  }
                });
              }
            }
            dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
            response.data.data.objects = schemeList;
            response.data.data.taggingData = tagsList;
            dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
            if (
              !closeLoader ||
              browserStorage.getItemValue(browserStorageKeys.USER_TYPE)
            )
              dispatch({
                type: "HANDLE_LOADER",
                payload: { showLoader: false },
              });
            dispatch({ type: "GET_PRODUCT", payload: response.data.data });
          }
        }
      )
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
        console.log(err);
        HandleApiError(err)
      });
  };
};
