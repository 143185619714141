import axios from "axios";
import browserStorageKeys from "../../common/browserStorageKeys";
import browserStorage from "../../utility/browserStorage";
import {HandleApiError} from "../../utility/handleApiError"
import { responseTime } from "../../utility/responseTime";

export const appOpenCount = (data) => {
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "",
    },
  });

  let formData = {};
  if (browserStorage.getItemValue(browserStorageKeys.JWT_DATA)) {
    formData["jwt"] = data
  }
  if (browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID)) {
    formData["geographyId"] = parseInt(browserStorage.getItemValue(browserStorageKeys.GEOGRAPHY_ID), 10)
  }
  if (browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)) {
    formData["languageCode"] = JSON.parse(browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)).code
  }
  return (dispatch) => {
    const startTime = performance.now();
    instance
      .post("appOpenCount/", formData)
      .then((response) => {
        responseTime(performance.now(),startTime)
        if (response.status === 200) {
          dispatch({ type: "APP_OPEN_COUNT", payload: response.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
        HandleApiError(err)
      });
  };
};
